<template>

  <v-sheet
      id="page"
      ref="page"
      style="position: relative"
      :color="loadingColor"
      min-height="100%"
      height="100%"
      @scroll="onScroll"
      class="d-flex flex-column"
  >
    <view-header v-if="!preview" @scroll-to-block="scrollToBlock"  />


    <v-fade-transition>


      <div v-if="!loading">

        <check-document-page v-if="documentCheck" />

        <template v-else-if="entity.id && !newsAlias && !course">
          <template    v-for="(block,i) in blocks">
            <view-block
                v-cloak
                v-if="visibilityCondition(block.config) && !block.parent"
                :preview="preview"
                :trigger-update="trigger"
                :block="block"
                :elements="getBlockElements(block.id)"
                style="transition: all 0.3s ease"
                :key="'block_' + i"
                :overlap="i === 0"
                :is-first="i === 0"
                :children="getBlockChildren(block.id)"
                :children-elements="getChildrenElements(block.id)"
                :ref="`#${block.id}`"
                :id="`#${block.id}`"
            />
          </template>
        </template>
        <view-course
            v-else-if="course"
            :course="course"
        />
        <view-news
            v-else-if="newsAlias"
            @ready="loading = false"
            :alias="newsAlias"
        />
        <v-sheet
            v-else-if="!entity.id && !LOADING"
            :color="GET_AVALON_COLOR('background_main' )"
            class="fill-height d-flex align-center justify-center" height="100%">
          <h1>{{  $t('PageNotFound')  }}</h1>

        </v-sheet>
      </div>
      <v-sheet
          v-else-if="loading" :color="loadingColor" dark key="loading_view"
          :style="!preview ? 'position: fixed; top : 0 ; bottom : 0; left : 0; right : 0' : ''"
          height="100%"  class=" d-flex align-center justify-center">
        <div style="width: 150px">
          <v-progress-linear v-if="!preview" :style="`margin-bottom : ${header.config.height}px`" indeterminate size="82" color="#ffffff66"/>
        </div>

      </v-sheet>

    </v-fade-transition>


    <div class="flex-grow-1"></div>

    <view-footer v-if="!preview" />

  </v-sheet>
</template>

<script>
import viewHeader from "@/components/AvalonEditor/View/header/viewHeader";
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";
import viewFooter from "@/components/AvalonEditor/View/footer/viewFooter";
import Quill from 'quill'
import {mapActions, mapState} from "vuex";
import checkDocumentPage from "@/components/AvalonEditor/View/checkDocumentPage";
export default {
  name: "viewPage",
  metaInfo() {
    if ( !this.IS_ROUTE_CHANGED )  {
      return {}
    }
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.entity.title || 'Сторінка'  ,
    }
  },
  components : {
    viewHeader,
    viewBlock,
    viewFooter,
    viewNews : () => import("@/components/AvalonEditor/View/viewNews"),
    viewCourse : () => import("@/components/AvalonEditor/View/viewCourse"),
    checkDocumentPage
  },

  props : {
    page : {
      type : String
    },
    template : {
      type : Boolean,
      default : false
    },
    pageData : {
      type : Object,
    },
    newsAlias : {
      type : String,
    },
    preview : {
      type : Boolean,
      default : false
    },
    documentCheck : {
      type :Boolean,
      default : false
    },
    course : {
      type : String,
    }

  },
  data() {
    return {
      trigger : 0,
      noInit : false,
      entity : {},
      blocks : [],
      elements : [],
      displayNewBlockDialog : false,
      displayDeleteBlockDialog : false,
      displayCopyBlockDialog : false,
      newBlockNumber : 0,
      selectedBlock : {},
      firstLoad : true,
      loading : true,
      scroll : 0,
    }
  },
  computed : {
    ...mapState('avalon',['sideMenu','editorMode' , 'header']),
    loadingColor() {
      // return this.GET_AVALON_COLOR(this.header.config.background_color)
      return this.header.config.style
          ? this.CONFIG_PARAM_COLOR('styles', this.header.config.style, 'bg')
          : this.GET_AVALON_COLOR(this.header.config.background_color)
    }
  },
  watch : {
    course() {
      window.scrollTo(0,0)
    },
    page() {
      window.scrollTo(0,0)
      this.initPage()
    },
    newsAlias() {
      window.scrollTo(0,0)
      this.initPage()
    },
    pageData : {
      handler() {
        if ( !this.firstLoad ) {
          this.entity = this.pageData.entity
          this.blocks = this.pageData.blocks
          this.elements = this.pageData.elements
          this.trigger++
        }

      },
      deep : true
    },
    LANG() {
      this.initPage()
    },
    SM() {
      this.initPage()
    },
    scroll(value) {
      this.$store.state.avalon.scrollPosition = value
    },
  },
  methods : {
    ...mapActions('avalonPage',[
        'GET_PAGE_EDIT',
    ]),
    ...mapActions('avalon',[
      'GET_TEMPLATE_PAGE',
    ]),
    ...mapActions('avalonPage',[
      'GET_PAGE_PUBLIC',
      'GET_HOMEPAGE_PUBLIC'
    ]),

    scrollToBlock(id) {
      if ( !id ) {
        return
      }
      setTimeout(() => {
        let targetBlock = this.$refs[id][0].$el
        let scrollPosition = targetBlock.offsetTop
        window.scrollTo({
          top: scrollPosition - 100,
          behavior: 'smooth'
        })
      },100)
    },

    visibilityCondition(config) {
      if (!config) {
        return true
      }
      if ( this.SM ) {
        if ( config.visibility_mobile === false ) {
          return false
        }
      }

      if ( !this.SM ) {
        if ( config.visibility_desktop === false ) {
          return false
        }
      }

      return true
    },

    //technical
    getBlockElements(id) {
      if (this.elements.length === 0 ) {
        return []
      }
      return this.elements.filter(el=>el.block_id === id)
    },
    getBlockChildren(parentId) {
      if ( this.blocks.length === 0 ) {
        return []
      }
      return this.blocks.filter(el => el.parent === parentId)
    },
    getChildrenElements(parentId) {
      if ( this.getBlockChildren(parentId).length === 0 ) {
        return []
      }
      let items = [];
      this.getBlockChildren(parentId).forEach(block => {
        items = [...items , ...this.getBlockElements(block.id)]
      })
      return items
    },
    async initPage() {
      if ( this.newsAlias ) {
        this.loading = false
        return
      }
      this.loading = true
      if ( this.$store.state.avalon.previewMode ) {
        this.loading = false
        return
      }

      this.entity = {}
      this.blocks = []
      this.elements = []

      let result = false;
      if ( this.template ) {
        result  = await this.GET_TEMPLATE_PAGE(this.page)
      } else {
        result  = this.page ? await this.GET_PAGE_PUBLIC(this.page) : await this.GET_HOMEPAGE_PUBLIC(this.page)
      }

      this.entity = result.page
      this.blocks = result.blocks
      this.elements = result.elements
      this.elements = result.elements
      if ( result.page.course ) {
        this.$store.state.avalonPage.course = result.page.course
      }
      this.$emit('ready')

      setTimeout(()=> {
        this.loading = false

        if ( this.$route.hash ) {
          this.scrollToBlock(this.$route.hash)
        }

      }, 50)


    },
    onScroll() {
        this.scroll = window.top.scrollY
    },
    initQuill() {
      let Inline = Quill.import('blots/inline');
      class CustomSpanBlot extends Inline {

        static create(value) {
          let node = super.create();
          node.setAttribute('class', value);
          node.setAttribute('id', 'wsColor');
          return node;
        }

        static formats(domNode) {
          return domNode.getAttribute('class') || true;
        }

        format(name, value) {
          if (name === 'class') {
            this.domNode.setAttribute('class', value);
          } else {
            super.format(name, value);
          }
        }

      }
      CustomSpanBlot.blotName = 'wsColor';
      CustomSpanBlot.tagName = 'span';
      Quill.register(CustomSpanBlot, true);

    }
  },
  beforeMount() {

    if (this.LANG !== this.$store.state.routeLang && this.PARAMS_languages.includes(this.$store.state.routeLang) )   {
      this.$i18n.locale = this.$store.state.routeLang
    }

    this.initQuill()
    if ( this.pageData ) {
      this.entity = this.pageData.entity
      this.blocks = this.pageData.blocks
      this.elements = this.pageData.elements
      this.noInit = true
      this.firstLoad = false
    }


  },
  mounted() {
    if ( !this.template ) {
      this.$store.state.avalon.viewAvalon = true
    }
    this.initPage()
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    this.$store.state.avalon.viewAvalon = false
    window.removeEventListener("scroll" , this.onScroll)
  }
}
</script>

<style scoped>

</style>