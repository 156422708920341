<template>
  <ws-dialog
      persistent
      v-model="display"
      :title="$t('avalon.website.setup.title')"
      @save="save"
      blank
      fullscreen
      :background-color="wsLIGHTCARD"
      no-navigation
      not-centered
      no-padding
  >


    <template #default>

      <div class="d-flex flex-row fill-height">
        <!-- Sidebar -->
        <v-sheet
            v-if="step > 0 && !isFromTemplates"
            width="466"
            min-width="250"
            class="fill-height d-flex flex-column ">

          <!-- Title and Subtitle -->
          <div class="py-4 px-8">
            <img height="32px" class="mb-8"  alt="WeStudy" src="@/assets/img/avalon/westudy_constructor.png" />
            <div>
              <h3 :style="`color : ${wsDARKER}`" style="font-size: 22px">
                {{ $t(`avalon.new.sidebar.${stepName}.title`) }}
              </h3>
              <h4 class="font-weight-regular mt-2 " :style="`color : ${wsDARKER}`">
                {{ $t(`avalon.new.sidebar.${stepName}.description`) }}
              </h4>
            </div>

          </div>

          <!-- Sidebar Content -->
          <div class="flex-grow-1 overflow-y-auto px-8 mt-4" >

            <new-site-sidebar-homepage
                v-if="step === 1"
                @select="selectBlock"
                :items="homePageBlocksSelect"
                :homepage="homepage"
            />
            <new-site-sidebar-otherPages
                @select="selectPage"
                :items="pagesSelect"
                :selected-pages="selectedPages"
                v-if="step === 2" />
            <new-site-sidebar-colors
                :items="colorPalettesSelect"
                v-if="step === 3" />
            <new-site-sidebar-fonts
                v-if="step === 4"
            />
          </div>

        </v-sheet>

        <!-- Main Content -->
        <div class="flex-grow-1">

          <!-- Edit Block Dialog -->
          <ws-dialog
              v-if="displayBlockDialog"
              v-model="displayBlockDialog"
              :title="$t('avalon.new.homepage.change_block')"
              key="dialog"
              hide-buttons
              width="700"
              height="700px"
              not-centered
          >
            <v-sheet  style="position : relative; height : 100% "  >

              <div  class="overflow-x-hidden " style="position: absolute; width : 1500px; transform: scale(0.433);
                  transform-origin: top left;">

                <v-hover v-for="(item,index) in categoryBlocks" :key="index + 'change_block'" #default="{hover}">
                  <div :style="`border : 4px solid ${hover ? wsACCENT : wsDARKLIGHT };` "
                       style="border-radius: 8px"
                       class="mt-3 overflow-hidden pointer"
                       @click="editBlock(item.block.id)"
                  >
                <view-block
                    :block="item.block"
                    :elements="item.elements"
                    preview
                />
                  </div>
                </v-hover>


              </div>

            </v-sheet>



          </ws-dialog>

          <!-- Left arrow -->
          <div class="d-flex flex-column fill-height px-12">
            <!-- Cancel Button -->
            <div v-if="step > 0"  class="d-flex justify-end align-center py-4 pr-n4">
              <h5 @click="skip"
                  :style="`color : ${wsACCENT}`"
                  class="d-flex align-center pointer"
              >
                {{ $t('Skip') }}
                <v-icon class="ml-2" :color="wsACCENT">mdi-close</v-icon>
              </h5>
            </div>

            <!-- Welcome screen -->
            <new-site-welcome
                v-if="step === 0"
                @input="isFromTemplates = $event; step = 1"
                @skip="skip"
            />

            <!-- Page name -->
            <h4  v-if="step > 0 && !isFromTemplates"
                :style="`color : ${wsACCENT}`" class="text-center   pb-4">
              {{ step !== 2 || !selectedPage.value || selectedPage.value === 'home' ?  $t('Homepage') : selectedPage.text }}
            </h4>
            <!-- Preview window header -->
            <v-sheet
                v-if="step > 0 && !isFromTemplates"
                :color="wsBACKGROUND"
                width="700"
                class="mx-auto d-flex justify-space-between align-center pa-2 header"
            >
              <div class="d-flex align-center">
                <v-icon :color="wsDARKLIGHT" class="mr-1" size="10">mdi-circle</v-icon>
                <v-icon :color="wsDARKLIGHT" class="mr-1" size="10">mdi-circle</v-icon>
                <v-icon :color="wsDARKLIGHT" class="mr-1" size="10">mdi-circle</v-icon>
              </div>

              <div/>

            </v-sheet>

            <!-- Left arrow -->
            <v-btn
                v-if="step > 0 && !isFromTemplates"
                @click="prevPage"
                :color="wsACCENT"
                style="position: absolute; top : 50%;  margin-top: 40px; margin-right: 124px;  right: 50%; z-index : 999999 "
                large
                icon
                :disabled="selectedPages.length < 2"
                key="leftButton"
            >
              <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>

            <!-- Preview Сontent: Page Viewer -->
            <v-sheet
                v-if="step > 0 && !isFromTemplates"
                width="700"
                class="flex-grow-1 mx-auto overflow-y-auto content"
            >
              <!-- Page Viewer -->
              <new-site-page-viewer
                  @edit-block="openEditBlock"
                  :homepage="homepage"
                  :step="step"
                  :pages="selectedPages"
                  :selected-page="selectedPage"
                  :selected-pages="selectedPages"
                  :blocks="blocks"
                  :home-page-blocks-select="homePageBlocksSelect"
              />
            </v-sheet>


            <!-- Right arrow -->
            <v-btn
                v-if="step > 0 && !isFromTemplates"
                @click="nextPage"
                :color="wsACCENT"
                style="position: absolute; top : 50%; margin-top: 40px; margin-left: 590px; left: 50%;"
                large
                icon
                :disabled="selectedPages.length < 2"
            >
              <v-icon large>mdi-arrow-right</v-icon>
            </v-btn>



          </div>
        </div>
      </div>

    </template>

    <template #footer>
      <newSiteFooter
          v-if="step > 0"
          @step="step = $event"
          :step="step"
          @back="prevStep"
          @next="nextStep"
          @step-name="stepName = $event"
          :is-from-templates="isFromTemplates"
          :homepage="homepage"
      />
    </template>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";
import newSiteWelcome from "@/components/AvalonEditor/Dialogs/newSiteDialog/newSiteWelcome";
import newSiteFooter from "@/components/AvalonEditor/Dialogs/newSiteDialog/newSiteFooter";
import newSiteSidebarHomepage from "@/components/AvalonEditor/Dialogs/newSiteDialog/sidebar/newSiteSidebarHomepage";
import newSiteSidebarOtherPages from "@/components/AvalonEditor/Dialogs/newSiteDialog/sidebar/newSiteSidebarOtherPages";
import newSiteSidebarColors from "@/components/AvalonEditor/Dialogs/newSiteDialog/sidebar/newSiteSidebarColors";
import newSiteSidebarFonts from "@/components/AvalonEditor/Dialogs/newSiteDialog/sidebar/newSiteSidebarFonts";
import newSitePageViewer from "@/components/AvalonEditor/Dialogs/newSiteDialog/newSitePageViewer";
import entitiesFunctions from "@/mixins/entitiesFunctions";
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";
import defaultHeader from "@/assets/json/avalon/defaultHeader.json";

export default {
  name: "AvalonCreateSiteDialog",
  mixins : [languagesList , entitiesFunctions],
  init : 'avalon/GET_SETUP_DATA',
  init_callback(result) {
    this.homePageBlocksSelect = result.categories_select
    this.pagesSelect = result.pages_categories_select
    this.blocks = result.blocks
    this.colorPalettesSelect = result.palettes
    this.pages = result.pages

    this.homePageBlocksSelect.forEach( el => {
      this.homepage[el.value] = null
    })



    this.homepage = this.COPY(this.homepage)
  },
  components : {
    newSiteWelcome,
    newSiteFooter,
    newSiteSidebarHomepage,
    newSiteSidebarOtherPages,
    newSiteSidebarColors,
    newSiteSidebarFonts,
    newSitePageViewer,
    viewBlock
  },

props : {
    value : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      navigation : 'template',
      pages : [],
      entityData : {
        primary_lang : 'ua'
      },
      langError : false,
      step : 1,
      isFromTemplates : false,
      stepName : 'homepage',

      homePageBlocksSelect : [],
      pagesSelect : [],
      homepage : {},
      colorPalettesSelect : [],
      selectedPages : [
        { value : 'home' , text : 'homepage' },
      ],
      selectedPage : {},
      selectedPageIndex : 0,

      blocks : [],
      categories : [],

      displayBlockDialog : false,
      selectedCategory : null
    }
  },
  computed : {
    navigationItems() {
      return [
        { text : this.$t('Languages') , value : 'languages' },
      ]
    },
    categoryBlocks() {
      if ( !this.selectedCategory ) {
        return []
      }
      return this.blocks.filter(el=> el.category_id === this.selectedCategory)
    },
  },
  methods : {
    ...mapActions('avalon',[
        'GET_TEMPLATE_PAGES',
        'SETUP_WEBSITE',
        'GET_COLOR_PALETTES',
        'GET_TEMPLATE_BLOCKS'
    ]),

    editBlock(value) {
      this.homepage[this.selectedCategory] = value
      this.selectedCategory = null
      this.displayBlockDialog = false
    },
    openEditBlock(category) {
      this.selectedCategory = category
      this.displayBlockDialog = true
    },
    async createSite() {
      let homepageBlocks = []
      Object.keys(this.homepage).forEach(block => {
        if ( this.homepage[block] ) {
          homepageBlocks.push(this.homepage[block])
        }
      })
      let data = {
        homepage_name : this.$t('Homepage'),
        homepage_blocks : homepageBlocks,
        pages : this.selectedPages ?? [],
        config : this.$store.state.avalon.config,
      }

      let result = await this.SETUP_WEBSITE(data)
      if ( !result ) {
        return
      }
      location.reload()

    },
    prevStep() {
      if ( this.step === 1 ) {
        return
      }
      this.step--
    },
    nextStep() {
      if ( this.step === 4 && !this.isFromTemplates) {
        this.createSite()
        return
      }
      this.step++
    },
    selectBlock(category) {
      if ( !this.homepage[category] ) {
        let block = this.blocks.find(el => el.category_id === category)
        if ( block ) {
          this.homepage[category] = block.block.id
        }

      } else {
        this.homepage[category] = null
      }
      this.homepage = this.COPY(this.homepage)
    },
    selectPage({category, categoryName}) {
      if ( !this.selectedPages.map(el=> el.category).includes(category) ) {
        let page = this.pages.find(el => el.category && el.category.toString() === category.toString() )
        if ( page ) {
          this.selectedPage = this.COPY({value : page.id , text : categoryName, category : category})
          this.selectedPages.push({value : page.id , text : categoryName, category : category})
          this.$store.state.avalon.mainMenu.push(
              { value: category,
                text: categoryName,
                type : "page",
              }
          )

        }
      } else {
        this.selectedPages = this.selectedPages.filter(el => el.category !== category)
        this.selectedPage = this.selectedPages[this.selectedPages.length - 1] || {}
        this.$store.state.avalon.mainMenu = this.$store.state.avalon.mainMenu.filter(el => el.value !== category)
      }
      this.selectedPageIndex = this.selectedPages.length > 0  ? ( this.selectedPages.length - 1) : 0
    },
    prevPage() {
      if ( this.selectedPageIndex - 1 !== -1 ) {
        this.selectedPageIndex--
      } else {
        this.selectedPageIndex = this.selectedPages.length - 1
      }
      this.selectedPage = this.COPY(this.selectedPages[this.selectedPageIndex])
    },
    nextPage() {
      if ( this.selectedPageIndex + 1 <= this.selectedPages.length - 1 ) {
        this.selectedPageIndex++
      } else {
        this.selectedPageIndex = 0
      }
      this.selectedPage = this.COPY(this.selectedPages[this.selectedPageIndex])
    },
    skip() {
      this.display = false
    },
    async save() {
      if ( !this.entityData.primary_lang ) {
        this.langError = true
        this.notify(this.$t('LanguageNotSet'))
        return
      }
      let result = await this.SETUP_WEBSITE(this.entityData)
      if ( !result ) {
        return
      }
      location.reload()
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    }
  },
  async mounted() {


    this.$store.state.avalon.config = this.COPY(this.DEFAULT_CONFIG)
    this.$store.state.avalon.header = this.COPY(defaultHeader)
    this.$store.state.avalon.header.config.title = this.$store.state.business.selectedBusiness.name

    this.CHECK_CONFIG_PARAM("fonts" , "families")
    this.LOAD_FONTS()

    this.display = this.value

  }
}
</script>

<style scoped>
.header {

  z-index: 2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.content {
  box-shadow: 0px 1px 10px 0px rgba(86, 113, 134, 0.20) !important;
  margin-top: -26px; /* pulls the content div up by 1 pixel */
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

</style>