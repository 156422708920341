<template>
  <div>

    <ws-button
        text
        :label-raw="$i18n.locale.toUpperCase()"
        icon="mdi-menu-down"
        @click="expanded = !expanded"
        block accent left
    />

    <v-expand-transition>
      <v-sheet class="d-flex pl-4"  v-if="expanded">

        <v-divider :style="`border-color :  ${wsDARKLIGHT}`" vertical />
        <div style="width: 100%">
          <ws-button
              v-for="(item,i) in langSelect" :key="i"
              @click="changeLang(item.value)"
              text
              :label-raw="LANGUAGE_TEXT(item.text)"
              block accent left
              class="mb-2"
          />
        </div>


      </v-sheet>
    </v-expand-transition>






  </div>

</template>

<script>

import languagesList from "@/mixins/languagesList";

export default {
  name: "wsLangSelect",
  components : {

  },
  mixins : [languagesList],
  props : {
    backgroundColor : {
      type : String
    },
    textColor : {
      type : String
    }

  },
  data() {
    return {
      lang : 'ua',
      expanded : false
    }
  },
  computed: {
    routte() {
      return this.$route.path
    },
    langSelect(){
      let items = []

      this.PARAMS_languages.forEach((lang)=>{

        let path = this.$route.path

        if ( this.$i18n.locale === process.env.VUE_APP_I18N_LOCALE ) {
          if( lang !== process.env.VUE_APP_I18N_LOCALE ) {
            path = '/' + lang +  path
          }
        } else {
          if( lang !== process.env.VUE_APP_I18N_LOCALE ) {
            path = '/' + lang + path.substring(3)
          } else  {
            path = path.substring(3)
          }

        }



        items.push( { text : lang, value : lang, link: path })
      })
      return items
    }
  },
  methods : {
    changeLang(input) {
      this.LOCALE_CHANGE(input)
    }
  }
}
</script>

<style scoped>

</style>