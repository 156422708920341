<template>
<div>

  <!-- Proceed As Window  -->
  <div v-if="!displayEmailConfirmation">
    <ws-logo class="mt-n6" />
    <h3 class="wsDARKER mt-2">{{ $t('ChooseAccount') }}</h3>

    <v-hover #default="{hover}">
      <v-sheet
          @click="proceedAsUser"
          v-ripple
          :color="hover ? wsLIGHTCARD : 'transparent'"
          :style="`border : 1px solid ${wsACCENT} !important`"
          class="wsRoundedLight mt-4 pointer d-flex align-center  justify-space-between"
          style="padding : 10px 16px"

      >
        <div class="d-flex align-center">
          <v-sheet
                   style="overflow:hidden; border-radius: 50px"
                   :style="`border : 3px solid ${wsATTENTION}`"
                   height="32" width="32" class="mr-3 d-flex align-center justify-center"
          >
            <v-icon :color="wsACCENT" v-if="!proceedUser.img">mdi-account-tie</v-icon>
            <v-img v-else :src="proceedUser.img" height="40" width="40" />
          </v-sheet>
          <div >
            <div class="d-flex align-center">
              <h5 class="wsACCENT">{{ $t('ProceedAs') }} {{ proceedUser.firstname }} </h5>
            </div>
            <h5  class="font-weight-regular wsACCENT">{{ proceedUser.email }}</h5>
          </div>

        </div>

        <ws-logo height="24"/>

      </v-sheet>
    </v-hover>
    <v-hover #default="{hover}">
      <v-sheet
          @click="$emit('skip')"
          v-ripple
          :color="hover ? wsLIGHTCARD : 'transparent'"
          :style="`border : 1px solid ${wsACCENT} !important`" class="d-flex align-center wsRoundedLight mt-2 pointer"
          style="padding : 10px 16px"
      >
        <v-sheet
            style="overflow:hidden; border-radius: 50px"
            height="32" width="32" class="mr-3 d-flex align-center justify-center"
            :style="`border : 3px solid ${wsATTENTION}`"
        >
          <v-icon :color="wsATTENTION" >mdi-account</v-icon>
        </v-sheet>
        <div >
          <div class="d-flex align-center">
            <h5 class="wsACCENT">{{ $t('LoginFromOther') }} </h5>
          </div>
        </div>
      </v-sheet>
    </v-hover>
  </div>

  <!-- Email Confirmation -->
  <verify-window
      v-else
      @success="proceedAsUser"
      @return="displayEmailConfirmation = false"
      :user="proceedUser"
      display-return
  />

</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import verifyWindow from "@/components/auth/UI/verifyWindow";

export default {
  name: "proceedAsWindow",
  props : {
    noAuthAction : {
      type : Boolean,
      default : false
    },
  },
  components : {
    verifyWindow
  },
  data() {
    return {
      displayEmailConfirmation : false,
    }
  },
  computed : {
    ...mapState('auth' , [ 'proceedUser']),
  },
  methods : {
    ...mapActions('auth',[ 'PROCEED_AS' ]),

    async proceedAsUser() {
      let data = {
        'refresh' : localStorage.getItem('old_refresh_token'),
        'business' : localStorage.getItem('old_business'),
      }
      let result = await this.PROCEED_AS(data)

      if ( !result ) {
        if ( this.$store.state.ajax.error.includes('Email Require verification')) {
          this.displayEmailConfirmation = true
        }
        return
      }

      if ( this.noAuthAction) {
        this.$store.state.auth.proceedAuthSuccessTrigger++
        return
      }
      if ( this.$store.state.business.selectedBusiness.alias ) {
        this.$router.push(this.businessDashLink(''))
        return
      }

      this.$router.push(this.localeLink('dashboard'))
    },
  }
}
</script>

<style scoped>

</style>