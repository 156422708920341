<template>
<div>
  <ws-logo class="mt-n6" />
  <h3 class="wsDARKER mt-2">{{ $t('AccountSecurity') }}</h3>

  <div >

    <h5 class="font-weight-regular wsDARKER mt-1">
      {{ $t('EmailAccountSecurityText1') }}
      <span class="font-weight-bold">{{ user ? user.email : $store.state.auth.user.email }}</span>
      {{ $t('EmailAccountSecurityText2') }}
    </h5>

    <ws-text-field
        v-model="confirmationCode"
        :label="$t('VerificationCode')"
        :placeholder="$t('EnterEmailVerificationCode')"
        @input="codeError = false"
        :error="codeError"
        class="mt-5"
        clearable
    />
    <ws-timer class="mt-2" #default="{time,finished,start}" seconds="125">
      <h5 :style="`color : ${finished ? wsATTENTION : wsDARKLIGHT }`"
          :class="[{pointer : finished}]"
          @click="resendEmail(finished,start)"
      >
        {{ $t('ResendAgain') }} <span v-if="!finished">: {{ time }} </span>
      </h5>
    </ws-timer>

    <ws-button
        @click="verifyEmail"
        :disabled="!confirmationCode || (confirmationCode.length < 6)"
        class="mt-5"
        block label="Proceed"
    />

    <ws-button
        v-if="displayReturn"
        @click="$emit('return')"
        class="mt-2"
        block label="Back" left-icon="mdi-chevron-left" outlined :color="wsACCENT"
    />

  </div>

</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "verifyWindow",
  props : {
    user : {
      type : Object
    },
    displayReturn : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      confirmationCode : null,
      codeError : false
    }
  },
  methods : {
    ...mapActions('auth',[
        'VERIFY_EMAIL',
        'GET_VERIFICATION_CODE'
    ]),

    async resendEmail(finished , start) {
      if ( !finished) {
        return
      }
      start()
      let result = await this.GET_VERIFICATION_CODE(this.user.id || this.$store.state.auth.user.id)
      if ( !result ) {
        return this.ERROR()
      }

    },
    async verifyEmail() {

      let data = {
        code : this.confirmationCode,
        uuid : this.user.id || this.$store.state.auth.user.id
      }
      let result = await this.VERIFY_EMAIL(data)
      if ( !result ) {
        this.codeError = true
        return this.notify(this.$t('VerificationCodeError') , 'warning')
      }
      this.notify(this.$t('EmailVerified') , 'success')
      this.$emit('success')
    }

  }
}
</script>

<style scoped>

</style>