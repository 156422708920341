<template>
  <div   class="px-4 d-flex flex-row" ref="blockText"  >

    <!-- Navigation Contents -->
    <v-sheet
        v-if="block.config.show_sidebar"
        :min-width="block.config.navigation_width || 350"
        :width="block.config.navigation_width || 350"
        color="transparent"
        class="pr-8 overflow-y-auto"
        :style="navigationStyle"
        ref="navigation"
        style="transition: all 0.3s ease"
    >
      <h4 v-for="(item,i) in contents" :key="i"
          @click="scrollTo(item)"
          class="mb-2 linkHover pointer"
          :style="BLOCK_TEXT_CSS(block , block.config.style, 'block-text' , 'content') + chapterStyle(item)"
      >
        {{ item.text }}
      </h4>
    </v-sheet>
    <v-sheet
        color="transparent"
        v-if="block.config.show_sidebar && isFixedPosition"
        :min-width="block.config.navigation_width || 350"
        :width="block.config.navigation_width || 350"
    />
    <!-- Block Content-->
    <v-sheet
        :class="[{'flex-grow-1' : block.config.show_sidebar }]"
        color="transparent" width="100%" :max-width="!block.config.show_sidebar ? ( block.config.text_width || 700) : null" class="mx-auto">


      <!-- Text -->
      <div v-html="block.config.text"
           :id="`quill_${block.id}`"
      />


    </v-sheet>



  </div>
</template>

<script>

import {mapState} from "vuex";
export default {
  name: "blockGallery",
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    items : {
      type : Array
    }
  },
  data() {
    return {
      contents : [],
      text : '',
      ready : false,
      quillReadyTrigger : 0,
      displayToolbar : false,
      toolbarExpand : false,
      editorOptions: {
        scrollingContainer: null,
        modules : {
          toolbar : false,
          clipboard : true
        },
        theme: null,
        fontSize: '16px',
      },
      quill : {},

      marginTop : 0,
      selectionIndex : null,
      selectionRange : null,

      isFixedPosition : 0

    }
  },
  computed : {
    ...mapState('avalon' , ['header', 'scrollPosition']),

    navigationStyle() {
      let style = ''
      if ( this.isFixedPosition ) {
        style += `
        position : fixed;
         z-index : 0;
         bottom : 0;
          top : ${this.EDITOR_PREHEADER_HEIGHT}px;
          width : ${this.block.config.navigation_width || 350}px
          `
      }
      return style
    },

    nesting() {
      const uniqueTypes = [...new Set(this.contents.map(heading => heading.type))].sort((a, b) => a - b);

      let nestingObject = {}
      uniqueTypes.forEach((type , index) => {
        nestingObject[type] = index
      })

      return nestingObject
    },


    config() {
      return this.block.config || {}
    },
    siteConfig() {
      return this.CONFIG_PARAM('fonts')
    },
    toolbarStyle() {
      let style = `border : 1px solid ${this.wsBACKGROUND};`

      let margin = this.marginTop - 40
      style += `margin-top : ${margin}px;`

      return style
    },
    blockStyle() {
      return this.block.config.style
    }
  },
  watch : {
    blockStyle() {
      this.processTextStyling()
    },
    scrollPosition(){
      this.checkTopPadding()
    },
    'siteConfig.fonts' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    'siteConfig.styles' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
  },
  methods : {

    checkTopPadding() {

      let element = this.$refs.blockText
      if ( element  ) {

        // let height = domElement.getBoundingClientRect().height
        let top = element.getBoundingClientRect().y
        let bottom = element.getBoundingClientRect().bottom - 50

        let scrollStart = this.EDITOR_PREHEADER_HEIGHT

        if ( this.header.config.overlap && this.header.config.sticky && this.isFirst   ) {
          scrollStart -= this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']*2
        }
        else if ( this.header.config.overlap && this.isFirst   ) {
          scrollStart -= this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']
        }

        if ( this.header.config.sticky ) {
          scrollStart += this.header.config[this.MOBILE_VIEW ? 'height_sm' : 'height']
        }

        if (top <  scrollStart && bottom > scrollStart  ) {
          this.marginTop = -top + scrollStart
          this.isFixedPosition = true
        } else {
          this.marginTop = 0
          this.isFixedPosition = false
        }


      }


    },

    scrollTo(item) {
      item.heading.scrollIntoView()
    },

    chapterStyle(item) {
      return `;margin-left : ${ (this.nesting[item.type] || 0) * 8 }px`
    },

    checkHeadings() {

      const div = document.getElementById(`quill_${this.block.id}`);
      if ( !div ) {
        return
      }

      const headings = div.querySelectorAll('h1, h2, h3, h4, h5');
      const headingArray = [];

      headings.forEach((heading) => {

        const tagName = heading.tagName.toLowerCase();
        const type = parseInt(tagName.substring(1), 10);
        const text = heading.textContent;

        headingArray.push({ type, text , heading });
      });

      this.contents = headingArray;

    },

    processTextStyling() {
      let domId = `quill_${this.block.id}`
      let blockStyle = this.block.config.style || 'light_1'
      let isView = false
      let blockWidth = this.block.config.width || 1240
      let styles = this.TEXT_STYLES_CONFIG(blockStyle)
      let coefficient = this.EDITOR_FONT_COEFFICIENT(blockWidth)
      // Process General Styles
      Object.keys(styles).forEach(tag => {
        const domElements = document.querySelectorAll(`#${domId} ${tag}`);
        domElements.forEach(domElement => {
          domElement.removeAttribute("style")
          domElement.style.marginBottom = 0
          if ( isView && tag === 'a') {
            if ( domElement.href.includes(this.businessPublicLink('',true)) ) {
              let route = domElement.href.replace(this.businessPublicLink('',true), "");
              route = route.slice(1)
              domElement.addEventListener("click", (event) => {
                event.preventDefault();
                this.$router.push(this.businessPublicLink(route))
              });
            }
          }

          Object.keys(styles[tag]).forEach(param => {
            if ( param === 'fontSize' ) {
              let textMultiplier = 1
              if (coefficient !== 1) {
                textMultiplier = coefficient
              }
              let sizeValue = styles[tag][param]
              if ( (this.MOBILE_VIEW || this.SM) && styles[tag][param + '_sm'] ) {
                sizeValue = styles[tag][param + '_sm']
              }
              let size  =  sizeValue*textMultiplier
              size = size + 'px'

              domElement.style[param] = size;
            }
            if ( param === 'fontWeight' ) {
              // let classNames = domElement.className.split(" ");
              // // classNames.forEach(className => {
              // //     // if ( className.includes('weight-') ) {
              // //     //     domElement.classList.remove(className )
              // //     // }
              // // });
              domElement.classList.add('weight-'+ styles[tag][param] )
            } else {
              domElement.style[param] = styles[tag][param];
            }
          })
        });
      })
      // Process wsColors

      const domElements = document.querySelectorAll(`#wsColor`);
      domElements.forEach(domElement => {
        domElement.classList.forEach(className => {
          if (this.AVALON_COLORS_CLASS_NAMES.includes(className) ) {
            domElement.style.color = this.GET_AVALON_COLOR(className.substring(3));
          }
        })
      });

    },
    cleanNode(node) {
      if (node.removeAttribute) {
        node.removeAttribute('style');
        node.removeAttribute('class');
      }
      node.childNodes.forEach(child => this.cleanNode(child));
    },

  },
  mounted() {
    this.checkHeadings()
    this.processTextStyling()
    setTimeout( ()=> {
      this.processTextStyling()
      this.checkHeadings()
      this.ready = true
    } , 100  )
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
</style>