<template>
  <v-expand-transition>
    <v-sheet
        v-if="display"
        :style="menuStyle"
        style="z-index : 999999"
        :key="header.id"
        class="d-flex flex-column"
    >

      <!-- Menu Items-->
      <div class="flex-grow-1 overflow-y-auto"   >
        <template v-for="(item, i) in mainMenu" >

          <div :key="'mobile_menu' + i" >
            <ws-link
                :href="item.url || null"
                :to="item.route ? businessPublicLink(item.route + (item.block || '')) : null"
                :new-window="item.open_in_new_window"
                :disabled="preview || $store.state.avalon.editorMode"
            >
              <v-btn
                  exact-active-class="active"
                  @click="action(item)"
                  :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                  class="noCaps d-flex pointer justify-space-between py-3"
                  block text
                  :height="45*SCALE_COEFFICIENT"
              >
                <h4 :key="SCALE_COEFFICIENT + 'link'"
                    :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)"
                    class="mr-5 text-no-wrap" >
                  {{ $t(item.text) }}
                </h4>
                <v-icon v-if="item.type === 'submenu' "
                        :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                        :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </ws-link>
            <v-expand-transition>
              <div v-if="item.expand">
                <div v-for="(child,j) in item.children" :key="'mobile_sub_item' + i + j">
                  <ws-link
                      :href="child.url || null"
                      :to="child.route ? businessPublicLink(child.route + (child.block || '')) : null"
                      :new-window="child.open_in_new_window"
                      :disabled="preview || $store.state.avalon.editorMode"
                  >
                    <v-btn
                        @click="$emit('input' , false)"
                        :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                        block text
                        :height="45*SCALE_COEFFICIENT"
                        class="noCaps pl-8 d-flex pointer justify-space-between py-3"
                    >
                      <h4 :key="SCALE_COEFFICIENT + 'sub-link'"
                          :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)"  class="mr-5 text-no-wrap" >
                        {{ $t(child.text) }}
                      </h4>
                    </v-btn>
                  </ws-link>
                </div>
              </div>
            </v-expand-transition>

          </div>



        </template>

        <!-- Languages select -->
        <ws-lang-select
            class="mr-4"
            :background-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_dropdown_background')"
            :text-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
            :activator-style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'links')"
            :text-style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'dropdown_links')"
            autohide
            :disabled="preview || $store.state.avalon.editorMode"
            list
        />


      </div>


      <!-- Login Button-->

      <!-- Socials-->
      <div  v-if="$store.state.avalon.header.config.socials" class="d-flex align-center px-3 py-3">
        <a v-for="(social,i) in $store.state.avalon.header.config.socials" :key="i"
           :href="social.link"
           :is="preview || $store.state.avalon.editorMode ? 'span' : 'a'"
           target="_blank"
        >
          <v-sheet
              v-if="IS_WESTUDY_ICON(social.img)"
              color="transparent"
              :src="social.img"
              :style="`
                    -webkit-mask: url('${social.img}') no-repeat ;
                    mask: url('${social.img}') no-repeat 50% 50%;
                    mask-size : contain;
                    position:relative;
                    background-color: ${CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')} !important;`
              "
              :height="(social.size || 24) * SCALE_COEFFICIENT"
              :width="(social.size || 24) * SCALE_COEFFICIENT"
              contain
              class="mr-3"
              :key="social.img"
          />
          <ws-img
              v-else
              :src="social.img"
              :height="(social.size || 24) * SCALE_COEFFICIENT"
              :width="(social.size || 24) * SCALE_COEFFICIENT"
              class="mr-3"
              contain
          />
        </a>


      </div>

      <div    v-if="!header.config.hide_button" >
        <v-divider :style="`border-color : ${CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')}`" />
        <div class=" py-2">

          <v-btn
              v-if="$store.state.auth.loggedIn"
              @click="toggleLoginMenu(); $emit('input' , false)"
              :to="businessDashLink('')"
              :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
              block
              text
              class="noCaps  d-flex pointer justify-space-between py-2"
              :min-height="45*SCALE_COEFFICIENT"
          >
            <h4 :key="SCALE_COEFFICIENT + 'dashboard'"
                :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)" class="mr-5 text-no-wrap" >
              {{ $t('GoToDashboard') }}
            </h4>
            <v-icon
                :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">mdi-view-dashboard</v-icon>
          </v-btn>

          <v-btn
              v-if="!$store.state.auth.loggedIn"
              @click="toggleLoginMenu(); $emit('input' , false)"
              :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
              block
              text
              class="noCaps  d-flex pointer justify-space-between py-2"
              :min-height="45*SCALE_COEFFICIENT"
          >
            <h4 :key="SCALE_COEFFICIENT + 'login'"
                :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)"
                class="mr-5 text-no-wrap" >
              {{ $t('Login') }}
            </h4>
            <v-icon
                :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">mdi-login</v-icon>
          </v-btn>

          <v-btn
              v-else
              @click="  logout() ; $emit('input' , false);"
              :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
              block
              text
              class="noCaps  d-flex pointer justify-space-between py-2"
              :min-height="45*SCALE_COEFFICIENT"
          >
            <h4
                :key="SCALE_COEFFICIENT + 'login'"
                :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)" class="mr-5 text-no-wrap" >
              {{ $t('Logout') }}
            </h4>
            <v-icon
                :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">mdi-logout</v-icon>
          </v-btn>

        </div>
      </div>

    </v-sheet>
  </v-expand-transition>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import wsLangSelect from "@/components/UI/wsLangSelect";

export default {
  name: "viewMobileMenu",
  components :{
    wsLangSelect
  },
  props : {
    value : {
      type : Boolean,
      default : false
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      showBlur : false
    }
  },
  computed : {
    ...mapState('avalon' , ['header' , 'mainMenu']),
    ...mapMutations(['toggleLoginMenu']),
    menuColor() {
      if ( this.header.config.overlap ) {
        let color = this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )

        let opacity = (Math.round(this.header.config.overlay_opacity*2.55) ).toString(16)
        if (opacity.length === 1 ) {
          opacity = '0' + opacity
        }
        if ( this.header.config.overlay_opacity === 0 ) {
          opacity = '00'
        }
        return color + opacity  ;
      }
      return this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )
    },
    menuStyle() {
      let style = `position : fixed;
                   z-index: 9999999;
                   top : ${this.header.config.height_sm * this.SCALE_COEFFICIENT}px;
                   left: 0;
                   right: 0;
                   bottom: 0;
                   inset : ${this.header.config.height_sm * this.SCALE_COEFFICIENT}px 0 0 0;
                   background-color : ${this.menuColor};`

      if ( this.header.config.overlap  ) {
        if ( this.header.config.blur && this.showBlur ) {
          style += `backdrop-filter: blur(${this.header.config.blur}px) !important;
                  -webkit-backdrop-filter: blur(${this.header.config.blur}px) !important ;`
        }

      }
      return style
    }
  },
  methods : {
    ...mapMutations('auth',['LOGOUT']),
    logout() {
      this.LOGOUT(this.$router)
    },
    action(item) {
      if ( item.type === 'block') {
        this.$emit('scroll-to-block' , item.block)
        this.$emit('input' , false)
      }
      if (item.type === 'submenu' ) {
        item.expand = !item.expand
      } else {
        this.$emit('input' , false)
      }
    }
  },
  watch : {
    value(value) {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
      setTimeout(()=> {
        this.showBlur = value
      },1)
    },
    SM() {
      this.$emit('input' , false )
    }
  },
  mounted() {
    this.display = this.value
  }
}
</script>

<style scoped>
</style>