<template>
  <ws-dialog
      v-model="display"
      :title="$t('homepage.sections.consultation.order')"
      :subtitle="$t('homepage.contacts.subtitle')"
      @save="addLead"
      width="700"
  >

      <v-row class="no-gutters pt-5">
        <v-col md="6" cols="12" :class="[ {'pr-2' : !SM} ]" >
          <ws-text-field
              v-model="entityData.name"
              :label="$t('HumanName')"
              :placeholder="$t('EnterHumanName')"
              :error="errorName"
              @input="errorName = false"
              :hide-details="false"
          />
        </v-col>
        <v-col md="6" cols="12" class="pr-1" :class="[ {'pl-2' : !SM} ]">
          <ws-text-field
              v-model="entityData.university"
              :label="$t('SchoolName')"
              :placeholder="$t('EnterSchoolName')"
              :error="errorBusiness"
              @input="errorBusiness = false"
              :hide-details="false"
          />
        </v-col>
        <v-col md="6" cols="12" :class="[ {'pr-2' : !SM} ]">
          <ws-phone-input
              v-model="entityData.phone"
              :label="$t('Phone')"
              :error="errorPhone"
              @input="errorPhone = false"
              :hide-details="false"
          />
        </v-col>
        <v-col md="6" cols="12" :class="[ {'pl-2' : !SM} ]">
          <ws-text-field
              v-model="entityData.email"
              :label="$t('Email')"
              :placeholder="$t('EnterEmail')"
              :error="errorEmail"
              @input="errorEmail = false"
              :hide-details="false"
          />
        </v-col>
      </v-row>




    <ws-text-field
        v-model="entityData.comment"
        :label="$t('Comment') + '*' "
        :placeholder="$t('EnterComment')"
        area
    />

  </ws-dialog>
</template>

<script>
import {mapActions } from "vuex";

export default {
  name: "homeContactFormDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    service : {},
    upgrade : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false,
      entityData : {},

      errorName : false,
      errorPhone : false,
      errorEmail : false,
      errorBusiness : false
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input', this.display)
      }
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', ['ADD_LEAD']),

    addLead() {
      let errorMessage = ''
      if ( !this.entityData.email ) {
        this.errorEmail= true
        errorMessage= this.$t('EnterEmail') }
      if ( !this.entityData.university ) {
        this.errorBusiness = true
        errorMessage= this.$t('contacts.business_name_placeholder') }
      if ( !this.entityData.phone ) {
        this.errorPhone = true
        errorMessage= this.$t('EnterPhone')}
      if ( !this.entityData.name ) {
        this.errorName = true
        errorMessage= this.$t('EnterHumanName') }

      if (errorMessage) { return this.notify(errorMessage) }

      this.ADD_LEAD(this.entityData).then((out)=>{
        if ( out.result ) {
          this.notify(this.$t('home_contacts.messageSent') , 'success')
          this.entityData = {}
          this.display = false
        }
      })
    },
  },
  mounted() {
    this.display = this.value
  }
}
</script>

<style scoped>

</style>