<template>
  <div style="width: 100%">
    <div
         style="width: 100%; position: relative;"
         ref="block"
         class="d-flex align-center mb-5"
         :class="[{'justify-center' : block.config.heading_align === 'center'} , {'justify-end' : block.config.heading_align === 'right'}]"
    >

      <template v-if="!(MOBILE_VIEW || SM)">
        <v-sheet
            :ref="`category_${i}`"
            v-for="(item , i ) in itemsFiltered" :key="`cat_${i}`"
            style="border-radius: 100px"
            @click="localValue = item.value"
            class="px-6 py-2 mb-1 mr-3 pointer"
            v-ripple
            :color="value === item.value ? BLOCK_COLOR_PARAM(block , 'button_bg' , block.config.style ) : 'transparent'"
        >
          <h5 class="text-no-wrap" :style="buttonStyle(item.value)">{{  item.text  }} </h5>
        </v-sheet>
        <ft-select
            style="pointer-events: none"
            :disabled="foldedItems.length < 1"
            :style="foldedItems.length < 1 ? 'opacity : 0; pointer-events: none' : null "
            v-model="localValue"
            :items="foldedItems"
            @expand="dropdownExpanded = $event"
            :background-color="CONFIG_PARAM_COLOR('styles', block.config.style, 'bg')"
            :text-css="BLOCK_TEXT_CSS(block , block.config.style , 'p' )"
            text-color="red"
        >
          <v-sheet
              :style="foldedItems.length < 1 ? 'opacity : 0; pointer-events: none' : null "
              ref="folded_icon"
              style="border-radius: 100px"
              class="px-6 py-2 mb-1 mr-3 pointer"
              v-ripple
              :color="dropdownExpanded ? BLOCK_COLOR_PARAM(block , 'button_bg' , block.config.style ) : 'transparent'"
          >
            <v-icon
                :size="BLOCK_FONT_VARIABLE(block , 'size' , 'buttons' , 'text' )*1.5"
                :color="BLOCK_COLOR_PARAM(block ,  dropdownExpanded ? 'button_text' : 'button_text_secondary' , block.config.style  )" >mdi-dots-horizontal</v-icon>
          </v-sheet>
        </ft-select>
      </template>
      <v-sheet v-else width="100%">
        <ft-select
            v-model="localValue"
            :items="items"
        >
          <v-sheet
              width="100%"
              class=" py-2 mr-3 pointer"
              color="transparent"
          >
            <h5 :style="buttonStyle(1)"  >
              {{ $t('Category')}}:  {{  selectedCategoryName  }}
              <v-icon :color="BLOCK_COLOR_PARAM(block , 'button_text_secondary' , block.config.style  ) ">mdi-menu-down</v-icon>
            </h5>
          </v-sheet>
        </ft-select>
      </v-sheet>



    </div>
  </div>
</template>

<script>
export default {
  name: "tilesCategories",
  props : {
    value : {
      type : String
    },
    items : {
      type : Array,
      default() { return [] }
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    }
  },
  data() {
    return {
      localValue : null,
      foldBreakpoints : [],
      lastWidth : null,
      dropdownExpanded : false
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.COPY(this.items)
      if ( this.foldBreakpoints.length > 0 ){
        items.splice(items.length -this.foldBreakpoints.length ,this.foldBreakpoints.length)
      }
      return items
    },
    foldedItems() {
      if ( this.foldBreakpoints.length === 0 ) {
        return []
      }
      return this.items.filter( (_,index) => index >= this.items.length - this.foldBreakpoints.length  )

    },
    selectedCategoryName() {
      let index = this.items.findIndex( el=> el.value === this.localValue )

      if ( index === -1 ) {
        return this.$t('All')
      }

      return this.items[index].text

    }
  },
  watch : {
    WINDOW_WIDTH() {
      this.handleOverlap()
    },
    items : {
      handler() {
        this.foldBreakpoints = []
        setTimeout(this.handleOverlap,10 )
      },
      deep : true
    },
    localValue() {
      if ( this.value !== this.localValue ) {
        this.$emit('input' , this.localValue)
      }
    },
    value() {
      if ( this.value !== this.localValue ) {
        this.localValue = this.value
      }
    }
  },
  methods : {
    handleOverlap() {
      let block = this.$refs.block
      if ( !block ) {
        return
      }
      let cell = this.$refs.folded_icon
      if ( !cell ) {
        return
      }
      cell = cell.$el

      let blockRect = block.getBoundingClientRect()
      let cellRect  = cell.getBoundingClientRect()
      let cellRight = cellRect.right - blockRect.left

      console.log(cellRight , blockRect.width )

      if ( cellRight > blockRect.width ) {

        this.lastWidth = cellRect.right
        this.foldBreakpoints.push(cellRect.right)
        setTimeout(this.handleOverlap , 40)

      }

    },

    buttonStyle(category = null ) {
      let style = this.BLOCK_TEXT_CSS(this.block , this.block.config.style , 'buttons' , 'text')
      style = style.slice(13)
      style += `color : ${ this.BLOCK_COLOR_PARAM(this.block ,  category === this.localValue ? 'button_text' : 'button_text_secondary' , this.block.config.style  ) };`
      return style
    },
  },
  mounted() {
    if ( this.value ) {
      this.localValue = this.value
    }
    this.handleOverlap()
    setTimeout(()=> {
      this.handleOverlap()
    },10)
  }
}
</script>

<style scoped>

</style>