var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"fill-height",class:[
        {'px-10' : !(_vm.SM || _vm.MOBILE_VIEW)} ,
        {'py-8' : !(_vm.SM || _vm.MOBILE_VIEW)} ,
        {'px-3' : (_vm.SM || _vm.MOBILE_VIEW)} ,
        {'py-5' : (_vm.SM || _vm.MOBILE_VIEW)}
    ],staticStyle:{"transition":"transform 0.3s ease","position":"relative"},style:(("border : 1px solid " + _vm.wsBACKGROUND + " !important;")),attrs:{"color":"transparent"}},[_c('div',{staticClass:" justify-space-between align-center",class:[{'d-flex' : !(_vm.SM || _vm.MOBILE_VIEW)} ]},[_c(_vm.routerLinkTag,{tag:"router-link",staticClass:"noUnderline",class:[{'pointer' : !!_vm.item.route }],attrs:{"to":_vm.businessPublicLink( _vm.defaultSite ? ("course/" + (_vm.item.uuid)) : (_vm.item.route ||  ("course/" + (_vm.item.uuid)))  )}},[_c('div',{staticClass:"mr-8",staticStyle:{"max-width":"600px"}},[(_vm.config.display_category && _vm.item.category_name)?_c('h4',{style:(_vm.BLOCK_TEXT_CSS(_vm.block , _vm.block.config.style , 'tiles-courses' , 'category'))},[_vm._v(" "+_vm._s(_vm.item.category_name)+" ")]):_vm._e(),_c('h3',{staticClass:"mt-4",class:[{'linkHover' : _vm.item.route} , { 'mt-4' : _vm.config.display_category && !!_vm.item.category_name }],style:(_vm.BLOCK_TEXT_CSS(_vm.block , _vm.block.config.style , 'tiles-courses' , 'title'))},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.config.display_description)?_c('h4',{staticClass:"mt-4",style:(_vm.BLOCK_TEXT_CSS(_vm.block , _vm.block.config.style , 'tiles-courses' , 'description'))},[_vm._v(_vm._s(_vm.item.short_description))]):_vm._e(),(_vm.item.discount && _vm.item.payed)?_c('h4',{staticClass:"mt-4",style:(_vm.BLOCK_TEXT_CSS(_vm.block , _vm.block.config.style , 'tiles-courses' , 'discount'))},[_vm._v(" "+_vm._s(_vm.item.discount)+" % "+_vm._s(_vm.$t('Discount').toLowerCase())+", "),_c('span',{staticStyle:{"text-decoration":"line-through"}},[(_vm.item.has_payment_plans)?[_vm._v(_vm._s(_vm.$t('priceFrom'))+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.item.has_payment_plans ? _vm.item.min_price : _vm.item.price)+" "+_vm._s(_vm.BUSINESS_CURRENCY)+" ")],2)]):_vm._e()])]),_c('div',{class:!(_vm.SM || _vm.MOBILE_VIEW) ? 'd-flex' : 'mt-3'},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"pointer d-flex px-4 py-3 text-no-wrap",class:(_vm.SM || _vm.MOBILE_VIEW) ? 'justify-center' : '',attrs:{"color":_vm.BLOCK_COLOR_PARAM(_vm.block , 'tiles-courses_button_bg' , _vm.block.config.style ) + (hover ? '66' : '')},on:{"click":function($event){$event.stopPropagation();return _vm.openCourseDialog($event)}}},[_c('h5',{staticClass:"d-flex align-center",style:(_vm.BLOCK_TEXT_CSS(_vm.block, _vm.block.config.style , 'tiles-courses' , 'button'))},[(_vm.item.payed || _vm.coursePrice !== 0 )?[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.BLOCK_COLOR_PARAM(_vm.block , 'tiles-courses_button' , _vm.block.config.style )}},[_vm._v("mdi-basket")]),_vm._v(" "+_vm._s(_vm.$t('Purchase'))+" ("),(_vm.item.has_payment_plans)?[_vm._v(_vm._s(_vm.$t('priceFrom'))+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.coursePrice)+" "+_vm._s(_vm.BUSINESS_CURRENCY)+") ")]:_c('span',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('Register'))+" ")])],2)])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }