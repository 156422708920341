<template>
  <router-link
      :is="preview || $store.state.avalon.editorMode ? 'span' : 'router-link'"
      :to="businessPublicLink('news/' + item.alias)"
      class="noUnderline"
  >
    <v-sheet
        color="transparent"
        class="fill-height pointer px-10 py-8 "
        style="transition: transform 0.3s ease; position: relative"
        :style="`border : 1px solid ${wsBACKGROUND} !important;`"
    >
      <v-row no-gutters>
        <v-col cols="12" md="5" class="pr-2">
          <div  style="padding: 24px" :style="`padding-bottom : ${34 + (config.block_radius/2)}px`">

            <h4 v-if="config.display_news"
                :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-news' , 'date')" class="mt-2">{{ MONTH_DAY_TIME(item.date) }}</h4>

            <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-news' , 'title')" class="mt-4" >{{ item.title }}</h3>

            <h4 v-if="config.display_description"
                :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-news' , 'description')" class="mt-4">{{ item.description }}</h4>


          </div>
        </v-col>
        <v-col cols="12" md="7" class="pl-2">
          <ws-img
              v-if="item.img && config.display_images"
              :src="item.img"
              height="400px"
              vertical-position="50"
              horizontal-position="50"
              auto-size
              :trigger-resize="imageResizeTrigger"
          />
        </v-col>
      </v-row>

    </v-sheet>
  </router-link>
</template>

<script>
export default {
  name: "blockTilesDesign1",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    config  : {
      type : Object,
      default() { return {} }
    },
    cols : {
      type : Number
    },
    preview : {
      type : Boolean,
      default : false
    },
    block  : {
      type : Object,
      default() { return { config : {}} }
    },
  },
  data() {
    return {
      imageResizeTrigger : 0
    }
  },
  watch : {
    cols() {
      this.imageResizeTrigger++
    },
  }
}
</script>

<style scoped>

</style>