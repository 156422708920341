<template>
  <div :style="editor ? 'pointer-events : none' : ''">
    <!-- Header Content-->
    <div :style="headerStyle" style="z-index : 1000 !important; transition : left 0.3s ease"  >
        <div style="position:relative; width: 100%">
          <!-- ::  Header content :: -->
          <v-sheet :color="headerColor"   width="100%" >


            <!-- DESKTOP -->
            <template v-if="!(SM || MOBILE_VIEW)">

              <header-design-default
                  v-if="!header.config.design || header.config.design === 'default'"
                  :preview="preview"
                  @scroll-to-block="$emit( 'scroll-to-block' , $event)"
              />
              <header-design-dream
                  v-else-if="header.config.design === 'dream_style' "
                  :preview="preview"
                  @scroll-to-block="$emit( 'scroll-to-block' , $event)"
              />


            </template>

            <!-- MOBILE -->
            <template v-else>

              <header-design-default-mobile
                  :preview="preview"
                  @scroll-to-block="$emit( 'scroll-to-block' , $event)"
              />

            </template>


          </v-sheet>
        </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";
import headerDesignDefault from "@/components/AvalonEditor/View/header/UI/headerDesignDefault";
import headerDesignDream from "@/components/AvalonEditor/View/header/UI/headerDesignDream";
import headerDesignDefaultMobile from "@/components/AvalonEditor/View/header/UI/headerDesignDefaultMobile";
export default {
  name: "viewAvalonHeader",
  components : {
    headerDesignDefaultMobile,
    headerDesignDefault,
    headerDesignDream
  },
  props : {
    preview : {
      type : Boolean,
      default : false
    },
    editor : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      showMobileMenu : false,
      edit : false,
    }
  },
  computed : {
    ...mapState('avalon',[
        'editorMode' ,
        'header' ,
        'sideMenu',
        'mainMenu' ,
        'previewMode',
        'selectedLang' ,
        'updateHeaderTrigger' ,
        'scrollPosition'
    ]),

    headerStyle() {


      if ( this.header.config.sticky ) {

        let style = `position : fixed; z-index : 300 ;
                     top : ${((this.editorMode || (this.editorMode && this.sideMenu)))  && !this.previewMode ? `${this.EDITOR_PREHEADER_HEIGHT}px` : '0'} ;
                     left : ${ (((this.editorMode || (this.editorMode && this.sideMenu)) || (this.sideMenu  && this.editorMode))) && !this.previewMode  ? 280 : 0}px; right : 0;`
        if ( this.editorMode && this.MOBILE_VIEW ) {
          style += `width : ${this.MOBILE_WIDTH}; margin-left : auto; margin-right : auto;`
        }
        if ( this.header.config.overlap && this.header.config.blur ) {
          style += `backdrop-filter: blur(${this.header.config.blur}px);
                   -webkit-backdrop-filter: blur(${this.header.config.blur}px);`
        }
        return style
      }

      return 'z-index : 300 ; position : relative '

    },
    headerColor() {
      if ( this.header.config.overlap ) {
        let color = this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )

        let opacity = (Math.round(this.header.config.overlay_opacity*2.55) ).toString(16)
        if (opacity.length === 1 ) {
          opacity = '0' + opacity
        }
        if ( this.header.config.overlay_opacity === 0 ) {
          opacity = '00'
        }
        return color + opacity  ;
      }
      return this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )
    },
  },
  watch : {
    LANG() {
      this.updateMenu()
    }
  },
  methods : {
    // technical
    ...mapActions('avalon' , [ 'GET_MAIN_MENU' ]),
    ...mapMutations(['toggleLoginMenu']),

    async updateMenu() {
      let result = await this.GET_MAIN_MENU()
      if ( !result ) {
        return
      }
      this.$store.state.avalon.mainMenu = result.main_menu

    },
    layoutStyle(hover ) {
      let style = 'transition : all 0.3s ease;'

      style += `background-color : ${ this.avalonConfig.colors.background_main }` + ';'
      if ( !this.header.config ) {
        this.$store.state.avalon.header.config = {}
      }

      style += `height : ${this.header.config.height || 50}px`



      if ( (hover || this.edit) && this.editorMode ) {
        style += `-webkit-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `-moz-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
      }

      return style
    },

  },
  mounted() {
  },

}
</script>

<style scoped>

</style>