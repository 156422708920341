<template>
  <div class="px-4">
    <!-- Tiles Content-->

    <v-fade-transition mode="out-in">
      <v-row
          :key="currentPage" no-gutters
          :style="rowStyle"
      >
<!--        :cols="12"-->
        <v-col
            :style="getColStyle(i)"
            v-for="(item , i) in tilesItems" :key="i"

            :cols="!(MOBILE_VIEW || SM) ? columnsSizes[block.config.columns_large || 3] : 12"

        >
          <slot name="item" :item="item">
            <block-tiles-design1
                :item="item"
                :config="block.config"
                :block="block"
                @action="itemAction(item)"
                :cols="cols"
            />
          </slot>

        </v-col>
      </v-row>
    </v-fade-transition>

    <!-- DIALOGS-->
    <!-- Sidebar / Dialog -->
    <slider-sidebar
        v-if="displaySidebar"
        v-model="displaySidebar"
        :item="selectedItem"
        :block-style="block.config.style"
        :popup="displayDialog"
    />


  </div>
</template>

<script>
export default {
  name: "blockGallery",
  components : {
    blockTilesDesign1 : () => import("@/components/AvalonEditor/Editor/blocks/tiles/blockTilesDesign1"),
    sliderSidebar      : () => import("@/components/AvalonEditor/Editor/blocks/slider/sliderSidebar")
  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    items : {
      type : Array
    }
  },
  data() {
    return {
      currentPage : 0,
      columnsSizes : {
        1 : 12,
        2 : 6,
        3 : 4,
        4 : 3,
        6 : 2,
        12 : 1
      },
      displaySidebar : false,
      displayDialog : false,
      selectedItem : {}
    }
  },
  computed : {
    cols() {
      return !this.MOBILE_VIEW ? this.columnsSizes[this.block.config.columns_large || 3] : 12
    },
    rowStyle() {
      let style = ''
      if ( this.block.config.gutter && !( this.SM || this.MOBILE_VIEW ) ) {
        style += `margin-left : -${this.block.config.gutter/2}px;`
        style += `margin-right : -${this.block.config.gutter/2}px;`
      }
      return style
    },
    config() {
      return this.block.config || {}
    },
    tilesItems() {
      if ( this.items ) {
        return this.items
      }
      return this.block.config.elements || []
    },
    itemsFiltered() {

      if (!this.config.elements || this.config.elements.length === 0 ) {
        return []
      }

      let minimumIndex = this.currentPage * this.block.config.items_per_page
      let maximumIndex = (this.currentPage+1) * this.block.config.items_per_page

      let items = this.config.elements
      items = items.filter((el, index) =>

          index >= minimumIndex && index < maximumIndex

      )

      return items
    },
    nextIsAvailable() {
      if ( !this.block.config.elements ) {
        return false
      }
      return this.tilesItems.length > (this.currentPage*this.block.config.items_per_page + this.block.config.items_per_page )
    },
    previousIsAvailable() {
      return this.currentPage !== 0
    }
  },
  methods : {
    itemAction(item) {
      if ( this.$store.state.avalon.editorMode && !['sidebar','enroll_on_course','dialog'].includes(item.action) ) {
        this.notify(this.$t('avalon.links_disabled_in_editor') , 'warning')
        return
      }

      this.selectedItem = item

      if ( item.action === 'sidebar') {
        this.displayDialog = false
        this.displaySidebar = true
      }

      if ( item.action === 'dialog') {
        this.displaySidebar = true
        this.displayDialog = true
      }

    },
    getColStyle(index ) {
      let style = ''
      if ( this.block.config.gutter_vertical &&
               (!(this.SM || this.MOBILE_VIEW) && index >= this.block.config.columns_large )
            || ( (this.SM || this.MOBILE_VIEW) && index > 0 )
      ) {
        style += `margin-top : ${this.block.config.gutter_vertical}px;`
      }
      if ( this.tilesItems.length > 1 && this.block.config.gutter && !(this.SM || this.MOBILE_VIEW) ) {
        style += `padding-left : ${this.block.config.gutter/2}px;`
        style += `padding-right : ${this.block.config.gutter/2}px; `
      }
      return style
    },
    nextPage() {
      if ( !this.nextIsAvailable ) {
        return
      }
      this.currentPage++
    },
    previousPage() {
      if ( !this.previousIsAvailable ) {
        return
      }
      this.currentPage--
    }
  }
}
</script>

<style scoped>

</style>