<template>
  <v-sheet
      :style="`border-top : 1px solid ${wsDARKLIGHT} !important`"
      class="d-flex flex-row  "
      color="transparent"
  >

    <v-sheet class="pl-4 py-4" color="transparent" width="150">
      <ws-button
          v-if="step > 1"
          @click="$emit('back')"
          :color="wsACCENT"
          label="GoBack"
          outlined
      />
    </v-sheet>

    <div class="flex-grow-1 fill-height">

      <div class="d-flex justify-center align-center fill-height">

        <template v-for="(item , index) in  navigationList" >
          <v-sheet
              :key="index"
              class="fill-height d-flex flex-column" color="transparent">
            <div style="height: 4px"></div>

            <div class="flex-grow-1 d-flex align-center">
              <h5 @click="gotoStep(index + 1)"
                  :class="[{pointer : (index + 1 <=  step) }]"
                  :style="`color : ${index + 1 <=  step  ? wsATTENTION : wsDARKLIGHT}`">
                {{ $t(`avalon.new.footer_navigation.${item}`) }}
              </h5>
            </div>

            <div :style="`background :  ${step === index + 1 ? wsATTENTION : 'transparent'};`"
                 style="border-top-left-radius:4px; border-top-right-radius: 4px; height: 4px"
            />

          </v-sheet>

          <v-sheet
              v-if="index  < navigationList.length - 1"
              :key="index"
              color="transparent" width="48" class="d-flex justify-center align-center">
            <v-icon :color="index + 1 <= step  ? wsATTENTION : wsDARKLIGHT" >mdi-chevron-right</v-icon>
          </v-sheet>

        </template>


      </div>

    </div>

    <v-sheet  color="transparent" class="d-flex justify-end pr-4 py-4" width="150">
      <ws-button
          @click="$emit('next')"
          :disabled="disableNextCondition"
          label="Next"
          icon="mdi-chevron-right"
          min-width="100"
      />
    </v-sheet>

  </v-sheet>
</template>

<script>
export default {
  name: "newSiteFooter",
  props : {
    isFromTemplates : {
      type : Boolean,
      default : false
    },
    step : {
      type : Number,
      default : 0
    },
    homepage : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      navigationFromBlocks : ['homepage' , 'pages' , 'colors' , 'fonts' ],
      navigationFromTemplates : ['list' , 'preview' ]
    }
  },
  computed : {
    currentStepName() {
      return this.navigationList[this.step - 1]
    },
    navigationList() {
      return this.isFromTemplates ? this.navigationFromTemplates : this.navigationFromBlocks
    },
    disableNextCondition() {
      let result = true
      Object.keys(this.homepage).forEach(el => {
        if ( this.homepage[el] ) {
          result =  false
        }
      })
      return result
    }
  },
  watch :{
    currentStepName(value) {
      this.$emit('step-name' , value)
    }
  },
  methods : {
    gotoStep(step) {
      if ( step <  this.step  ) {
        this.$emit('step' , step )
      }
    }
  }
}
</script>

<style scoped>

</style>