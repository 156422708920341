<template>
  <div>
    <transition mode="out-in">
      <!-- LOGIN WINDOW-->
      <div v-if="!signUp && !restoreWindow" key="log">
        <form   @submit.prevent="login">
          <!-- LOGIN: EMAIL-->
          <div class="d-flex  align-center my-6 mb-10"
               :class="displayHide(['sm']) ? 'justify-space-between' : 'justify-center' ">
            <h4 v-if="displayHide(['sm'])" :style="`color : ${wsACCENT}`">
              {{$t('Login')}}:
            </h4>
            <ws-toggle
                v-model="toggle"
                @input="loginType = $event ? 0 : 1"
                :text-true="$t('ByEmail')"
                :text-false="$t('ByTelephone')"
            >

            </ws-toggle>
          </div>
          <ws-text-field
              v-if="loginType===0"
              v-model="email"
              label="Email"
              :placeholder="$t('EnterEmail')"
              :error-messages="emailError"
              :hide-details="false"
              required
              clearable
          />

          <ws-phone-input
              v-if="loginType===1"
              v-model="phoneNumber"
              :label="$t('Phone')"
              :placeholder="$t('EnterPhone')"
              :error-messages="emailError"
              @input="emailError = []"
          />

          <ws-text-field
              v-model="password"
              :label="$t('Password')"
              :placeholder="$t('FillPassword')"
              :error-messages="passwordError"
              :hide-details="false"
              password
              required
              clearable
          />

          <v-btn
              @click="resetPasswordModal(); restoreWindow = true"
              :color="wsACCENT"
              block class="noCaps" text>
            {{ $t('ForgetPassword') }}
          </v-btn>

          <v-btn
              :loading="LOADING"
              large
              :disabled="loader"
              :color="wsATTENTION"
              block    dark
              type="submit"
              class=" mt-10 mt-10 noCaps"
              elevation="0"
          >
            <v-icon small class="mr-2">mdi-login-variant</v-icon>
            {{ $t('Login') }}
          </v-btn>
          <v-btn :disabled="LOADING" large  block @click="signUp=true" :color="wsACCENT"  text class=" noCaps" elevation="0">
            {{ $t('Register') }}
          </v-btn>

        </form>
      </div>
      <!-- REGISTRATION WINDOW-->
      <div v-if="signUp && !restoreWindow" key="sign">
        <form  @submit.prevent="nextStep">
          <v-btn v-if="displayRegister" @click="signUp = false; step = 1 ;" dark :color="wsATTENTION" block class="noCaps pointer mb-3 hover-underline" >
            {{ $t('Already have account ?')}}
          </v-btn>
          <h4 class="mb-6 mt-3 font-weight-medium text-center">
            {{ $t('SignUp') }}
          </h4>
          <transition mode="out-in">
            <!-- REGISTER: EMAIL, PASSWORD -->
            <section key="s1" v-if="step==1">

              <ws-text-field
                  v-model="signEmail"
                  label="Email"
                  :placeholder="$t('EnterEmail')"
                  :error-messages="signEmailError"
                  outlined
                  required
                  :hide-details="false"
              />
              <ws-text-field
                  v-model="signPassword"
                  :error-messages="signPasswordError"
                  :label="$t('Password')"
                  :placeholder="$t('FillPassword')"
                  password
                  size="sm"
                  required
                  :hide-details="false"
              />
              <ws-text-field
                  v-model="signPassword2"
                  :error-messages="signPasswordError"
                  :label="$t('PasswordRepeat')"
                  :placeholder="$t('FillPasswordRepeat')"
                  password
                  required
                  :hide-details="false"
              />
            </section>
            <section key="s2" v-if="step==2">
              <ws-text-field
                  v-model="signFirstName"
                  :label="$t('HumanName')"
                  :placeholder="$t('EnterHumanName')"
                  :hide-details="false"
                  required
              />
              <ws-text-field
                  v-model="signLastName"
                  :label="$t('Lastname')"
                  :placeholder="$t('EnterLastname')"
                  :hide-details="false"
                  required
              />
              <ws-phone-input
                  v-model="phoneNumber"
                  :error="phoneError"
                  @input="phoneError = false"
                  :label="$t('Phone')"
                  :hide-details="false"
                  required
              />
              <ws-date-picker
                  v-model="signBirth"
                  :clearable="false"
                  :label="$t('Birthday')"
              />
            </section>
            <section key="s3" v-if="step==3">
              <v-text-field mdi-calendar  v-model="signCountry" label="Country" size="sm" required/>
              <v-text-field mdi-calendar  v-model="signProvince" label="Province" size="sm" required/>
              <v-text-field mdi-calendar  v-model="signCity" label="City" size="sm" required/>
            </section>
            <section key="s4" v-if="step==4">
              {{ regOutput }}
            </section>
          </transition>
          <v-row class="mt-6 mb-3">
            <v-col cols="6" >
              <v-btn v-if="(displayRegister && step > 1) || !displayRegister" :color="wsACCENT" :disabled="LOADING" large block text @click="back"    class="noCaps mr-3">
                <v-icon class="mr-2">mdi-chevron-left</v-icon>
                {{ $t('Back') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :loading="LOADING" :disabled="loader" dark :color="wsATTENTION" large block type="submit"   elevation="0"  class="noCaps " >
                {{ $t('Next')}}
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </form>
      </div>
      <!-- RESTORE PASSWORD WINDOW-->
      <div v-if="restoreWindow" key="restore">
          <!-- RESTORE: EMAIL-->
          <div class="d-flex justify-space-between align-center mb-6">
<!--            <h4 class="">Відновлення: </h4>-->
<!--            <v-btn-toggle background-color="transparent" borderless mandatory v-model="restoreType" dense>-->
<!--              <v-btn value="email" color="transparent" class="noCaps">Через Пошту</v-btn>-->
<!--              <v-btn value="phone" color="transparent" class="noCaps">За телефоном</v-btn>-->
<!--            </v-btn-toggle>-->
          </div>
        <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" :color="wsLIGHTCARD" class="d-flex align-center mb-7 pa-3 wsRoundedHalf">
          <v-icon class="mr-2" :color="wsACCENT">mdi-information</v-icon>
          <h5 v-if="restoreStep === 0" >

            <span v-if="restoreType==='email'">
               {{ $t('EmailPasswordRestorationText') }}
            </span>
            <span v-if="restoreType==='phone'">
               Введіть номер телефону, на який зареєстровано ваш обліковий запис, на нього буде відправлено SMS із кодом зміни паролю
            </span>

          </h5>
          <h5 v-if="restoreStep === 1" >{{ $t('EnterCodeAndNewPassword')}} </h5>
        </v-sheet>



          <ws-text-field
              v-if="restoreStep === 0 & restoreType==='email'"
              v-model="email"
              label="Email"
              :placeholder="$t('EnterEmail')"
              :error-messages="emailError"
              :hide-details="false"
          />
          <v-text-field  v-if="restoreStep === 0 & restoreType==='phone'"
                         v-model="phoneNumber"
                         :error-messages="emailError"
                         @blur="phoneInputFocus  = false"
                         @focus="phoneInputFocus  = true"
                         class="red--text"
                         @keypress="onlyNumberInput($event)" ref="phone"
                         placeholder="063-111-22-33"
                         :label="$t('Phone')"

                         dense filled rounded  >
            <template  slot="prepend-inner" >
              <div class="grey--text text--lighten-1"
                   :style="phoneInputFocus || phoneNumber ? 'margin-top:17px' : 'margin-top:5px' "
                   style="width: 50px; transition: all 0.3s ease ">
                {{ phoneCountryCode }}
              </div>
            </template>
          </v-text-field>

          <ws-text-field
              v-if="restoreStep === 1"
              v-model="restoreCode"
              :label="$t('PasswordChangeCode')"
              :placeholder="$t('PasswordChangeCode')"
              :error-messages="restoreCodeError"
              @input="restoreCodeError = []"
              filled rounded
              dense
              :hide-details="false"
          />
          <ws-text-field
              v-if="restoreStep === 1"
              v-model="signPassword"
              :label="$t('NewPassword')"
              :placeholder="$t('EnterNewPassword')"
              :error-messages="signPasswordError"
              password
              filled rounded
              required
              :hide-details="false"
          />
          <ws-text-field
              v-if="restoreStep === 1"
              v-model="signPassword2"
              :label="$t('NewPasswordRepeat')"
              :placeholder="$t('NewPasswordRepeat')"
              :error-messages="signPasswordError"
              password
              filled rounded
              required
              :hide-details="false"
          />

          <v-row class="mt-10">
            <v-col cols="6">
              <v-btn
                  @click="restorePrevStep"
                  :color="wsACCENT"
                  :disabled="LOADING"
                  class="noCaps"
                  large block text >
                <v-icon class="mr-2">mdi-chevron-left</v-icon>
                {{ $t('Back')}}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                  @click="restoreNextStep"
                  :loading="LOADING"
                  :disabled="LOADING"
                  :color="wsATTENTION"
                  large block dark
                  elevation="0"
                  class="noCaps "
              >
                  {{ $t('Next')}}
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>

      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  name: "authWindow",
  props : {
    dark : Boolean,
    noToggle : Boolean,
    horizontal : Boolean,
    light : Boolean,
    fullSize : {
      type : Boolean,
      default : false
    },
    white : {
      type : Boolean,
      default: false,
    },
    displayRegister : {
      type : Boolean,
      default: false,
    }
  },
  data() {
    return {
      toggle : true,
      loading  : false,

      restoreWindow : false,
      restoreStep : 0,
      restoreCode : '',
      restoreEmail : '',
      restoreCodeError : [],
      restoreType : 'email',

      phoneCountryCode : '+38',
      phoneNumber : '',
      phoneInputFocus : false,
      phoneError : false,

      loginType : 0,
      email: '',
      password: '',
      signEmail: '',
      signPassword: '',
      signPassword2: '',
      signFirstName: '',
      signLastName: '',
      signTelephone: '',
      signBirth: '2022-08-24',
      signCountry: '',
      signProvince: '',
      signCity: '',
      signUp : false,
      signBusiness : false,
      regOutput : '',
      step : 1,
      validLogin: false,
      validSign1: false,
      validSign2: false,
      validSign3: false,
      emailError:[],
      passwordError:[],
      signEmailError:[],
      signPasswordError:[],
      birthError : '',
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,
      menuBirth : false,
    }
  },
  computed : {
    ...mapState(['showLoginMenu']),
    ...mapState('auth',['loader']),

  },
  watch : {
    // validation block

    email() {
      this.emailError = [];
    },
    password() {
      this.passwordError = [];
    },
    signPassword2() {
      this.signPasswordError=[];
    },
    signPassword() {
      this.signPasswordError=[];
    },
    signEmail() {
      this.signEmailError=[];
    },
    birthMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    showLoginMenu() {
      this.FLUSH_LOADER()
    }
  },
  methods : {
    ...mapActions('auth',['LOGIN','REGISTER','CHECK_EMAIL','GET_USER','REQUEST_PASSWORD','CHANGE_PASSWORD']),
    ...mapMutations('auth',['SET_RESEND_TOKEN','SHOW_MODAL_CONFIRMAION','SHOW_MODAL_CONFIRM_INFO','SHOW_MODAL_PASSWORD_RESET','FLUSH_LOADER']),
    ...mapMutations(['toggleLoginMenu']),
    onlyNumberInput(input){

      input = (input) ? input : window.event;
      var charCode = (input.which) ? input.which : input.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        input.preventDefault();
      } else {
        return true;
      }

    },
    async login() {
      this.email = this.email.replace(/ /g,'');
      if(  !this.reg.test(this.email) && this.loginType !== 1 ) {
        this.emailError = [this.$t('WrongEmail')];
        return
      }

      if(  this.loginType === 1 ) {
        if ( !this.phoneNumber) {
          return this.notify(this.$t('EnterCorrectPhoneNumber'))
        }
      }
      let data = {
        email: this.email,
        phone : this.phoneNumber,
        password: this.password,
      }

      if (!await this.LOGIN(data)) {
        this.emailError = [this.$t('UserNotFoundOrWrongPassword')];
        return
      }

      this.flushLogin();
      if ( !this.noToggle ) {
        this.toggleLoginMenu();
      }
      this.$emit('success')

    },
    restorePrevStep() {
      this.restoreStep--;
      if (this.restoreStep === -1) {
        this.restoreWindow = false;
        this.restoreStep = 0
      }
    },
    async restoreNextStep() {
      switch(this.restoreStep) {
        case 0 :
          var restoredata = {}
          if (this.restoreType === 'email' )  {
            this.email = this.email.replace(/ /g,'');
            if(!this.reg.test(this.email)) {
              this.emailError = [this.$t('WrongEmail')];
              break;
            }
            restoredata.email = this.email
          }
          if ( this.restoreType === 'phone' ) {
            restoredata.phone = this.phoneNumber
          }
          var result = await this.REQUEST_PASSWORD(restoredata)
          if ( !result ) {
            this.emailError = [this.$t('UserNotFound')]
          } else {
            this.restoreStep++
          }

           break;
        case 1 :
          if ( this.signPassword !== this.signPassword2 ) {
            this.signPasswordError = [this.$t('PasswordRepeatNotMatch')];
            break;
          }
          this.loading = true
            var data = {
              code : this.restoreCode,
              password : this.signPassword,
              repeat_password : this.signPassword2
            }
          if ( this.restoreType === 'email' ) {
            data.email = this.email
          }
          if ( this.restoreType === 'phone' ) {
            data.phone = '' + this.phoneCountryCode + this.phoneNumber
          }

          var passwordChangeResult =  await this.CHANGE_PASSWORD(data)

            if ( !passwordChangeResult ) {

              this.restoreCodeError = [this.$t('PasswordResetCodeError')]
            } else {

              this.notify(this.$t('PasswordChangedMessage'))
              this.flushRestore()
              if (!this.noToggle) {
                this.toggleLoginMenu()
              }
            }

          break;
      }
    },
    async nextStep() {
      let registerReady = false
      switch (this.step) {
        // Email and Password

        case 1: var check=0;

          // Validate email
          this.signEmail = this.signEmail.replace(/ /g,'');
          if(!this.reg.test(this.signEmail)) {
            this.signEmailError = [this.$t('WrongEmail')];
          } else {
            if ( !await this.CHECK_EMAIL({type : 'email', email : this.signEmail}) ) {
              this.signEmailError = [this.$t("UserAlreadyExists")];
              this.notify(this.$t('UserAlreadyExistsEmail') , 'warning')
              return
            }
            check++;
            if (check === 2) { this.step++ }

          }

          // Validate Password
          if (this.signPassword !== this.signPassword2) {
            this.signPasswordError = [this.$t('PasswordRepeatNotMatch')];
          } else { check++; }

          // just in case ASYNC CHECK EMAIL SUPER FAST
          if (check === 2 ) { this.step++ }
          break;
        // Name,telephone, birthday
        case 2:
          if (!this.signBirth) {
            this.birthError = [this.$t('EnterBirthday')]
            return
          }
          if ( !this.phoneNumber.length ) {
            this.emailError = [this.$t('EnterPhone')];
            return
          } else {
            this.signTelephone = this.phoneNumber

            let check = await this.CHECK_EMAIL({type : 'phone', phone : this.signTelephone})

            if ( !check ) {
              if ( this.$store.state.ajax.error === 'User already exists') {
                this.emailError = [this.$t('UserAlreadyExists')];
                this.phoneError = true
                this.notify(this.$t('UserAlreadyExistsPhone') , 'warning')
              }

              return
            }
            this.completeRegister()

          }
          break;

        case 3:


          break;
      }

      if ( !registerReady  ) {
        console.log(registerReady)
      }
    },
    async completeRegister() {
      var data = {
        name: this.signFirstName,
        lastname: this.signLastName,
        email: this.signEmail,
        password : this.signPassword,
        repeat_password : this.signPassword,
        phone : this.signTelephone,
        birthday : this.signBirth,
        is_businessman : this.signBusiness,
      }

      if ( this.$store.state.templates.businessAlias ) {
        data.subscription = this.$store.state.templates.businessAlias
      }

      let result = await this.REGISTER(data)
      if ( !result ) {
        if ( this.$store.state.ajax.error === 'Phone is too long') {
          this.phoneError = true
          this.notify(this.$t('PhoneNumberTooLongError') , 'warning')
        }
        return
      }
      this.signUp = false;
      if (!this.noToggle ) {
        this.toggleLoginMenu()
      }

      this.flushRegister()
      this.$store.state.auth.userLinkKey = result.link_key
      this.$emit('success')
    //  this.$store.state.auth.showNewUserDialog = true

    },
    back() {
      this.step--;
      if (this.step == 0) {
        this.signUp = false;
        this.step = 1
      }
    },
    birthSave (date) {
      this.birthError = []
      this.$refs.menu.save(date)
    },
    flushLogin() {
      this.email = ''
      this.password = ''
    },
    flushRestore() {
      this.restoreCode = '';
      this.restoreCodeError =[]
      this.restoreWindow = false
      this.restoreStep = 0;
      this.email = ''
    },
    flushRegister() {
      this.signUp = false;
      this.step = 1;
      this.signEmail = ''
      this.signPassword = ''
      this.signPassword2 = ''
      this.signFirstName = ''
      this.signLastName = ''
      this.signBirth = ''
      this.signCountry = ''
      this.signProvince = ''
      this.signCity = ''
      this.phoneNumber = ''
      this.emailError = []
    },
    resetPasswordModal() {
     // this.toggleLoginMenu();
     //  this.SHOW_MODAL_PASSWORD_RESET()
      this.flushLogin()

    }
  },
  mounted() {
    if ( this.displayRegister ) {
      this.signUp = true
    }
  }
}
</script>


<style scoped>
.link {
  text-decoration: none }
.link_dark {
  color: white !important;
}

/*ANIMATION */
.v-enter {
  opacity: 0 !important;
  transform: translateX(-50px) ;
}
.v-enter-active {

  transition: all 0.2s ease-out;
}
.v-leave-active {
  transition: all 0.2s ease-out;
  opacity: 0 !important;
  transform: translateX(-50px) ;
}
.loginTypeButton:after {
  background-color: #00000000 !important;
  bottom:0;
}
</style>