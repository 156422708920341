<template>
  <ws-dialog
      :title="title"
      v-model="$store.state.showLoginMenu"
      no-navigation
      width="392"
  >
      <auth-window-new
          display-login
          :auth-route="businessDashLink('')"
          dialog
          @navigation="navigation = $event"
      />

  </ws-dialog>
</template>

<script>
import authWindowNew from "@/components/auth/authWindowNew";
import { mapMutations, mapState} from 'vuex';

export default {
  name: "loginSideBar",
  components : {
    authWindowNew
  },
  data() {
    return {
      navigation : 'login'
    }
  },
  computed : {
    ...mapState(['showLoginMenu']),

    title() {
      switch (this.navigation) {
        case 'proceed' : return null
        case 'login' : return this.$t('Login')
        case 'register' : return this.$t('Register')
        default : return  this.$t('Authorization')
      }
    }
  },
  watch : {
    showLoginMenu() {
      this.FLUSH_LOADER()
    }
  },
  methods : {
    ...mapMutations('auth',['FLUSH_LOADER']),
    ...mapMutations(['toggleLoginMenu']),
  }
}
</script>

<style scoped>

.stopScroll {
  height: 100vh;
  width: 100% !important;
  top:0;
  left:0;
  position: fixed;

}


/*ANIMATION */
.v-enter {
  opacity: 0 !important;
  transform: translateX(-50px) ;
}
.v-enter-active {

  transition: all 0.2s ease-out;
}
.v-leave-active {
  transition: all 0.2s ease-out;
  opacity: 0 !important;
  transform: translateX(-50px) ;
}

</style>