<template>
  <div>
    <!-- Header -->
    <v-sheet

        color="transparent"
        :height="(header.config.height_sm || 45) * SCALE_COEFFICIENT"
        class="d-flex justify-space-between align-center pa-2 px-3 "
        style="width: 100%"
    >

      <router-link
          :to="businessPublicLink('')"
          :is="preview || $store.state.avalon.editorMode ? 'span' : 'router-link'"
          class="noUnderline"
      >
        <img v-if="header.config.logo"  :height="30*SCALE_COEFFICIENT + 'px'"   alt="WeStudy" :src="header.config.logo" />
        <h1 v-else
            :key="SCALE_COEFFICIENT + 'logo_text'"
            :style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'title' , null , null , header, DEFAULT_BLOCK_WIDTH)"
        >{{ header.config.title || 'Company'  }}</h1>
      </router-link>
      <v-btn
          @click="showMobileMenu = !showMobileMenu"
          :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
          icon
          :height="30*SCALE_COEFFICIENT"
          :width="30*SCALE_COEFFICIENT"
      >
        <v-icon :size="24*SCALE_COEFFICIENT">
          mdi-{{ !SM || (SM && !showMobileMenu) ? 'menu' : 'close' }}
        </v-icon>
      </v-btn>
    </v-sheet>

    <!-- Sidebars and Popups-->
    <view-mobile-menu
        v-model="showMobileMenu"
        @scroll-to-block="$emit( 'scroll-to-block' , $event)"
        :preview="preview"
    />

  </div>


</template>

<script>
import {mapState} from "vuex";

import viewMobileMenu from "@/components/AvalonEditor/View/header/viewMobileMenu";


export default {
  name: "headerDesignDefaultMobile",
  props: {
    preview : {
      type : Boolean,
      default : false
    }
  },
  components : {
    viewMobileMenu
  },
  data() {
    return {
      showMobileMenu : false
    }
  },
  computed : {
    ...mapState('avalon', ['editorMode', 'header', 'sideMenu', 'mainMenu', 'selectedLang', 'updateHeaderTrigger', 'scrollPosition']),
  }
}
</script>

<style scoped>

</style>