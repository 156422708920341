<template>
  <div>
    <v-hover v-for="(item,index) in items" :key="index" #default="{hover}">
      <v-sheet
          @click="selectItem(item)"
          :style="`border-top : 1px solid ${wsBACKGROUND};`"
          class="py-3 d-flex align-center pointer"
          :color="hover ? wsLIGHTCARD : ''"
      >
        <ws-check-box
            @prevent="selectItem(item)"
            :value="selectedPages.map(el => el.category).includes(item.value)"
            :color="wsACCENT"
            :selected-color="wsACCENT"
            prevent-select
            :small="false"
            class="ml-2"
        />
        <h5 :style="`color : ${wsDARKER}`">
          {{ item.text }}
        </h5>
      </v-sheet>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "newSiteSidebarHomepage",
  props : {
    items : {
      type : Array,
      default() { return {} }
    },
    homepage : {
      type : Object,
      default() { return {} }
    },
    selectedPages : {
      type : Array,
      default() { return [] }
    },
  },
  methods : {
    selectItem(item) {
      this.$emit('select' , {category : item.value , categoryName : item.text} )
    }
  }
}
</script>

<style scoped>

</style>