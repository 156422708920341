<template>
    <v-sheet
        color="transparent"
        class="fill-height"
        style="transition: transform 0.3s ease; position: relative"
        :style="`border : 1px solid ${wsBACKGROUND} !important;`"
        :class="[
            {'px-10' : !(SM || MOBILE_VIEW)} ,
            {'py-8' : !(SM || MOBILE_VIEW)} ,
            {'px-3' : (SM || MOBILE_VIEW)} ,
            {'py-5' : (SM || MOBILE_VIEW)}
        ]"
    >
      <div class=" justify-space-between align-center"
           :class="[{'d-flex' : !(SM || MOBILE_VIEW)} ]"
      >
        <router-link
            :is="routerLinkTag"
            :to="businessPublicLink( defaultSite ? `course/${item.uuid}` : (item.route ||  `course/${item.uuid}`)  )"
            class="noUnderline"
            :class="[{'pointer' : !!item.route }]"
        >
        <div class="mr-8" style="max-width: 600px" >

          <h4 v-if="config.display_category && item.category_name"
              :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-courses' , 'category')" >
            {{ item.category_name }} </h4>

          <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-courses' , 'title')" class="mt-4"
              :class="[{'linkHover' : item.route} , { 'mt-4' : config.display_category && !!item.category_name }]" >
          {{ item.name }}
          </h3>

          <h4 v-if="config.display_description"
              :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-courses' , 'description')" class="mt-4">{{ item.short_description }}</h4>

          <h4 v-if="item.discount && item.payed"
              :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-courses' , 'discount')" class="mt-4">
            {{ item.discount }} % {{ $t('Discount').toLowerCase() }},
            <span style="text-decoration: line-through">
               <template v-if="item.has_payment_plans">{{ $t('priceFrom') }} </template>
          {{  item.has_payment_plans ? item.min_price : item.price  }}
          {{  BUSINESS_CURRENCY  }}
          </span>

          </h4>

        </div>
        </router-link>
        <div :class="!(SM || MOBILE_VIEW) ? 'd-flex' : 'mt-3'">
          <v-hover #default="{hover}">
            <v-sheet
                @click.stop="openCourseDialog"
                :color="BLOCK_COLOR_PARAM(block , 'tiles-courses_button_bg' , block.config.style ) + (hover ? '66' : '')"
                class="pointer d-flex px-4 py-3 text-no-wrap"
                :class="(SM || MOBILE_VIEW) ? 'justify-center' : ''"
                v-ripple
            >
              <h5  :style="BLOCK_TEXT_CSS(block, block.config.style , 'tiles-courses' , 'button')" class="d-flex align-center">

                <template v-if="item.payed || coursePrice !== 0 ">
                  <v-icon :color="BLOCK_COLOR_PARAM(block , 'tiles-courses_button' , block.config.style )" class="mr-2">mdi-basket</v-icon>
                  {{ $t('Purchase') }}
                  (<template v-if="item.has_payment_plans">{{ $t('priceFrom') }} </template>
                  {{  coursePrice }}
                  {{  BUSINESS_CURRENCY  }})
                </template>
                <span class="px-2" v-else>
                  {{ $t('Register') }}
                </span>

              </h5>

            </v-sheet>
          </v-hover>
        </div>
      </div>

    </v-sheet>
</template>

<script>
export default {
  name: "blockTilesDesign1",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    config  : {
      type : Object,
      default() { return {} }
    },
    cols : {
      type : Number
    },
    preview : {
      type : Boolean,
      default : false
    },
    block  : {
      type : Object,
      default() { return { config : {}} }
    },
    defaultSite : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      imageResizeTrigger : 0
    }
  },
  computed : {
    routerLinkTag() {
      if ( this.preview ) {
        return 'span'
      }
      if ((this.$store.state.avalon.editorMode )) {
        return 'span'
      }
      return 'router-link'
    },
    coursePrice() {
      return (this.item.has_payment_plans ? this.item.min_price : this.item.price)
          - Math.round((this.item.discount || 0)/100*(this.item.has_payment_plans ? this.item.min_price : this.item.price))
    }
  },
  watch : {
    cols() {
      this.imageResizeTrigger++
    },
  },
  methods : {
    openCourseDialog( ) {
      this.$emit('open-course-dialog' , this.item )
    }
  }
}
</script>

<style scoped>

</style>