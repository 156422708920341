import { render, staticRenderFns } from "./newSiteSidebarColors.vue?vue&type=template&id=085759ee&scoped=true&"
import script from "./newSiteSidebarColors.vue?vue&type=script&lang=js&"
export * from "./newSiteSidebarColors.vue?vue&type=script&lang=js&"
import style0 from "./newSiteSidebarColors.vue?vue&type=style&index=0&id=085759ee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085759ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VHover,VSheet})
