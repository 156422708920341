<template>
  <div v-if="(!autohide || (autohide && langSelect.length > 1)) && !list">
    <v-menu :open-on-hover="true" open-on-click  offset-y transition="slide-y-transition">
      <template v-slot:activator="{attrs,on}">
        <v-sheet
            v-bind="attrs" v-on="on"
            class="d-flex align-center"
            color="transparent"
            rounded v-ripple style="cursor: pointer" >
<!--          <v-icon :color="textColor || wsACCENT">mdi-web</v-icon>-->
          <h5  style="text-transform: uppercase"
               :style="activatorStyle || (textColor ? `color : ${textColor}` : '')"
          >
           {{$i18n.locale.toUpperCase()}}
          </h5>

          <v-icon :color="textColor || wsDARKER" >mdi-menu-down</v-icon>
        </v-sheet>
      </template>
      <v-sheet :color="backgroundColor" max-height="400" class="overflow-y-auto">
        <v-list dense :style="backgroundColor ? `background-color : ${backgroundColor}` : ''"  >

          <v-list-item-group v-model="lang"  >
            <div v-for="(item,i) in langSelect" :key="i" @click="changeLang(item.value)">
               <v-list-item   >
                  <v-list-item-title >
                    <h5
                      :style="textStyle || (textColor ? `color : ${textColor}` : '')"
                      class="text-uppercase"
                    >
                      {{item.text  }}
                    </h5>
                  </v-list-item-title>
                </v-list-item>
            </div>

          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-menu>


  </div>
  <div v-else-if="(!autohide || (autohide && langSelect.length > 1)) && list">
    <v-btn
        exact-active-class="active"
        @click="expand = !expand "
        :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
        class="noCaps d-flex pointer justify-space-between py-3"
        block text
        height="45"
    >
      <h4 :style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'links')"
          class="mr-5 text-no-wrap" >
        {{$i18n.locale.toUpperCase()}}
      </h4>
      <v-icon
              :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2"
              :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">
        mdi-chevron-down
      </v-icon>
    </v-btn>
    <v-expand-transition>
      <div v-if="expand">
        <div  v-for="(item,i) in langSelect" :key="i">
          <ws-link
              :to="item.link"
              :disabled="preview || $store.state.avalon.editorMode"
          >
            <v-btn
                @click="changeLang(item.value)"
                :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                block text height="45"  class="noCaps pl-8 d-flex pointer justify-space-between py-3" >
              <h4 :style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'links')"  class="mr-5 text-no-wrap" >
                {{ item.text }}
              </h4>
            </v-btn>
          </ws-link>
        </div>
      </div>
    </v-expand-transition>


  </div>

</template>

<script>

import {mapState} from "vuex";

export default {
  name: "wsLangSelect",
  components : {

  },
  props : {
    backgroundColor : {
      type : String
    },
    textColor : {
      type : String
    },
    activatorStyle : {
      type : String
    },
    textStyle : {
      type : String
    },
    autohide : {
      type : Boolean,
      default : false
    },
    disabled : {
      type : Boolean,
      default : false
    },
    list : {
      type : Boolean,
      default : false
    }

  },
  data() {
    return {
      lang : 'ua',
      expand : false
    }
  },
  computed: {
    ...mapState('avalon' , ['header' ]),

    langSelect(){
      let items = []

      this.PARAMS_languages.forEach((lang)=>{

        let path = this.$route.path

         if ( this.$i18n.locale === process.env.VUE_APP_I18N_LOCALE ) {
           if( lang !== process.env.VUE_APP_I18N_LOCALE ) {
             path = '/' + lang +  path
           }
         } else {
           if( lang !== process.env.VUE_APP_I18N_LOCALE ) {
             path = '/' + lang + path.substring(3)
           } else  {
             path = path.substring(3)
           }

         }

        items.push( { text : lang, value : lang, link: path })
      })
      return items
    }
  },
  methods : {
    changeLang(input) {
      if ( this.disabled )  {
        return
      }
      this.LOCALE_CHANGE(input)
    }
  }
}
</script>

<style scoped>

</style>