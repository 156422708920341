<template>
  <div  v-if="!noBlocks" style="padding-top: 26px" >
    <view-header
        preview
        style="zoom : 0.45"
    />

    <div v-if=" !selectedPage.value || selectedPage.value === 'home'" style="position : relative " >

        <div  class="overflow-x-hidden" style="position: absolute; width : 1500px; transform: scale(0.4666);
                  transform-origin: top left;">

          <template v-for="(item) in homePageBlocksSelect" >
            <v-hover #default="{hover}"   v-if="homepage[item.value]"  :key="item.value + 'block'">
              <div style="position: relative; " :style="`background-color: ${wsACCENT}`" >
                <ws-button
                    v-if="hover"
                    @click="$emit('edit-block' , item.value)"
                    outlined
                    :color="wsACCENT"
                    label="Change"
                    style="position: absolute; zoom : 2.0; background-color: #ffffff; z-index: 999; bottom : 24px; right : 24px"
                />
                <view-block
                    :style="hover ? `opacity : 0.92` : ''"
                    :key="homepage[item.value]"
                    :block="getBlock(homepage[item.value]).block"
                    :elements="getBlock(homepage[item.value]).elements"
                    preview
                />
              </div>

            </v-hover>
          </template>




        </div>

    </div>

    <div v-else style="min-height : 600px; position : relative " >

      <div  class="overflow-x-hidden" style="position: absolute; width : 1500px; transform: scale(0.4666);
                  transform-origin: top left;">
      <view-page
          preview
          :key="selectedPage.value"
          :page="selectedPage.value"
          template
      />
      </div>
    </div>


  </div>
  <div v-else style="padding-top: 26px;" class="fill-height d-flex align-center justify-center">
    <div>
      <div class="d-flex justify-center">
        <v-icon size="80" :color="wsDARKLIGHT">mdi-widgets</v-icon>
      </div>
      <h3 class="mt-5" style="font-size: 22px" :style="`color : ${wsDARKLIGHT}`">{{  $t('avalon.new.homepage.no_blocks')  }}</h3>
    </div>
  </div>
</template>

<script>
import viewPage from "@/components/AvalonEditor/View/viewPage";
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";
import viewHeader from "@/components/AvalonEditor/View/header/viewHeader";
export default {
  name: "newSitePageViewer",
  components : {
    viewBlock,
    viewPage,
    viewHeader
  },
  props : {
    homepage : {
      type : Object,
      default() { return {} }
    },
    step : {
      type : Number,
      default : 1
    },
    selectedPage : {
      type : Object,
      default() { return {} }
    },
    selectedPages : {
      type : Array,
      default() { return [] }
    },
    blocks : {
      type : Array,
      default() { return [] }
    },
    homePageBlocksSelect : {
      type : Array,
      default() { return [] }
    }
  },
  computed : {
    noBlocks() {
      let result = true
      Object.keys(this.homepage).forEach(el => {
        if ( this.homepage[el] ) {
          result =  false
        }
      })
      return result
    }
  },
  methods : {
    getBlock(value) {
      return this.blocks.find(el => el.block.id === value)
    },
    getBlockElements(id) {
      if (this.elements.length === 0 ) {
        return []
      }
      return this.elements.filter(el=>el.block_id === id)
    },
  }
}
</script>

<style scoped>

</style>