<template>
  <v-fade-transition>
    <v-sheet
        v-if="display"
        :style="menuStyle"
        style="z-index : 9999999999"
        :key="header.id"
    >

      <v-sheet
          class="fill-height"
          :style="`margin-left : ${pagePadding}px`"
          :width="logoWidth + 70"
          :color="menuColor"
      >
        <v-row class="no-gutters flex-wrap flex-column fill-height">

          <!-- Menu Items-->

          <v-col  cols="auto" class="shrink" >
            <div class="d-flex align-center px-3 mt-n1">
              <router-link
                  ref="logo"
                  :is="preview || $store.state.avalon.editorMode ? 'span' : 'router-link'"
                  class="noUnderline mr-3" :to="businessPublicLink('')">
                <img v-if="header.config.logo"  :height="`${header.config.logo_height || 50}px`"  alt="WeStudy" :src="header.config.logo" />
                <h1 v-else
                    :style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'title')"
                >{{ header.config.title || 'Company'  }}</h1>
              </router-link>

              <v-btn
                  @click="$emit('input' , false)"
                  :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')" class="mr-3" icon>
                <v-icon large>mdi-close</v-icon>
              </v-btn>

            </div>
          </v-col>

          <v-col cols="auto" class="grow overflow-y-auto" style="height: 100px"  >
            <template v-for="(item, i) in mainMenu" >

              <div :key="'mobile_menu' + i" >
                <ws-link
                    :href="item.url || null"
                    :to="item.route ? businessPublicLink(item.route + (item.block || '')) : null"
                    :new-window="item.open_in_new_window"
                    :disabled="preview || $store.state.avalon.editorMode"
                >
                  <v-btn
                      exact-active-class="active"
                      @click="action(item)"
                      :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                      class="noCaps d-flex pointer justify-space-between py-3"
                      block text
                      :height="45*SCALE_COEFFICIENT"
                  >
                    <h4 :key="SCALE_COEFFICIENT + 'link'"
                        :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)"
                        class="mr-5 text-no-wrap" >
                      {{ $t(item.text) }}
                    </h4>
                    <v-icon v-if="item.type === 'submenu' "
                            :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                            :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </ws-link>
                <v-expand-transition>
                  <div v-if="item.expand">
                    <div v-for="(child,j) in item.children" :key="'mobile_sub_item' + i + j">
                      <ws-link
                          :href="child.url || null"
                          :to="child.route ? businessPublicLink(child.route + (child.block || '')) : null"
                          :new-window="child.open_in_new_window"
                          :disabled="preview || $store.state.avalon.editorMode"
                      >
                        <v-btn
                            @click="$emit('input' , false)"
                            :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                            block text
                            :height="45*SCALE_COEFFICIENT"
                            class="noCaps pl-8 d-flex pointer justify-space-between py-3"
                        >
                          <h4 :key="SCALE_COEFFICIENT + 'sub-link'"
                              :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)"  class="mr-5 text-no-wrap" >
                            {{ $t(child.text) }}
                          </h4>
                        </v-btn>
                      </ws-link>
                    </div>
                  </div>
                </v-expand-transition>

              </div>



            </template>

            <!-- Languages select -->
            <ws-lang-select
                class="mr-4"
                :background-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_dropdown_background')"
                :text-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                :activator-style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'links')"
                :text-style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'dropdown_links')"
                autohide
                :disabled="preview || $store.state.avalon.editorMode"
                list
            />


          </v-col>


          <!-- Login Button-->

          <v-col    v-if="!header.config.hide_button" cols="auto" class="shrink" >
            <v-divider :style="`border-color : ${CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')}`" />
            <div class=" py-2">

              <v-btn
                  v-if="$store.state.auth.loggedIn"
                  @click="toggleLoginMenu(); $emit('input' , false)"
                  :to="businessDashLink('')"
                  :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                  block
                  text
                  class="noCaps  d-flex pointer justify-space-between py-2"
                  :min-height="45*SCALE_COEFFICIENT"
              >
                <h4 :key="SCALE_COEFFICIENT + 'dashboard'"
                    :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)" class="mr-5 text-no-wrap" >
                  {{ $t('GoToDashboard') }}
                </h4>
                <v-icon
                    :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                    :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">mdi-view-dashboard</v-icon>
              </v-btn>

              <v-btn
                  v-if="!$store.state.auth.loggedIn"
                  @click="toggleLoginMenu(); $emit('input' , false)"
                  :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                  block
                  text
                  class="noCaps  d-flex pointer justify-space-between py-2"
                  :min-height="45*SCALE_COEFFICIENT"
              >
                <h4 :key="SCALE_COEFFICIENT + 'login'"
                    :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)"
                    class="mr-5 text-no-wrap" >
                  {{ $t('Login') }}
                </h4>
                <v-icon
                    :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                    :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">mdi-login</v-icon>
              </v-btn>

              <v-btn
                  v-else
                  @click="  logout() ; $emit('input' , false);"
                  :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
                  block
                  text
                  class="noCaps  d-flex pointer justify-space-between py-2"
                  :min-height="45*SCALE_COEFFICIENT"
              >
                <h4
                    :key="SCALE_COEFFICIENT + 'login'"
                    :style="BLOCK_TEXT_CSS(header , header.config.style, 'header' , 'links' , null, null, DEFAULT_BLOCK_WIDTH)" class="mr-5 text-no-wrap" >
                  {{ $t('Logout') }}
                </h4>
                <v-icon
                    :size="CONFIG_FONT_VARIABLE('size' , 'header' , 'links')*1.2*SCALE_COEFFICIENT"
                    :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">mdi-logout</v-icon>
              </v-btn>

            </div>
          </v-col>



        </v-row>
      </v-sheet>





    </v-sheet>
  </v-fade-transition>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import wsLangSelect from "@/components/UI/wsLangSelect";

export default {
  name: "viewMobileMenu",
  components :{
    wsLangSelect
  },
  props : {
    value : {
      type : Boolean,
      default : false
    },
    preview : {
      type : Boolean,
      default : false
    },
    logoWidth : {}
  },
  data() {
    return {
      display : false,
      showBlur : false
    }
  },
  computed : {
    ...mapState('avalon' , ['header' , 'mainMenu']),
    ...mapMutations(['toggleLoginMenu']),

    pagePadding() {
      if ( this.WINDOW_WIDTH > this.header.config.width ) {
        return (this.WINDOW_WIDTH - this.header.config.width)/2
      }
      return 0
    },
    menuColor() {
      if ( this.header.config.overlap ) {
        let color = this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )

        let opacity = (Math.round(this.header.config.overlay_opacity*2.55) ).toString(16)
        if (opacity.length === 1 ) {
          opacity = '0' + opacity
        }
        if ( this.header.config.overlay_opacity === 0 ) {
          opacity = '00'
        }
        return color + opacity  ;
      }
      return this.CONFIG_PARAM_COLOR('styles' , this.header.config.style , 'header_background' )
    },
    menuStyle() {
      let style = `position : fixed;
                   z-index: 9999999;
                   top: 0;
                   left: 0;
                   right: 0;
                   bottom: 0;
                   background : #00000044;`

      if ( this.header.config.overlap  ) {
        if ( this.header.config.blur && this.showBlur ) {
          style += `backdrop-filter: blur(${this.header.config.blur}px) !important;
                  -webkit-backdrop-filter: blur(${this.header.config.blur}px) !important ;`
        }

      }
      return style
    }
  },
  methods : {
    ...mapMutations('auth',['LOGOUT']),
    logout() {
      this.LOGOUT(this.$router)
    },
    action(item) {
      if ( item.type === 'block') {
        this.$emit('scroll-to-block' , item.block)
        this.$emit('input' , false)
      }
      if (item.type === 'submenu' ) {
        item.expand = !item.expand
      } else {
        this.$emit('input' , false)
      }
    }
  },
  watch : {
    value(value) {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
      setTimeout(()=> {
        this.showBlur = value
      },1)
    },
    SM() {
      this.$emit('input' , false )
    }
  },
  mounted() {
    this.display = this.value
  }
}
</script>

<style scoped>
</style>