<template>
<div>
  <!-- Heading-->
  <div class="d-flex justify-space-between align-center mb-8">
    <h2 :style="`font-size: ${!SM ? 24 : 18}px`">
      {{ $t('homepage.register.create_account') }}
    </h2>
    <h4 v-if="!hideLogin"
        @click="$emit('login' , true)"
        :style="`color : ${wsATTENTION}`"
        class="linkHover text-no-wrap"
        style="font-size: 13px" >
      {{ $t('homepage.register.or_login')}}
    </h4>
  </div>

  <!-- Auth Data -->
  <div v-if="step === 'auth_data'">



    <!-- Google Facebook Sign in / Login -->
    <template >
      <!-- Google Facebook Sign in / Login -->
      <div v-if="!$store.state.AGENT_SOCIAL_APP">
        <v-btn
            :href="socialsAuthUrlGoogle"
            @click="handleAvalonElementAfterRefresh"
            :color="wsACCENT"
            class="noCaps d-flex justify-space-between"
            height="40"
            block outlined
            :disabled="!socialsAuthUrlGoogle"
        >
          <v-icon size="20" :color="wsATTENTION">mdi-google</v-icon>
          {{ $t('homepage.register.google.proceed') }}
          <div/>
        </v-btn>
        <v-btn
            :href="socialsAuthUrlFacebook"
            @click="handleAvalonElementAfterRefresh"
            :color="wsACCENT"
            class="noCaps d-flex justify-space-between mt-5"
            height="40"
            outlined
            block
            :disabled="!socialsAuthUrlFacebook"
        >
          <v-icon size="20" color="primary">mdi-facebook</v-icon>
          {{ $t('homepage.register.facebook.proceed') }}
          <div/>
        </v-btn>
      </div>
      <v-sheet v-else class="pa-3 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">
        <h5 class="text-center wsACCENT">{{ $t('AuthSocialWarningTitle') }}</h5>
        <h5 class="mt-3 font-weight-regular text-center">{{ $t('AuthSocialWarningDescription') }}</h5>
      </v-sheet>
      <!-- Separator -->
      <div class="d-flex justify-space-between align-center my-6">
        <v-divider :style="`border-color : ${wsBACKGROUND}`" class="mr-10" />
        <h5 :style="`color : ${wsACCENT}`">{{ $t('or') }}</h5>
        <v-divider :style="`border-color : ${wsBACKGROUND}`" class="ml-10" />
      </div>
    </template>






    <!-- Register Window -->
    <div >
      <ws-text-field
          v-model="entityData.email"
          :label="$t('Email')"
          :placeholder="$t('EnterEmail')"
          :error="!!error.email"
          :error-messages="error.email"
          @input="error.email = null"
          :hide-details="false"
      />
      <ws-text-field
          style="z-index : 9999999999"
          v-model="entityData.password"
          :label="$t('Password')"
          :placeholder="$t('EnterPassword')"
          :error="!!error.password"
          :error-messages="error.password"
          @input="error.password = null"
          :hide-details="false"
          new-password
      />
    </div>
  </div>
  <!-- Personal Data -->
  <div v-if="step === 'personal_data'">
    <ws-text-field
        v-model="entityData.name"
        :label="$t('HumanName')"
        :placeholder="$t('EnterHumanName')"
        :error="!!error.name"
        :error-messages="error.name"
        @input="error.name = null"
        :hide-details="false"
    />
    <ws-text-field
        v-model="entityData.lastname"
        :label="$t('Lastname')"
        :placeholder="$t('EnterLastname')"
        :error="!!error.lastname"
        :error-messages="error.lastname"
        @input="error.lastname = null"
        :hide-details="false"
    />
    <ws-phone-input
        v-model="entityData.phone"
        :label="$t('Phone')"
        :placeholder="$t('EnterPhone')"
        :error="!!error.phone"
        :error-messages="error.phone"
        @input="error.phone = null"
        :hide-details="false"
    />
    <ws-date-picker
        v-model="entityData.birthday"
        :label="$t('Birthday')"
        :placeholder="$t('Birthday')"
        new-password
    />
  </div>


  <!-- Buttons -->
  <div class="d-flex justify-end align-center mt-10">
    <ws-button
        v-if="step !== 'auth_data'"
        @click="prevAction"  :color="wsACCENT" text height="40">
      <v-icon>mdi-chevron-left</v-icon>
      {{ $t('GoBack') }}
    </ws-button>
    <ws-button
        @click="nextAction"
        :color="wsATTENTION"
        :disabled="LOADING || $store.state.auth.registerTimeoutOn"
        :loading="LOADING"
    >
      {{ $t('Continue') }}
      <v-icon>mdi-chevron-right</v-icon>
    </ws-button>
  </div>

</div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "registerWindow",
  props : {
    hideLogin : {
      type : Boolean,
      default : false
    },
    noAuthAction : {
      type : Boolean,
      default : false
    },
    isNewBusiness : {
      type : Boolean,
      default : false
    },
    elementUuid : {
      type : String
    }
  },
  data() {
    return {
      error : {
        email : null,
        password : null,
        name : null,
        lastname : null,
        phone : null
      },
      emailError : null,
      passwordError : null,

      entityData : {
        birthday : '2022-08-24'
      },
      step : 'auth_data',
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

    }
  },
  computed : {
    ...mapState('auth' , ['socialsAuthUrlGoogle' , 'socialsAuthUrlFacebook'])
  },
  methods : {
    ...mapActions('auth' , ['CHECK_EMAIL' , 'REGISTER']),

    handleAvalonElementAfterRefresh() {
      if ( this.elementUuid ) {
        localStorage.setItem('avalon_refresh_element' , this.elementUuid)
      }
    },

    continueGoogle() {
      this.notify('continueGoogle')
    },
    continueFacebook() {
      this.notify('continueFacebook')
    },
    prevAction() {
      this.step = "auth_data"
    },
    nextAction() {
      switch(this.step) {
        case 'auth_data' : this.authDataAction(); return
        case 'personal_data' : this.registerAction(); return
      }
    },
    async authDataAction() {

      this.entityData.email = this.entityData.email.replace(/ /g,'');

      if( !this.reg.test(this.entityData.email) ) {
        this.error.email = this.$t('WrongEmail')
        return
      }
      if ( !this.entityData.password ) {
        this.error.password = this.$t('EnterPassword')
        return
      }
      if ( !await this.CHECK_EMAIL({type : 'email', email : this.entityData.email}) ) {
        this.error.email = this.$t("UserAlreadyExists");
        return
      }

      this.step = "personal_data"
    },
    async registerAction() {
      if ( !this.entityData.name ) {
        this.error.name = this.$t('EnterHumanName')
        return
      }
      if ( !this.entityData.lastname ) {
        this.error.lastname = this.$t('EnterLastname')
        return
      }
      if ( !this.entityData.phone ) {
        this.error.phone = this.$t('EnterPhone')
        return
      }
      this.entityData.repeat_password = this.entityData.password
      this.entityData.is_businessman = this.isNewBusiness

      if ( this.$store.state.templates.businessAlias && !this.entityData.is_businessman ) {
        this.entityData.subscription = this.$store.state.templates.businessAlias
      }

      let result = await this.REGISTER(this.entityData)
      if ( result === 'timeout' ) {
        return this.notify(this.$t('RegisterTimeoutMessage') , 'warning')
      }
      if ( !result ) {
        return this.notify(this.$t(this.$store.state.ajax.error) , 'warning')
      }
      this.notify(this.$t('homepage.register.success') , 'success')

      if ( this.noAuthAction) {
        this.$emit('success')
        return
      }

      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      if ( this.$store.state.business.selectedBusiness.alias ) {
        this.$router.push(this.businessDashLink(''))
        return
      }

      this.$router.push(this.localeLink('dashboard'))


    }
  }
}
</script>

<style scoped>

</style>