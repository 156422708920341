<template>
  <v-sheet
      :max-width="header.config.width"
      :height="header.config.height"
      class="mx-auto d-flex justify-space-between align-center px-3"
      color="transparent"
  >

    <!-- LOGO -->
    <router-link
        ref="logo"
        :is="preview || $store.state.avalon.editorMode ? 'span' : 'router-link'"
        class="noUnderline" :to="businessPublicLink('')">
      <img v-if="header.config.logo"  :height="`${header.config.logo_height || 50}px`"  alt="WeStudy" :src="header.config.logo" />
      <h1 v-else
          :style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'title')"
      >{{ header.config.title || 'Company'  }}</h1>
    </router-link>

    <!-- Menu Items  -->
    <v-sheet ref="menuElements" color="transparent" class="d-flex align-center ">

      <template v-for="(item, i) in itemsFiltered" >
        <view-menu-item
            @scroll-to-block="$emit( 'scroll-to-block' , $event)"
            :item="item"
            :key="i"
            :preview="preview"
            :ref="`menu_item_${item.value}`"
        />
      </template>

      <ft-select
          v-if="foldedItems.length > 0"
          :items="foldedItems"
          translate
          open-on-hover
          color-black
          :background-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_dropdown_background')"
          :text-css="CONFIG_TEXT_CSS(header.config.style , 'header' , 'dropdown_links')"
          :text-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
          :disable-actions="preview || $store.state.avalon.editorMode"
          left
      >
        <v-btn
            :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')" class="mr-3" icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </ft-select>


      <ws-lang-select
          class="mr-4"
          :background-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_dropdown_background')"
          :text-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
          :activator-style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'links')"
          :text-style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'dropdown_links')"
          autohide
          :disabled="preview || $store.state.avalon.editorMode"
      />

      <!-- Socials-->
      <div v-if="$store.state.avalon.header.config.socials" class="d-flex align-center">
        <a v-for="(social,i) in $store.state.avalon.header.config.socials" :key="i"
           :href="social.link"
           :is="preview || $store.state.avalon.editorMode ? 'span' : 'a'"
           target="_blank"
        >

          <v-sheet
              v-if="IS_WESTUDY_ICON(social.img)"
              color="transparent"
              :src="social.img"
              :style="`
              -webkit-mask: url('${social.img}') no-repeat ;
              mask: url('${social.img}') no-repeat 50% 50%;
              mask-size : contain;
              position:relative;
              background-color: ${CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')} !important;`
              "
              :height="social.size || 24"
              :width="social.size || 24"
              contain
              class="mr-3"
              :key="social.img"
          />
          <ws-img
              v-else
              :src="social.img"
              :height="social.size || 34"
              :width="social.size || 34"
              class="mr-3"
              contain
          />
        </a>


      </div>

      <v-btn
          v-if="!header.config.hide_button"
          :to="!preview && !$store.state.avalon.editorMode ? businessDashLink('') : null"
          :href="header.config.button_action === 'link' ? header.config.button_url : null"
          :key="'header_button' + updateHeaderTrigger"
          :color="CONFIG_PARAM_COLOR('styles', header.config.style, `header_button_${header.config.style_button || 'primary'}_color`)"
          class="noCaps"
          elevation="0"
          :outlined="header.config.button_style === 'outline' || header.config.style_button === 'secondary'"
          :text="!header.config.style_button || header.config.style_button === 'text'"
          :style="`border-radius: ${header.config.button_radius}px`"
          dark
      >
        <h5 :style="CONFIG_TEXT_CSS(
                          header.config.style ,
                          'header' ,
                          `button_${header.config.style_button === 'primary' ? 'primary' : 'secondary'}_text`
                          )"
            class="px-3">
          {{ $t( !$store.state.auth.loggedIn || preview || $store.state.avalon.editorMode ? 'Login' : 'PersonalDashboard') }}
        </h5>
      </v-btn>


    </v-sheet>
  </v-sheet>
</template>

<script>
import {mapState} from "vuex";

import viewMenuItem from "@/components/AvalonEditor/View/header/viewMenuItem";
import wsLangSelect from "@/components/UI/wsLangSelect";
import userDropdown from "@/components/UI/userDropdown";
import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";
import viewMobileMenu from "@/components/AvalonEditor/View/header/viewMobileMenu";


export default {
  name: "headerDesignDefault",
  props: {
    preview : {
      type : Boolean,
      default : false
    }
  },
  components : {
    viewMenuItem,
    wsLangSelect,
    userDropdown,
    viewButtonCourseEnroll,
    viewMobileMenu
  },
  data() {
    return {
      X1 : 0,
      X2 : 0,
      foldBreakpoints : [],
      lastWidth : null,
      bufferTime : 0,
      bufferTimeTotal : 5000,
    }
  },
  computed : {
    ...mapState('avalon', ['editorMode', 'header', 'sideMenu', 'mainMenu', 'selectedLang', 'updateHeaderTrigger', 'scrollPosition']),

    itemsFiltered() {
      let items = this.COPY(this.mainMenu)
      if ( this.foldBreakpoints.length > 0 ){
        items.splice(items.length -this.foldBreakpoints.length ,this.foldBreakpoints.length)
      }
      return items
    },
    foldedItems() {

      if ( this.foldBreakpoints.length === 0 ) {
        return []
      }

      return this.mainMenu.filter( (_,index) => index >= this.mainMenu.length - this.foldBreakpoints.length  )

    },

    logoRightPadding() {
      return 16
    },
  },
  watch : {
    WINDOW_WIDTH() {

      this.getDimensions()
    }
  },
  methods : {
    handleOverlap() {
      let menuElements = this.$refs.menuElements.$el
      if ( !menuElements ) {
        return
      }
      let width = menuElements.getBoundingClientRect().width
      let distance = this.X1 - this.X2

      if ( this.X1 > this.X2 ) {
        if ( this.foldBreakpoints.length > 0 && this.lastWidth < (width + distance) ) {
          this.foldBreakpoints.splice(-1,1)
          if ( this.foldBreakpoints.length > 0 ) {
            this.lastWidth = this.foldBreakpoints[this.foldBreakpoints.length - 1]
          } else {
            this.lastWidth = null
          }
        }
        return
      }

      this.lastWidth = width
      this.foldBreakpoints.push(width)
    },
    getDimensions() {
      let logo = this.$refs.logo
      if ( !logo ) {
        return
      }
      if ( logo.$el ) {
        logo = logo.$el
      }

      let logoRect = logo.getBoundingClientRect()
      this.X2 = logoRect.right + this.logoRightPadding

      let menuElements = this.$refs.menuElements.$el
      if ( !menuElements ) {
        return
      }
      let menuElementsRect = menuElements.getBoundingClientRect()
      this.X1 = menuElementsRect.left

      this.handleOverlap()

    },
    initPage() {
      this.getDimensions()
      setTimeout( ()=> {
        this.bufferTime +=50
        if ( this.bufferTime < this.bufferTimeTotal ) {
          this.initPage()
        }
      } , 50 )
    }
  },

  mounted() {
    this.$nextTick(()=> {
      this.initPage()
    })
  }
}
</script>

<style scoped>

</style>