import { render, staticRenderFns } from "./font.vue?vue&type=template&id=1e885130&scoped=true&"
import script from "./font.vue?vue&type=script&lang=js&"
export * from "./font.vue?vue&type=script&lang=js&"
import style0 from "./font.vue?vue&type=style&index=0&id=1e885130&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e885130",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VFadeTransition})


/* vuetify-loader */
import installDirectives from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
