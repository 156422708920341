<template>
  <v-hover #default="{hover}">

      <v-sheet
        :color="BLOCK_COLOR_PARAM(block , 'tiles-courses_card_background' , block.config.style )"
        class="fill-height d-flex flex-column"
        :class="[{'pointer' : !!item.route }]"
        style="transition: transform 0.3s ease; position: relative"
        :style="`border-radius : ${block.config.block_radius}px; ${hover ? 'transform: scale(1.01)' : ''}`"
      >
        <router-link
            :is="routerLinkTag"
            :to="businessPublicLink( defaultSite ? `course/${item.uuid}` : (item.route ||  `course/${item.uuid}`)  )"
            class="noUnderline"
        >
        <v-sheet
            v-if="block.config.display_images"
            :color="wsACCENT"
            :height="config.image_height || 200"
            :style="`border-top-left-radius: ${block.config.block_radius}px !important; border-top-right-radius: ${block.config.block_radius}px !important; height : ${block.config.image_height || 200}px`"
            style="position: relative"
            width="100%"
        >
          <ws-img
              v-if="item.img "
              :src="item.img"
              :height="block.config.image_height || 200"
              vertical-position="50"
              horizontal-position="50"
              :image-style="`border-top-left-radius: ${block.config.block_radius}px !important; border-top-right-radius: ${block.config.block_radius}px !important; height : ${block.config.image_height || 200}px  `"
              :contain="item.img_contain"
              auto-size
              :trigger-resize="imageResizeTrigger"
          />
        </v-sheet>
        </router-link>
        <router-link
            :is="routerLinkTag"
            :to="businessPublicLink( defaultSite ? `course/${item.uuid}` : (item.route ||  `course/${item.uuid}`)  )"
            class="noUnderline"
        >
        <div  style="padding: 24px; padding-bottom: 0"  >
          <h4 v-if="config.display_category && item.category_name"
            :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-courses' , 'category')" class="mt-2">
          {{ item.category_name || $t('NoCategory') }}
        </h4>
          <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-courses' , 'title')" :class="config.display_category && item.category_name ? 'mt-4' : ''" >
          {{ item.name }}
        </h3>
          <h4 v-if="config.display_description"
            :style="BLOCK_TEXT_CSS(block , block.config.style , 'tiles-courses' , 'description')" class="mt-4">
          {{ item.short_description }}
        </h4>
          <h4 v-if="item.discount && item.payed"
            :style="BLOCK_TEXT_CSS(block, block.config.style , 'tiles-courses' , 'discount')" class="mt-4">
            {{ item.discount }} % {{ $t('Discount').toLowerCase() }},
          <span style="text-decoration: line-through">
               <template v-if="item.has_payment_plans">{{ $t('priceFrom') }} </template>
          {{  item.has_payment_plans ? item.min_price : item.price  }}
          {{  BUSINESS_CURRENCY  }}
          </span>

        </h4>
        </div>
        </router-link>

        <v-sheet color="transparent" class="flex-grow-1"></v-sheet>

        <div :class="!(SM || MOBILE_VIEW) ? 'd-flex' : ''" >
          <v-hover #default="{hover}">
            <v-sheet
                @click.stop="openCourseDialog"
                :color="BLOCK_COLOR_PARAM(block , 'tiles-courses_button_bg' , block.config.style ) + (hover && !!item.route ? '66' : '')"
                :class="(SM || MOBILE_VIEW) ? 'justify-center' : ''"
                :style="`margin-bottom : ${24 + (block.config.block_radius/2)}px`"
                class="mt-4 d-flex mt-1 mx-6 px-4 py-3 text-no-wrap pointer"
                v-ripple
                width="100%"
            >
              <h5  :style="BLOCK_TEXT_CSS(block, block.config.style , 'tiles-courses' , 'button')" class="d-flex align-center">

                <template v-if="item.payed || coursePrice !== 0 ">
                  <v-icon :color="BLOCK_COLOR_PARAM(block , 'tiles-courses_button' , block.config.style )" class="mr-2">mdi-basket</v-icon>
                  {{ $t('Purchase') }}
                  (<template v-if="item.has_payment_plans">{{ $t('priceFrom') }} </template>
                  {{  coursePrice }}
                  {{  BUSINESS_CURRENCY  }})
                </template>
                <span class="px-2" v-else>
                  {{ $t('Register') }}
                </span>

              </h5>

            </v-sheet>
          </v-hover>
        </div>




    </v-sheet>

  </v-hover>
</template>

<script>
export default {
  name: "blockTilesDesign1",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    config  : {
      type : Object,
      default() { return {} }
    },
    block  : {
      type : Object,
      default() { return { config : {}} }
    },
    cols : {
      type : Number
    },
    preview : {
      type : Boolean,
      default : false
    },
    defaultSite : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      imageResizeTrigger : 0
    }
  },
  computed : {
    coursePrice() {
      return (this.item.has_payment_plans ? this.item.min_price : this.item.price)
          - Math.round((this.item.discount || 0)/100*(this.item.has_payment_plans ? this.item.min_price : this.item.price))
    },
    routerLinkTag() {
      if ( this.preview ) {
        return 'span'
      }
      if ((this.$store.state.avalon.editorMode)) {
        return 'span'
      }
      return 'router-link'
    },
  },
  watch : {
    cols() {
      this.imageResizeTrigger++
    },
  },
  methods : {
    openCourseDialog( ) {
      this.$emit('open-course-dialog' , this.item )
    }
  }
}
</script>

<style scoped>

</style>