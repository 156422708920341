<template>
 <div>

   <v-hover
       #default="{hover}"
       v-for="(fontPair, index) in FONT_PAIRS_SELECT"  :key="'palette_' + index "
   >
     <div class="d-flex align-center">
       <v-sheet
           width="100%"
           @click="changeFonts(fontPair , index)"
           class="mt-1 mb-1 pointer py-3 px-2"
           style="border-radius: 6px"
           color="transparent"
           :style="`box-shadow : 0 0 0 ${hover || index === selectedIndex ? 2 : 1}px ${wsDARKLIGHT} !important`"
       >
         <div class="d-flex align-center">
           <ws-check-box
               @prevent="changeFonts(fontPair , index)"
               :value="selectedIndex === index"
               :color="wsACCENT"
               :selected-color="wsACCENT"
               :small="false"
               class="mr-1"
               prevent-select
               radio
           />
           <div>
             <font :font="fontPair.heading" size="24" >
               <v-progress-linear class="wsRounded"  height="24" :color="wsBACKGROUND" indeterminate />
             </font>
             <font :font="fontPair.paragraph" regular >
               <v-progress-linear class="wsRounded"  height="16" :color="wsLIGHTCARD" indeterminate />
             </font>
           </div>
         </div>
       </v-sheet>
     </div>
   </v-hover>

 </div>
</template>

<script>
import font from "@/components/AvalonEditor/UI/sidebar/fonts/UI/font";
export default {
  name: "newSiteSidebarHomepage",
  components : {
    font
  },
  props : {
    items : {
      type : Array,
      default() { return []}
    },
  },
  data() {
    return {
      selectedIndex : 0
    }
  },
  methods :{
    changeFonts(fontPair, index) {

      this.selectedIndex = index

      this.$store.state.avalon.config.fonts.headings.family = fontPair.heading
      this.$store.state.avalon.config.fonts.paragraphs.family = fontPair.paragraph
      this.$store.state.avalon.config.fonts.families = [
        fontPair.heading , fontPair.paragraph
      ]
      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)

    }
  }
}
</script>

<style scoped>

</style>