<template>
  <div>
    <!-- Tiles Content-->
    <block-tiles
        v-if="!loading"
        :block="block"
        :items="items"
    >
      <template #item="{item}">
        <pricing-tile
            :item="item"
            :config="block.config"
            :block="block"
            :course="course"
        />
      </template>
    </block-tiles>

    <!-- DIALOGS-->


  </div>
</template>

<script>
import blockTiles from "@/components/AvalonEditor/Editor/blocks/tiles/blockTiles";
import pricingTile from "@/components/AvalonEditor/Editor/blocks/tiles/coursePricing/pricingTile";

import {mapActions} from "vuex";
export default {
  name: "blockCoursePricing",
  components : {
    blockTiles,
    pricingTile
  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      loading : true,
      courseData : {},
      previewItems : [
        { value : "plan_1",
          text : "Standard",
          "description": "Course introduces eco-friendly design program, equipping students with skills",
          "img": "https://westudy.ua/library/img/avalon/icon_set/tablet_coffee.svg",
          "price": 100
        },
        { value : "plan_2",
          text : "Elite",
          "description": "Course introduces eco-friendly design program, equipping students with skills",
          "img": "https://westudy.ua/library/img/avalon/icon_set/click.svg",
          "price": 150
        },
        { value : "plan_3",
          text : "Elite+",
          "description": "Course introduces eco-friendly design program, equipping students with skills",
          "img": "https://westudy.ua/library/img/avalon/icon_set/prise.svg",
          "price": 400
        },
        { value : "plan_4",
          text : "VIP",
          "description": "Course introduces eco-friendly design program, equipping students with skills",
          "img": "https://westudy.ua/library/img/avalon/icon_set/gift.svg",
          "price": 700
        },
      ]
    }
  },
  computed : {
    configCourseId() {
      return this.block.config.course || null
    },
    items() {
      let items = []
      if ( this.preview || !this.course.has_payment_plans || (this.course.payment_plans && this.course.payment_plans.length === 0)  ) {
        let items = this.COPY(this.previewItems)
        return items.splice(0, this.block.config.columns_large  )
      }
      else if ( this.course && this.course.has_payment_plans && this.course.payment_plans ) {
        return this.course.payment_plans
      }
      return items
    },
    course() {
      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null
      if (!this.block.config.course && this.$store.state.avalonPage.course ) {
        return this.$store.state.avalonPage.course
      }
      if ( checkId === this.block.config.course ) {
        return this.$store.state.avalonPage.course
      }
      return this.courseData
    },
    blockPricingUpdateTrigger() {
      return this.$store.state.avalon.blockPricingUpdateTrigger
    }
  },
  watch : {
    blockPricingUpdateTrigger() {
      this.handleCourse()
    },
    configCourseId() {
      this.handleCourse()
    }
  },
  methods : {
    ...mapActions('courses', ['GET_COURSE_PUBLIC']),

    async handleCourse() {

      if ( this.preview ) {
        this.loading = false
        return
      }
      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null
      if ( this.block.config.course !== checkId ) {
        let data = {
          uuid : this.block.config.course,
          lang : this.$store.state.avalon.selectedLang
        }
        let result = await this.GET_COURSE_PUBLIC(data)
        if ( !result ) {
          this.loading = false
          return
        }
        this.courseData = result
        this.loading = false
      } else {
        this.loading = false
      }
    }
  },
  mounted() {
    this.handleCourse()
  }
}
</script>

<style scoped>

</style>