<template>
  <div class="px-3">

    <!-- Header Title  -->
    <h5 v-if="block.config.calendar_title"
        class="mb-8"
        :style="getTextCss('header-title')"
    >
      {{ block.config.calendar_title }}
    </h5>

    <!--  Current Month And Navigation  -->

    <!-- Navigation  -->
    <div class="justify-space-between flex-row align-center align-center mb-4"
         :class="[{'d-flex ' : !(SM || MOBILE_VIEW)}]"
    >

      <div class="flex-grow-1">
        <!-- Categories-->
        <tiles-categories
            v-if="block.config.display_categories && !block.config.category"
            style="width: 100%"
            @input="selectCategory"
            :value="selectedCategory"
            :items="allCategoriesSelect"
            :block="block"
        />

        <!-- Sub Categories-->
        <v-expand-transition>
          <tiles-categories
              v-if="subcategoriesSelect.length > 0 && block.config.display_categories "
              @input="initPage"
              v-model="selectedSubcategory"
              :items="subcategoriesSelect"
              :block="block"
          />
        </v-expand-transition>
      </div>


      <!-- Navigation  -->
      <div class="d-flex align-center" >
        <!-- Previous Month Arrow  -->
        <avalon-icon-button
            @click="previousMonth"
            :block="block"
            icon="mdi-arrow-left"
        />
        <!-- Current Month  -->
        <h5 :style="getTextCss('header-month')" class="mx-5">
          {{ selectedMonthText }}, {{ selectedYear }}
        </h5>

        <!-- Next Month Arrow  -->
        <avalon-icon-button
            @click="nextMonth"
            :block="block"
            icon="mdi-arrow-right"
        />
      </div>

    </div>
    <!-- Days Names  -->
    <v-row no-gutters>
      <v-col :cols="!(SM || MOBILE_VIEW) ? 8 : 12" class="pr-2">
        <!-- Days Names  -->
        <table :key="selectedMonth + 'month'"
               style="width: 100%; "
               cellspacing="0" cellpadding="0"
        >
          <tr v-if="!(SM || MOBILE_VIEW)">
            <td  v-for="day in 7" :key="day"
                 width="14.2%"
                 class="py-2"
            >
              <h5  :style="getTextCss('header-days')">
                {{ $t(`Day_short_${day}`) }}
              </h5>
            </td>
          </tr>
        </table>

      </v-col>
    </v-row>

    <!-- Calendar and Sidebar  -->
    <div style="position: relative" class="positionRelative" >

      <!-- Calendar  -->
      <v-sheet color="transparent" :width="!SMALL ? '66%' : null" >
        <table :key="selectedMonth + 'month'" style="width: 100%; " cellspacing="0" cellpadding="0">
          <tr v-for="row in rowsAmount" :key="row + 'days'">
            <td  v-for="day in 7" :key="day + '-'+row"
                 :style="getTableBorderStyle(row,day)"
                 :width="`${100/7}%`"
            >
              <v-hover #default="{hover}">

                <div
                    v-if="getDay(day,row)"
                    @click="selectedDate = getDay(day,row)"
                    style="position: relative"
                    :style="`background-color : ${hover ? '#00000005' : 'transparent' };`  + ( !(SM || MOBILE_VIEW) ? 'height: 75px' : '')"
                    class="pointer"
                >
                  <div class="fill-height pa-2" style="position: relative"
                  >
                    <!-- Day  -->
                    <v-sheet :height="`${CONFIG_PARAM('fonts', 'paragraphs' ,'size' , 'p')*1.7}px`" :width="`${CONFIG_PARAM('fonts', 'paragraphs' ,'size' , 'p')*1.7}px`" style="border-radius: 50%;"
                             class="d-flex align-center justify-center"
                             :style="getDayCircleStyle(day,row)"
                    >
                      <h3 :style="dayTextStyle(getDay(day,row))">
                        {{ getDay(day,row) }}
                      </h3>
                    </v-sheet>

                    <!-- Event  -->
                    <div v-if="!SMALL && getDayItems(getDay(day,row)).length > 0" class="mt-2">
                      <v-sheet
                          :color="getColor('calendar_day-event') + '22'"
                          class="pa-1 mb-1 d-flex align-center "
                          style="border-radius: 2px"
                          width="100%"
                      >
                        <h6 class="text-regular shorten-text "
                            style="word-break: break-all"
                            :style="`color : ${getColor('calendar_day-event')}`">
                          {{ $t('CalendarEvent') }} :
                        </h6>
                        <h6 v-if="getDayItems(getDay(day,row)).length > 0"
                            :style="`color : ${getColor('calendar_day-event')}`"
                        >
                          {{ getDayItems(getDay(day,row)).length }}
                        </h6>
                      </v-sheet>
                    </div>

                  </div>
                </div>

              </v-hover>
            </td>
          </tr>
        </table>
      </v-sheet>

      <!-- Sidebar  -->
      <v-sheet
          :class="!SMALL ? 'pl-4 positionAbsolute' : ''"
          :width="!SMALL ? '34%' : null"
          class="flex-column d-flex sidebarStyle "
          color="transparent"
      >
        <v-sheet
            class="flex-grow-1 overflow-y-auto "
            :color="getColor('calendar_sidebar-bg') || 'transparent'"
        >
          <div style="height: 100%" class="overflow-auto pa-6 px-8" >

            <!-- No Events In Month   -->
            <div v-if="!events.length" class="fill-height d-flex align-center justify-center">
              <div>
                <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'calendar' , 'sidebar-title')" >
                  {{ $t('NoEventsInCurrentMonth') }}
                </h3>
              </div>

            </div>

            <!-- Events  -->

            <template v-else>
              <div v-for="(item,index) in eventsFiltered" :key="index">
                <ws-link
                    :to="businessPublicLink(`news/${item.alias}`)"
                    :disabled="$store.state.avalon.editorMode || $store.state.avalon.sideMenu || preview"
                >
                  <h4 :style="BLOCK_TEXT_CSS(block , block.config.style , 'calendar' , 'sidebar-date')" class="mt-5 shorten-text">
                    {{ MONTH_DAY_TIME(item.date,false) }}
                    <span v-if="item.category_name">• {{ item.category_name }}</span>
                  </h4>
                  <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'calendar' , 'sidebar-title')" >{{ item.text }}</h3>
                  <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'calendar' , 'sidebar-text')" >{{ item.description }}</h3>

                  <h3 class="mt-2" :style="BLOCK_TEXT_CSS(block , block.config.style , 'calendar' , 'sidebar-date')" >
                    {{ $t('MoreDetails') }}
                    <v-icon>mdi-arrow-right</v-icon>
                  </h3>

                </ws-link>
              </div>
            </template>

          </div>
        </v-sheet>

      </v-sheet>


    </div>


    <!-- All Items  -->

    <div class="d-flex justify-center mt-6">
      <ws-link
          v-if="block.config.all_items_page"
          :to="businessPublicLink(`page/${block.config.all_items_page}`)"
          :disabled="$store.state.avalon.editorMode || $store.state.avalon.sideMenu || preview"
      >
        <avalon-button
            :block="block"
            :block-style="block.config.style"
            :text="block.config.all_items_page_text || $t('All')"
            :disabled="LOADING"
            class="mt-10"
            padding-horizontal="48"
            :radius="buttonRadius"
        />
      </ws-link>
    </div>


  </div>
</template>

<script>
import {mapActions} from "vuex";
import tilesCategories from "@/components/AvalonEditor/Editor/blocks/tiles/UI/tilesCategories.vue";

export default {
  name: "BlockCalendar",
  components: {tilesCategories},
  props : {
    preview : {
      type : Boolean,
      default : false
    },
    view : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    }
  },
  data() {
    return {
      selectedYear : null,
      selectedMonth : null,
      selectedDate : null,
      currentDate : null,
      currentMonth : null,
      events : [],
      // categories
      categoriesSelect : [],
      selectedCategory : null,
      selectedSubcategory : null,
    }
  },
  computed : {
    buttonRadius() {
      let radius = 0
      if (!this.block.config.button_radius && this.block.config.button_radius!== 0) {
        radius = 100
      } else {
        radius = this.block.config.button_radius
      }
      return radius
    },
    allCategoriesSelect() {
      if ( this.preview ) {
        items = [
          { text : "All courses" , value : "all" },
          { text : "Design" , value : "" },
          { text : "Copyright" , value : "" },
          { text : "Programming" , value : "" },
          { text : "Testing" , value : "" }
        ]
        return items
      }
      let items = this.COPY(this.categoriesSelect)
      items = items.filter(el => !el.parent )
      items.unshift({ text : this.$t('All') , value : null })
      return items
    },
    subcategoriesSelect() {
      if ( !this.selectedCategory || !this.categoriesSelect ) {
        return []
      }

      let items = this.COPY(this.categoriesSelect).filter(el => el.parent === this.selectedCategory)
      if ( items.length > 1 ) {
        items.unshift({ text: this.$t('All'), value: this.selectedCategory })
      }

      return items

    },
    eventsFiltered() {
      if ( this.itemsToDisplay.length > 0 ) {
        return this.itemsToDisplay
      }
      if ( this.nearestEvents.length) {
        return this.nearestEvents
      }

      return []
    },
    itemsToDisplay() {
      if ( !this.selectedDate ) {
        return []
      }
      let items = this.getDayItems(this.selectedDate)
      return items
    },
    selectedMonthText() {
      if (!this.selectedMonth) {
        return ''
      }
      const month = this.monthSelect.find(el => el.value === this.selectedMonth)
      return month ? month.text : '';
    },
    rowsAmount() {
      let rows = 5
      if ( this.firstDayOfMonth > 4 && this.daysInMonth > 30 ) {
        rows = 6
      }

      return rows
    },
    monthSelect() {
      let items = [
        { value : 1 , text : this.$t('Jan') },
        { value : 2 , text : this.$t('Feb') },
        { value : 3 , text : this.$t('Mar') },
        { value : 4 , text : this.$t('Apr') },
        { value : 5 , text : this.$t('May') },
        { value : 6 , text : this.$t('Jun') },
        { value : 7 , text : this.$t('Jul') },
        { value : 8 , text : this.$t('Aug') },
        { value : 9 , text : this.$t('Sep') },
        { value : 10 , text : this.$t('Oct') },
        { value : 11 , text : this.$t('Nov') },
        { value : 12 , text : this.$t('Dec') }
      ]


      return items
    },
    daysInMonth() {
      if (this.selectedMonth === null || this.selectedYear === null) return 0;

      // Using Date object to get the number of days in the selected month
      return new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    },
    firstDayOfMonth() {
      if (this.selectedMonth === null || this.selectedYear === null) return null;

      // Using Date object to get the day of the week for the first day of the selected month
      return (new Date(this.selectedYear, this.selectedMonth - 1, 1).getDay() + 6) % 7;
    },

    nearestEvents() {
      let events = this.COPY(this.events)
      const sortedEvents = events.sort((a, b) => a.day - b.day)
      const nearestEvent = sortedEvents.filter(el => el.day >= this.currentDate);

      if ( !nearestEvent.length) {
        if (!sortedEvents.length) {
          return []
        }
        return sortedEvents.slice(-3);
      }
      return nearestEvent.slice(0, 3);
    },


  },
  methods : {
    ...mapActions('avalonPage' , ['GET_BLOCK_CALENDAR' , 'GET_DYNAMIC_BLOCK_CATEGORIES' ]),

    selectCategory(value) {
      let children = []

      if (value) {
        children = this.categoriesSelect.filter(el => el.parent === value)
      }

      if (children && children.length > 0  ) {
        this.selectedSubcategory = children[0].value
      } else {
        this.selectedSubcategory = value
      }

      this.selectedCategory = value
      this.initPage()
    },

    dayTextStyle(date) {
      let style = ``

      style += `font-size: 12px !important;`
      style += `font-family: "${this.CONFIG_PARAM('fonts', 'paragraphs' ,'family')}" !important  ;`
      style += `font-weight: 500 !important;`

      style += `color : ${this.getColor(this.selectedDate === date ? 'calendar_day-active' : 'calendar_day')  }`

      return style
    },

    previousMonth() {
      if ( this.selectedMonth > 1 ) {
        this.selectedMonth--
      } else {
        this.selectedMonth = 12
        this.selectedYear--
      }
      this.initPage()
    },
    nextMonth() {
      if ( this.selectedMonth < 12 ) {
        this.selectedMonth++
      } else {
        this.selectedMonth = 1
        this.selectedYear++
      }

      this.initPage()
    },

    // Technical
    getDayCircleStyle(day,row) {
      let style = ''
      const date = this.getDay(day,row)
      const color = this.getColor('calendar_day-active-bg')

      if (this.currentDate === date && this.selectedMonth === this.currentMonth) {
        style += `border: 2px solid ${color};`
      }
      if ( this.selectedDate === date ) {
        style += `background: ${color};`
      }
      else if ( this.getDayItems(this.getDay(day,row)).length > 0) {
        style += `background-color: ${color}22;`
      }
       else {
        style += `background: transparent;`
      }

      return style

    },
    getTableBorderStyle(row, day) {
      const color = this.getColor('calendar_border')
      let style = ``
      style += `border-top : 1px dashed ${color};`
      style += `border-left : 1px dashed ${color};`

      if ( day === 7) {
        style += `border-right : 1px dashed ${color};`
      }
      if ( row === this.rowsAmount) {
        style += `border-bottom : 1px dashed ${color};`
      }

      return style
    },
    getDayItems(day) {
      if ( !day) {
        return []
      }
      return this.events.filter(el =>
          parseInt(el.year) === parseInt(this.selectedYear)
          && parseInt(el.month) === parseInt(this.selectedMonth)
          && parseInt(el.day) === parseInt(day)
      )
    },
    getDay(day,row) {
      const resultDay = day + (row-1)*7 - this.firstDayOfMonth
      if ( resultDay < 1 || resultDay > this.daysInMonth) {
        return null
      }
      return resultDay
    },
    getCurrentDate() {
      const currentDate = new Date();
      this.selectedYear = currentDate.getFullYear();
      this.selectedMonth = currentDate.getMonth() + 1;
      this.currentDate = currentDate.getDate();
      this.currentMonth = currentDate.getMonth() + 1;
    },

    // Avalon Shortcuts
    getColor(variable) {
      return this.BLOCK_COLOR_PARAM(this.block ,  variable, this.block.config.style)
    },
    getTextCss(variable) {
      return this.BLOCK_TEXT_CSS(this.block , this.block.config.style , 'calendar' , variable)
    },


    async getCategories() {
      let result = await this.GET_DYNAMIC_BLOCK_CATEGORIES({ source : 'news' , lang : this.$store.state.avalon.editorMode ? this.selectedLang : null })
      if ( result && result !== true ) {
        this.categoriesSelect = result
      }

    },
    // Init
    async initPage() {
      let data = {
        block_id: this.block.id,
        month : this.selectedMonth,
        year : this.selectedYear,
        view : this.view,
        category_id : this.selectedSubcategory || this.selectedCategory || null
      }
      this.getCategories()
      let result = await this.GET_BLOCK_CALENDAR(data)
      if ( !result) {
        return this.ERROR()
      }
      this.events = result.events
    }
  },
  mounted() {
    if ( this.block.config.category) {
      this.selectedCategory = this.block.config.category
      this.selectedSubcategory = this.selectedCategory
    }
    this.getCurrentDate()
    this.initPage()
  }
}
</script>

<style scoped>
.sidebarStyle {
  top : 0;
  bottom: 0;
  right: 0;
  position: relative
}
</style>