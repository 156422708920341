<template>
  <v-sheet color="white" class="pt-6"
           :class="[{'pb-6' : !dialog}]"
           :width="contentWidth"
           light
  >

    <v-fade-transition mode="out-in">

      <verify-window
          v-if="displayVerify"
      />

      <proceed-as-window
          v-if="displayProceedAs"
          @skip="skipProceed = true"
          @verify="displayVerify = true"
          :no-auth-action="noAuthAction"
      />

      <register-window
          v-else-if="display === 'register'"
          @login="openLogin"
          :hide-login="hideLogin"
          :no-auth-action="noAuthAction"
          :is-new-business="isNewBusiness"
          @success="$emit('success')"
          key="register"
          :dialog="dialog"
          :element-uuid="elementUuid"
      />
      <login-window
          v-else-if="display === 'login'"
          @register="openRegister"
          @restore="openRestorePassword"
          :auth-route="authRoute"
          :hide-register="hideRegister"
          :no-auth-action="noAuthAction"
          @success="$emit('success')"
          key="login"
          :dialog="dialog"
          :element-uuid="elementUuid"
      />
      <restore-password-window
          v-else-if="display === 'restore_password'"
          @cancel="display = 'login'"
          key="restore_password"
      />
    </v-fade-transition>

    <ws-button
        @click="skipProceed = false"
        v-if="proceedUser.email && skipProceed"
        :color="wsACCENT"
        left-icon="mdi-chevron-left"
        label="Back"
        class="mt-6"
        outlined
        block
    />

  </v-sheet>
</template>

<script>
import registerWindow from "@/components/auth/UI/registerWindow";
import loginWindow from "@/components/auth/UI/loginWindow";
import restorePasswordWindow from "@/components/auth/UI/restorePasswordWindow";
import proceedAsWindow from "@/components/auth/UI/proceedAsWindow";
import verifyWindow from "@/components/auth/UI/verifyWindow";

import {mapActions, mapState} from "vuex";
export default {
  name: "authWindowNew",
  components : {
    registerWindow,
    loginWindow,
    restorePasswordWindow,
    proceedAsWindow,
    verifyWindow
  },
  props : {
    width : {
      type : String
    },
    displayRegister: {
      type : Boolean,
      default : true
    },
    displayLogin: {
      type : Boolean,
      default : false
    },
    displayRestorePassword: {
      type : Boolean,
      default : false
    },
    hideLogin: {
      type : Boolean,
      default : false
    },
    hideRegister: {
      type : Boolean,
      default : false
    },
    authRoute : {
      type : String
    },
    noAuthAction : {
      type : Boolean,
      default : false
    },
    isNewBusiness : {
      type : Boolean,
      default : false
    },
    dialog : {
      type : Boolean,
      default : false
    },
    elementUuid : {
      type : String
    }
  },
  data() {
    return {
      displayVerify : false,
      entityData : {},
      display : 'register',
      skipProceed : false,
    }
  },
  computed : {
    ...mapState('auth' , [ 'proceedUser' , 'proceedAuthSuccessTrigger']),

    displayProceedAs() {
      return this.proceedUser.email && !this.skipProceed
    },
    contentWidth() {
      if ( this.dialog ) {
        return '100%'
      }
      return this.width || (this.SM ? 300 : 350)
    },
    socialsReturnPath() {
      if ( this.authRoute ) {
        return  this.authRoute.substring(1)
      }
      return this.$route.path.substring(1)
    }
  },
  watch : {
    proceedAuthSuccessTrigger() {
      this.$emit('success')
    },
    displayProceedAs(value) {
      if (value) {
        this.$emit('navigation' , 'proceed')
      } else {
        this.$emit('navigation' , this.display)
      }
    },
    display() {
      this.$emit('navigation' , this.display)
    }
  },
  methods : {
    ...mapActions('auth' , ['GET_SOCIALS_AUTH_URL']),

    openLogin() {
      this.display = 'login'
    },
    openRegister() {
      this.display = 'register'
    },
    openRestorePassword() {
      this.display = 'restore_password'
    }
  },
  beforeMount() {

    let data = {
      path : this.localeLink(this.socialsReturnPath,true , true),
      is_businessman : this.isNewBusiness
    }
    if ( this.$store.state.referral.businessReferral ) {
      data.referral = this.$store.state.referral.businessReferral
    }

    this.GET_SOCIALS_AUTH_URL(data)
    if (this.displayRegister || this.hideLogin) {
      this.dipslay = 'register'
    }
    if (this.displayLogin || this.hideRegister) {
      this.openLogin()
    }
    if (this.displayRestorePassword ) {
      this.display = 'restore_password'
    }

    this.$nextTick(()=> {
      if ( this.displayProceedAs ) {
        this.$emit('navigation' , 'proceed')
      }
    })

  }
}
</script>

<style scoped>

</style>