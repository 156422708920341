<template>
<div>

  <div  class="d-flex justify-space-between align-center py-4  px-n4">
    <img height="32px" class="ml-n4" alt="WeStudy" src="@/assets/img/avalon/westudy_constructor.png" />
    <h5 @click="$emit('skip')"
        :style="`color : ${wsACCENT}`"
        class="d-flex align-center pointer"
    >
      {{ $t('Skip') }}
      <v-icon class="ml-2" :color="wsACCENT">mdi-close</v-icon>
    </h5>
  </div>

  <h2 :style="`color : ${wsDARKER}`" class="text-center mt-8 px-4">
    {{ $t('avalon.new.title') }}
  </h2>
  <h3 :style="`color : ${wsDARKER}`" class="text-center font-weight-regular  px-4">
    {{ $t('avalon.new.description') }}
  </h3>


  <div class="pa-3 d-flex align-center justify-center mt-10 pb-10">

    <v-hover v-if="false"  #default="{hover}">
      <v-sheet
          @click="$emit('input' , true)"
          :style="hover ? `outline : 2px solid ${wsDARKLIGHT}` : null"
          class="px-8 mr-4 py-16 wsRoundedHalf pointer" width="450">
        <div class="d-flex justify-center">
          <img width="200" height="auto"  src="@/assets/img/avalon/wizard_from_template.png" >
        </div>

        <h3 :style="`color : ${wsDARKER}`" class="text-center mt-6 px-6">{{  $t('avalon.new.from_template.title')  }}</h3>
        <h4 :style="`color : ${wsACCENT}`" class="text-center font-weight-regular  mt-2">{{  $t('avalon.new.from_template.description')  }}</h4>
      </v-sheet>
    </v-hover>

    <v-hover  #default="{hover}">
      <v-sheet
          @click="$emit('input' , false)"
          :style="hover ? `outline : 2px solid ${wsDARKLIGHT}` : null"
          class="px-8 py-16 wsRoundedHalf pointer" width="450">
        <div class="d-flex justify-center">
          <img width="200" height="auto"  src="@/assets/img/avalon/wizard_from_blocks.png" >
        </div>

        <h3 :style="`color : ${wsDARKER}`" class="text-center mt-6 px-6">{{  $t('avalon.new.from_wizard.title')  }}</h3>
        <h4 :style="`color : ${wsACCENT}`" class="text-center font-weight-regular px-1 mt-2">{{  $t('avalon.new.from_wizard.description')  }}</h4>
      </v-sheet>
    </v-hover>


  </div>
</div>
</template>

<script>
export default {
  name: "newSiteWelcome"
}
</script>

<style scoped>

</style>