<template>
  <div v-if="block">
    <view-block
        :block="block"
        :elements="elements"
        :key="'footer' + trigger"
    />
  </div>

</template>

<script>
import viewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";
import {mapActions, mapState} from "vuex";
export default {
  name: "avalonFooterEditor",
  components : {
    viewBlock
  },
  props : {
    triggerUpdate : {
      type : Number,
      default : 0
    }
  },
  data() {
    return {
      block : null,
      elements : [],
      trigger : 0,
    }
  },
  watch : {
    LANG() {
      this.initPage()
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang'])
  },
  methods : {
    ...mapActions('avalon',['GET_FOOTER_PUBLIC']),
    async initPage() {
      let result = await this.GET_FOOTER_PUBLIC()
      if ( !result ) {
        this.block = null
        this.elements = []
        return
      }
      this.block = result.block
      this.elements = result.elements
      this.trigger++
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>