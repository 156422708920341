<template>
  <div v-intersect="intersector" style="width: 100%">
   <v-fade-transition mode="out-in">
     <h3 v-if="fontReady"
         :style="fontStyle"
         key="font"
         class="cut-text"
     >
       {{ font }}
     </h3>
     <span v-else key="loader">
       <slot>
        <h3 > loading ... </h3>
       </slot>
     </span>

   </v-fade-transition>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "font",
  props : {
    font : {
      type: String
    },
    cssStyle : {
      type : String
    },
    color : {
      type : String
    },
    size : {
      type : String
    },
    regular : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      fontReady : false,
      intersected : false,
    }
  },
  computed : {
    ...mapState('avalon' , ['isLoadingFont']),
    fontClass() {
      return this.font.replace(/\s+/g, '').replace(/^/, 'wf-').replace(/$/, '-n4-active').toLowerCase();
    },
    fontStyle() {
      let style = `font-family : ${this.font};`
      style += `color : ${this.color || this.wsDARKER};`

      style += `font-size : ${this.size || 16}px;`
      if ( this.regular ) {
        style += `font-weight : 500;`
      }

      return style
    }
  },
  methods : {
    isFontAlreadyLoaded() {
      return document.getElementsByTagName('html')[0].classList.contains(this.fontClass)
    },
    checkReadyStatus() {
      if ( this.isFontAlreadyLoaded() ) {
        this.fontReady = true
      } else {
        this.fontReady = false
        setTimeout(this.checkReadyStatus , 300 )
      }
    },
    intersector(entries) {

      if ( !Array.isArray(entries) ) {
        return
      }
      if ( entries.length === 0 ) {
        return
      }
      let entry = entries[0]

      if ( entry.isIntersecting ) {

        if ( this.isFontAlreadyLoaded() ) {
          this.fontReady = true
        } else {
          this.fontReady = false
          this.LOAD_GOOGLE_FONT(this.font)
        }

      }
    }
  },
  beforeMount() {
    this.checkReadyStatus()
  }
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>