var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.items),function(palette,index){return _c('v-hover',{key:'palette_' + index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex align-center"},[_c('ws-check-box',{attrs:{"value":_vm.selectedIndex === index,"color":_vm.wsACCENT,"selected-color":_vm.wsACCENT,"prevent-select":"","radio":""},on:{"prevent":function($event){return _vm.changePalette(palette , index)}}}),_c('v-sheet',{staticClass:"mt-3 mb-3 pointer",staticStyle:{"border-radius":"6px"},style:(("box-shadow : 0 0 0 " + (hover ? 2 : 1) + "px " + _vm.wsDARKLIGHT + " !important")),attrs:{"width":"100%","color":"transparent"},on:{"click":function($event){return _vm.changePalette(palette , index)}}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.AVALON_COLORS_LIST),function(color,index){return _c('v-sheet',{key:color,class:[
              {roundedTopLeft : index === 0} ,
              {roundedBottomLeft : index === 0} ,
              {roundedTopRight : index === 5},
              {roundedBottomRight : index === 5}
          ],attrs:{"color":palette[color],"width":"100%","height":"32"}})}),1)])],1)]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }