<template>
  <div class="d-flex">
    <v-btn href="https://www.facebook.com/westudy.ua" target="_blank" :large="!large" :x-large="large" icon :class="[{'mr-2' : large}]">
      <v-icon :large="!large" :size="large ? 48 : null"  :color="color || wsDARKLIGHT">mdi-facebook</v-icon>
    </v-btn>
    <v-btn href="https://t.me/westudy_ua/" target="_blank" :large="!large" :x-large="large" icon :class="[{'mr-2' : large}]">
      <v-sheet :height="large ? '40' : '30'" :width="large ? '40' : '30'" class="d-flex align-center justify-center" style="border-radius: 50%; padding-left: 1px" dark :color="color || wsDARKLIGHT">
        <v-icon :size="large ? 26 : 22"  style="transform: rotate(-30deg); padding-bottom: 3px; padding-left: 4px " >mdi-send</v-icon>
      </v-sheet>
    </v-btn>
    <v-btn href="https://www.instagram.com/westudy_ua/" target="_blank" :large="!large" :x-large="large" icon :class="[{'mr-2' : large}]">
        <v-sheet :height="large ? '40' : '30'" :width="large ? '40' : '30'" class="d-flex align-center justify-center" style="border-radius: 50%; padding-left: 1px" dark :color="color || wsDARKLIGHT">
          <v-icon :size="large ? '30' : '22'"  >mdi-instagram</v-icon>
        </v-sheet>
    </v-btn>
    <v-btn href="https://www.youtube.com/@westudy_ua" target="_blank" :large="!large" :x-large="large" icon>
      <v-icon  :large="!large" :size="large ? 48 : null"   :color="color || wsDARKLIGHT">mdi-youtube</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "homeSocials",
  props : {
    color : {
      type : String
    },
    large : {
      type :Boolean,
      default : false
    }
  }
}
</script>

<style scoped>

</style>