<template>
  <div>

    <v-hover
        #default="{hover}"
        v-for="(palette, index) in items"  :key="'palette_' + index "
    >
      <div class="d-flex align-center">
        <ws-check-box
            @prevent="changePalette(palette , index)"
            :value="selectedIndex === index"
            :color="wsACCENT"
            :selected-color="wsACCENT"
            prevent-select
            radio
        />
        <v-sheet
            width="100%"
            @click="changePalette(palette , index)"
            class="mt-3 mb-3 pointer"
            style="border-radius: 6px"
            color="transparent"
            :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${wsDARKLIGHT} !important`"
        >

          <div class="d-flex">

            <v-sheet
                v-for="(color,index) in AVALON_COLORS_LIST" :key="color"
                :color="palette[color]"
                :class="[
                {roundedTopLeft : index === 0} ,
                {roundedBottomLeft : index === 0} ,
                {roundedTopRight : index === 5},
                {roundedBottomRight : index === 5}
            ]"
                width="100%"
                height="32"
            />

          </div>

        </v-sheet>
      </div>

    </v-hover>

  </div>
</template>

<script>
export default {
  name: "newSiteSidebarHomepage",
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
  },
  data() {
    return {
      selectedIndex : 0
    }
  },
  methods : {
    changePalette(palette, index) {
      this.selectedIndex = index
      Object.keys(palette).forEach(color => {
        this.$store.state.avalon.config.colors[color] = palette[color]
      })
      this.$store.state.avalon.config.styles = this.DEFAULT_CONFIG.styles
      this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)
    }
  }
}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 6px;
}
.roundedBottomLeft  {
  border-bottom-left-radius : 6px;
}
.roundedTopRight  {
  border-top-right-radius : 6px;
}
.roundedBottomRight  {
  border-bottom-right-radius : 6px;
}
</style>