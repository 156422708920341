import {mapActions} from "vuex";

export default {
    methods : {
        ...mapActions('referral' , [
            'CHECK_BUSINESS_REFERRAL'
        ]),
        ...mapActions('adminPartners' , [
            'CHECK_PARTNER_REFERRAL'
        ]),

        async checkBusinessReferral() {

            // PARSE BUSINESS REFFERAL
            if ( !this.$store.state.QUERY.ref  ) {
                return
            }
            let ref = this.$store.state.QUERY.ref
            if ( ref ) {
                let result = await this.CHECK_BUSINESS_REFERRAL(ref)
                if ( !result ) {
                    return
                }
                this.$store.state.referral.businessReferral = ref
            }

        },

        async CHECK_PARTNER_REFFERAL() {

            // PARSE BUSINESS REFFERAL

            if ( !this.$store.state.QUERY.pref && !localStorage.getItem('pref')  ) {
                return
            }
            let ref = this.$store.state.QUERY.pref
            if (!ref) {
                ref = localStorage.getItem('pref')
            }
            if ( ref ) {
                let result = await this.CHECK_PARTNER_REFERRAL(ref)
                if ( !result ) {
                    return
                }
                localStorage.setItem('pref', ref)
                this.$store.state.adminPartners.partnerReferral = ref
            }

        },


    }
}