<template>
  <div>
    <v-data-table v-if="!SM"
                  style="background-color: transparent"
                  hide-default-footer
                  disable-pagination
                  :items="items" >

      <template v-slot:item="{ item }">
        <tr style="background: transparent" class="pointer"  >
          <td class="px-0 pl-3 py-3" width="1">
            <router-link :to="businessPublicLink('course/' + item.uuid  )">
              <v-img style="border : 2px solid white" v-if="item.img" class="wsRoundedHalf" height="150" width="240"  :src="item.img" ></v-img>
              <v-img style="border : 2px solid white" v-else class="wsRoundedHalf" height="150" width="240"  src="@/assets/img/course_blank.jpg" ></v-img>
            </router-link>
          </td>
          <td valign="top" class="py-3">
            <router-link class="noUnderline  text--darken-3"
                         :class="'grey--text'"
                         :to="businessPublicLink('course/' + item.uuid  )">
              <h3>{{ item.name }}</h3>
              <h4 class="font-weight-light mt-3">
                {{ item.short_description }}
              </h4>
            </router-link>
          </td>
          <td valign="top" class="py-3" align="right"  width="150px">
            <div v-if="item.payed">
              <h4 >{{ item.price }} {{ BUSINESS_CURRENCY }}</h4>
            </div>
            <h4 v-else > </h4>

          </td>
        </tr>
      </template>


    </v-data-table>
    <v-data-iterator v-else
                     style="background-color: transparent"
                     hide-default-footer
                     disable-pagination
                     :items="items" >

      <template v-slot:default="{ items }">
        <v-sheet v-for="(item,i) in items" :key="i" class="px-3">
          <router-link :to="businessPublicLink('course/' + item.uuid  )">
            <v-img style="border : 2px solid white" v-if="item.img" class="wsRoundedHalf"  :src="item.img" ></v-img>
            <v-img style="border : 2px solid white" v-else class="wsRoundedHalf"  src="@/assets/img/course_blank.jpg" ></v-img>
          </router-link>

          <h4 class="mt-3 text-center"> {{ item.name }}</h4>
          <v-btn :to="businessPublicLink('course/' + item.uuid  )"
                 outlined block class="noCaps my-3 d-flex align-center">
            {{ $t('Details') }}
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>

        </v-sheet>
      </template>

    </v-data-iterator>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "blockCourses",
  props : {
    block : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      items : []
    }
  },
  methods : {
    ...mapActions('courses', ['GET_BUSINESS_COURSES']),
  },
  async mounted() {
    let result = await this.GET_BUSINESS_COURSES();
    if ( !result ) {
      return  }
    this.items = result !== true ? result.courses : [];
  }
}
</script>

<style scoped>

</style>