<template>
  <v-sheet color="transparent" class="d-flex justify-center px-9  pt-6" >
    <v-data-iterator style="width: 100%" :items="items"
                     hide-default-footer
                     disable-pagination
                     items-per-page="6">
      <template v-slot:default="{ items }">
        <v-row class="pa-0">
          <v-col v-for="(item,i) in items" :key="i"
                 cols="12" xs="12" sm="12" md="6" lg="4" xl="4"
                 class="">
            <router-link class="noUnderline"    :class="'black--text'" :to="businessPublicLink('news/' + item.alias)">
              <v-sheet  class="wsRoundedHalf  " >
                <v-img style="border : 2px solid white" v-if="item.img" class="wsRoundedHalf" aspect-ratio="1.5"  :src="item.img" ></v-img>
                <v-img style="border : 2px solid white" v-else class="wsRoundedHalf" aspect-ratio="1.5"  src="@/assets/img/course_blank.jpg" ></v-img>
              </v-sheet>
              <h5 class="text-center font-weight-medium mt-3">{{ item.title }}</h5>
              <h6 class="text-center font-weight-light ">{{ item.date }}</h6>
            </router-link>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "blockNews",
  props : {
    block : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      items : []
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_NEWS_PUBLIC']),
  },
  async mounted() {
    this.items = await this.GET_NEWS_PUBLIC()
  }
}
</script>

<style scoped>

</style>