<template>
  <v-sheet
      :color="BLOCK_COLOR_PARAM(block, 'course-pricing_background' , config.style )"
      class="fill-height  d-flex flex-column "
      :style="`border-radius : ${config.block_radius}px`"
      style="position: relative"
  >
    <v-sheet v-if="item.img" color="transparent" style="width: 100%;" class="px-6 pt-6" >
      <ws-img
          v-if="item.img"
          :src="item.img"

          :height="config.image_height || 70"
          :vertical-position="item.img_vertical || 50"
          :horizontal-position="item.img_horizontal || 0"
          :image-style="imageStyle"
          contain
          auto-size
          :trigger-resize="imageResizeTrigger"
      />

    </v-sheet>
    <div style="padding: 24px">

      <h3 :style="BLOCK_TEXT_CSS(block , block.config.style , 'course-pricing' , 'title')" >{{ item.text }}</h3>
      <h4 :style="BLOCK_TEXT_CSS(block , block.config.style , 'course-pricing' , 'price')" class="mt-2">
        {{ getPrice(item.price) || $t('Free') }} {{ getPrice(item.price) ? BUSINESS_CURRENCY : '' }}
      </h4>
      <h5 v-if="course.discount && item.price" :style="BLOCK_TEXT_CSS(block , block.config.style , 'course-pricing' , 'discount')" class="mt-2">
        {{ $t('Discount') }} {{ course.discount }} %,
        <span style="text-decoration: line-through">
          {{ item.price }} {{ BUSINESS_CURRENCY }}
        </span>
      </h5>

      <h4 :style="BLOCK_TEXT_CSS(block , block.config.style , 'course-pricing' , 'description')" class="mt-2"
          v-html="PARSE_NEW_LINES(item.description)"
      />
    </div>
    <v-sheet color="transparent" class="flex-grow-1" height="100%"  />
    <v-sheet color="transparent" height="40" class="px-6"  :style="`margin-bottom : ${24 + (config.block_radius/2)}px`">
      <avalon-button
          @click="displayEnrollTrigger++"
          large
          :text="`${$t('Purchase')}`"
          :block-style="config.style"
          icon="mdi-basket"
          fill-area
          primary-background-param="course-pricing_button_background"
          primary-text-color-param="course-pricing_button_text"
      />
    </v-sheet>
    <viewButtonCourseEnroll
        v-if="course.has_payment_plans"
        :entity="course"
        :display="displayEnrollTrigger"
        :block="block"
        avalon
        :element-uuid="block.id + 'block'"
        :payment-plan="item.value"
    />

  </v-sheet>
</template>

<script>
import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";
export default {
  name: "blockTilesDesign1",
  components : {
    viewButtonCourseEnroll
  },
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    config  : {
      type : Object,
      default() { return {} }
    },
    block : {
      type : Object,
    },
    course : {
      type : Object,
      default() { return {} }
    },
    cols : {}
  },
  data() {
    return {
      imageResizeTrigger : 0,
      displayEnrollTrigger : 0
    }
  },
  computed : {
    noCardBackground() {
      return this.CONFIG_PARAM_COLOR('styles' , this.config.style , 'tiles_card_background') === this.CONFIG_PARAM_COLOR('styles' , this.config.style , 'bg')
    },
    imageStyle() {
      return `border-top-left-radius: ${this.config.block_radius}px !important; border-top-right-radius: ${this.config.block_radius}px !important`
    }
  },
  watch : {
    cols() {
      this.imageResizeTrigger++
    }
  },
  methods : {
    getPrice(price) {
      if ( this.course.discount ) {
        price = (price * ((100 - this.course.discount) *0.01 )).toFixed()
      }

      return price

    }
  },

}
</script>

<style scoped>

</style>