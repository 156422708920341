<template>
<div>
  <h2 style="font-size: 24px" class="mb-8">
    {{ $t('homepage.register.restore_password') }}
  </h2>
  <!-- Email Input-->
  <div v-if="!displayRestore">
    <h4 class="font-weight-regular">
      {{ $t('EmailPasswordRestorationText') }}
    </h4>

    <ws-text-field
        class="mt-6"
        v-model="entityData.email"
        label="Email"
        :placeholder="$t('EnterEmail')"
        @input="emailError = null"
        :error="!!emailError"
        :error-messages="emailError"
        :hide-details="false"
    />
  </div>
  <!-- Restore Action -->
  <div v-else>
    <ws-text-field
        class="mt-6"
        v-model="entityData.code"
        :label="$t('PasswordResetCode')"
        :placeholder="$t('PasswordResetCode')"
        @input="codeError = null"
        :error="!!codeError"
        :error-messages="codeError"
        :hide-details="false"
    />
    <ws-text-field
        v-model="entityData.password"
        :label="$t('NewPassword')"
        :placeholder="$t('EnterPassword')"
        :error="!!passwordError"
        :error-messages="passwordError"
        @input="passwordError = null"
        :hide-details="false"
        new-password
    />
  </div>



  <v-btn
      @click="!displayRestore ? sendEmail() : restoreEmail()"
      :disabled="disabledCondition"
      :dark="!disabledCondition"
      :color="wsATTENTION"
      class="noCaps d-flex justify-space-between mt-2"
      height="40"
      elevation="0"
      block
  >
    <div class="mr-7" />
    {{ $t('homepage.register.restore_password') }}
    <v-icon> {{ !displayRestore ? 'mdi-email-fast-outline'  : 'mdi-restore' }}</v-icon>
  </v-btn>
  <v-btn
      @click="!displayRestore ? $emit('cancel') : displayRestore = false"
      class="noCaps d-flex justify-space-between mt-3"
      :color="wsDARKER"
      outlined
      block
      height="40"
      elevation="0"
  >
    <v-icon>mdi-chevron-left</v-icon>
    {{ $t('GoBack') }}
    <div class="ml-7" />
  </v-btn>


</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "restorePasswordWindow",
  props : {
    noAuthAction : {
      type : Boolean,
      default : false
    }
  },
  data : () => ({
    entityData : {},
    displayRestore : false,
    passwordError : null,
    emailError : null,
    codeError : null,
    reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

  }),
  computed : {
    disabledCondition() {
      if ( !this.displayRestore ) {
        return !this.entityData.email
      }
      return !(this.entityData.code && this.entityData.password)
    }
  },
  methods : {
    ...mapActions('auth',[ 'REQUEST_PASSWORD','CHANGE_PASSWORD']),

    async sendEmail() {

      if( !this.reg.test(this.entityData.email) ) {
        this.emailError = this.$t('WrongEmail')
        return false
      }
      this.entityData.email = this.entityData.email.replace(/ /g,'');
      if( !this.reg.test(this.entityData.email) ) {
        this.error.email = this.$t('WrongEmail')
        return
      }

      let result = await this.REQUEST_PASSWORD(this.entityData)
      if ( !result ) {
        this.emailError = [this.$t('UserNotFound')]
        return
      }

      this.displayRestore = true

    },
    async restoreEmail() {
      this.entityData.repeat_password = this.entityData.password

      let result =  await this.CHANGE_PASSWORD(this.entityData)

      if ( !result ) {
        this.codeError = this.$t('PasswordResetCodeError')
        return
      }

      this.notify(this.$t('PasswordChangedMessage') , 'success')

      if (  this.noAuthAction ) {
        return
      }

      if ( this.$store.state.business.selectedBusiness.alias ) {
        this.$router.push(this.businessDashLink(''))
        return
      }

      this.$router.push(this.localeLink('dashboard'))
    }
  }
}
</script>

<style scoped>

</style>