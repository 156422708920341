<template>
  <ft-select
      :disabled="item.type !== 'submenu'"
      :items="item.children"
      translate
      open-on-hover
      color-black
      :background-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_dropdown_background')"
      :text-css="CONFIG_TEXT_CSS(header.config.style , 'header' , 'dropdown_links')"
      :text-color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')"
      :disable-actions="preview || $store.state.avalon.editorMode"
  >
    <ws-link
        :href="item.url || null"
        :to="item.route ? businessPublicLink(item.route + (item.block || '') ) : null"
        :new-window="item.open_in_new_window"
        :disabled="preview || $store.state.avalon.editorMode || blockScrollRouter"
        :key="item.value"
    >
      <h5 @click="action(item)"
          :style="CONFIG_TEXT_CSS(header.config.style , 'header' , 'links')"
          class="mr-5 text-no-wrap pointer"  >
        {{ $t(item.text) }}
        <v-icon
            v-if="item.children && item.children.length > 0"
            :color="CONFIG_PARAM_COLOR('styles', header.config.style, 'header_links')">
          mdi-menu-down
        </v-icon>
      </h5>
    </ws-link>
  </ft-select>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "viewMenuItem",
  props : {
    item : {
      type : Object ,
      default() { return {} }
    },
    preview : {
      type : Boolean,
      default : false
    },
  },
  computed : {
    ...mapState('avalon' , ['header']),

    blockScrollRouter() {

      if ( this.item.type === 'block' && this.$route.params && this.$route.params.pageAlias && this.item.route ) {
        var str = this.item.route
        var parts = str.split('/');
        var route = parts[1];

        if ( route && route === this.$route.params.pageAlias ) {
          return true
        }

      }

        return false
    }
  },
  methods : {
    action(item) {
      if ( item.block ) {
        this.scrollToBlock(item)
      }
      if ( this.$store.state.avalon.editorMode ) {
        this.notify(this.$t('avalon.links_disabled_in_editor') , 'warning')
      }

    },
    scrollToBlock(item) {

      if ( !item.block ) {
        return
      }
      this.$emit( 'scroll-to-block' , item.block)
    }
  },

  mounted() {
    if ( this.item.children ) {
      this.item.children.forEach( child => {
        if ( child.route ) {
          child.route = this.businessPublicLink(child.route)
        }
      })
    }

  }
}
</script>

<style scoped>

</style>