<template>
  <v-sheet  class="d-flex justify-center" color="transparent" >
    <v-sheet :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'"  color="transparent" width="1200">


      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <h5 class="font-weight-medium">{{ $t('HumanName') }}:</h5>
          <v-text-field  v-model="entityData.name" dense   outlined   placeholder="Ім'я"> </v-text-field>
          <v-row>
            <v-col cols="6">
              <h5 class="font-weight-medium">{{ $t('Phone') }}:</h5>
              <wsPhoneInput v-model="entityData.phone"  />
            </v-col>
            <v-col cols="6">
              <h5 class="font-weight-medium">{{$t('Email')}}:</h5>
              <v-text-field v-model="entityData.email" dense   outlined   placeholder="Email"> </v-text-field>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
          <h5 class="font-weight-medium">{{ $t('Comment') }}:</h5>
          <v-textarea class="noResize" height="125" v-model="entityData.comment" dense  outlined placeholder="Коментар"> </v-textarea>
        </v-col>
      </v-row>

      <v-btn :loading="LOADING"
             outlined
             @click="addLead"
             :x-large="displayHide(['sm'])"
             :large="displayShow(['sm'])"

             class="noCaps px-8"
             block
      >Залишити заявку</v-btn>

    </v-sheet>
  </v-sheet>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "blockContactForm",
  props : {
    block : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      entityData : {}
    }
  },
  methods : {
    ...mapActions('leads', ['ADD_BUSINESS_LEAD']),

    async addLead() {
      if ( !this.entityData.phone ) {
        return this.notify(this.$t('PhoneNumberEmpty'))
      }
      if ( !this.entityData.name ) {
        return this.notify(this.$t('NameEmpty'))
      }
      this.entityData.origin = 'contact_form'
      this.entityData.device = this.DEVICE
      let result = await this.ADD_BUSINESS_LEAD(this.entityData)
      if( !result ) { return }
      this.notify(this.$t('BusinessLeadCreated'))
      this.entityData = {}
    },
  }
}
</script>

<style scoped>

</style>