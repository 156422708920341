<template>
  <div >

    <!-- Heading-->
    <div class="d-flex justify-space-between align-center mb-8">
      <h2 :style="`font-size: ${!SM ? 24 : 18}px`">
        {{ $t('Login') }}
      </h2>
      <h4 v-if="!hideRegister"
          @click="$emit('register' , true)"
          :style="`color : ${wsATTENTION}`"
          class="linkHover"
          style="font-size: 13px" >
        {{ $t('Register')}}
      </h4>
    </div>

    <!-- Login Window -->
    <div>

      <ws-toggle
          v-model="fromEmail"
          :text-true="$t('ByEmail')"
          :text-false="$t('ByTelephone')"
          class="mb-5"
      />

      <ws-text-field
          v-if="fromEmail"
          v-model="entityData.email"
          :label="$t('Email')"
          :placeholder="$t('EnterEmail')"
          @input="emailError = null"
          :error="!!emailError"
          :error-messages="emailError"
          :hide-details="false"

      />
      <ws-phone-input
          v-else
          v-model="entityData.phone"
          :label="$t('Phone')"
          :placeholder="$t('EnterPhone')"
          :error="!!phoneError"
          :error-messages="phoneError"
          @input="phoneError = null"
          :hide-details="false"
          required
      />
      <ws-text-field
          v-model="entityData.password"
          :label="$t('Password')"
          :placeholder="$t('Password')"
          @input="passwordError = null"
          :error="!!passwordError"
          :error-messages="passwordError"
          :hide-details="false"
          password
      />
      <h5 @click="$emit('restore')"
          :style="`color : ${wsDARKLIGHT}`"
          class="linkHover text-right">
        {{ $t('ForgetPassword') }}
      </h5>
    </div>
    <!-- Buttons -->

    <ws-button
        @click="login"
        :disabled="(fromEmail ? !entityData.email : !entityData.phone ) || LOADING "
        :loading="LOADING"
        label="Login"
        class="mt-6"
        block
    />


    <!-- Google Facebook Sign in / Login -->
    <template>
      <!-- Separator -->
      <div class="d-flex justify-space-between align-center my-6">
        <v-divider :style="`border-color : ${wsBACKGROUND}`" class="mr-10" />
        <h5 :style="`color : ${wsACCENT}`">{{ $t('or') }}</h5>
        <v-divider :style="`border-color : ${wsBACKGROUND}`" class="ml-10" />
      </div>

      <!-- Google Facebook Sign in / Login -->
      <div v-if="!$store.state.AGENT_SOCIAL_APP">
        <v-btn
            :disabled="!socialsAuthUrlGoogle"
            :href="socialsAuthUrlGoogle"
            @click="handleAvalonElementAfterRefresh"
            :color="wsACCENT"
            class="noCaps d-flex justify-space-between"
            height="40"
            block outlined
        >
          <v-icon size="20" :color="wsATTENTION">mdi-google</v-icon>
          {{ $t('homepage.register.google.proceed') }}
          <div/>
        </v-btn>
        <v-btn
            :disabled="!socialsAuthUrlFacebook"
            :href="socialsAuthUrlFacebook"
            @click="handleAvalonElementAfterRefresh"
            :color="wsACCENT"
            class="noCaps d-flex justify-space-between mt-2"
            height="40"
            outlined
            block
        >
          <v-icon size="20" color="primary">mdi-facebook</v-icon>
          {{ $t('homepage.register.facebook.proceed') }}
          <div/>
        </v-btn>
      </div>
      <v-sheet v-else class="pa-3 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">
        <h5 class="text-center wsACCENT">{{ $t('AuthSocialWarningTitle') }}</h5>
        <h5 class="mt-3 font-weight-regular text-center">{{ $t('AuthSocialWarningDescription') }}</h5>
      </v-sheet>
    </template>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "loginWindow",
  props : {
    hideRegister : {
      type : Boolean,
      default : false
    },
    onAuth : {
      type : Function
    },
    authRoute : {
      type : String
    },
    noAuthAction : {
      type : Boolean,
      default : false
    },
    dialog : {
      type : Boolean,
      default : false
    },
    elementUuid : {
      type : String
    }
  },
  data() {
    return {
      skipProceed : false,
      fromEmail : true,
      entityData : {
        email : null
      },
      emailError : null,
      phoneError : null,
      passwordError : null,
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,
    }
  },
  computed : {
    ...mapState('auth' , ['socialsAuthUrlGoogle' , 'socialsAuthUrlFacebook' , 'proceedUser'])
  },
  methods : {

    ...mapActions('auth',[
        'LOGIN', 'PROCEED_AS',
    ]),
    ...mapActions('ajax', [ 'REFRESH_TOKEN']),



    handleAvalonElementAfterRefresh() {
      if ( this.elementUuid ) {
        localStorage.setItem('avalon_refresh_element' , this.elementUuid)
      }
    },

    async login() {
      if (!this.validateInput()) {
        return
      }
      let result = await this.LOGIN(this.entityData)
      if ( !result ) {

        if ( this.$store.state.ajax.error === 'Incorrect password') {
          this.passwordError = this.$t('WrongPassword');
        }
        if ( this.$store.state.ajax.error === 'User not found') {
          if ( this.fromEmail ) {
            this.emailError = this.$t('UserNotFound');
          } else {
            this.phoneError = this.$t('UserNotFound');
          }

        }
        return
      }

      if ( this.noAuthAction) {
        this.$emit('success')
        return
      }
      if ( this.$store.state.business.selectedBusiness.alias ) {
        this.$router.push(this.businessDashLink(''))
        return
      }

      this.$router.push(this.localeLink('dashboard'))
    },
    validateInput() {

      if ( this.fromEmail ) {
        this.entityData.email = this.entityData.email.replace(/ /g,'');
      }

      if ( this.fromEmail && !this.entityData.email ) {
        this.emailError = this.$t('WrongEmail')
        return false
      }

      if( this.fromEmail && !this.reg.test(this.entityData.email) ) {
        this.emailError = this.$t('WrongEmail')
        return false
      }
      if ( !this.fromEmail && !this.entityData.phone ) {
        this.phoneError = this.$t('EnterPhone')
        return false
      }
      if ( !this.entityData.password ) {
        this.passwordError = this.$t('EnterPassword')
        return false
      }
      return true
    },
    continueGoogle() {
      this.notify('continueGoogle')
    },
    continueFacebook() {
      this.notify('continueFacebook')
    },
  }
}
</script>

<style scoped>

</style>