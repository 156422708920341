<template>
  <v-fade-transition>
    <v-sheet v-if="HOME_LANGUAGE_READY" :color="wsLIGHTCARD" class="fill-height">

      <login-side-bar/>


      <v-row class="no-gutters flex-wrap flex-column fill-height " >


        <v-col cols="auto" class="shrink">
          <home-header-wsu />
        </v-col>

        <v-col ref="content" cols="auto" class="grow overflow-y-auto " style="height: 100px; "  >
          <v-fade-transition mode="out-in">
            <router-view @hideFooter="displayFooter = !$event" />
          </v-fade-transition>
          <home-footer-wsu v-if="displayFooter" />
        </v-col>


      </v-row>


      <registerBusiness v-model="$store.state.homepage.displayRegisterBusiness"/>
      <homeContactFormDialog v-model="$store.state.homepage.displayContactForm" />

    </v-sheet>
  </v-fade-transition>

</template>

<script>

import loginSideBar from "@/components/auth/loginSideBar";
import homeHeaderWsu from "@modules/wsu_homepage/components/UI/homeHeaderWsu.vue";
import homeFooterWsu from "@modules/wsu_homepage/components/UI/homeFooterWsu.vue";

import registerBusiness from "@/components/UI/components/registerBusiness";
import homeContactFormDialog from "@/components/pages/homepage_westudy/UI/homeContactFormDialog";
import referral from "@/mixins/referral";

export default {
  name: "PageHomepage",
  mixins: [referral],
  components: {
    registerBusiness,
    homeHeaderWsu,
    homeFooterWsu,
    homeContactFormDialog,
    loginSideBar,
  },
  data: () => ({
    displayFooter: true,
    displayDialog: false,
    loading: false,
    leadDialog: false,
    HOME_LANGUAGE_READY: false
  }),
  watch: {
    $route() {
      if (this.$refs.content) {
        let content = this.$refs.content
        if (content) {
          content.scrollTop = 0
        }
      }
      window.scrollTo(0, 0)
    }
  },

  methods: {
    getLang() {

      const browserLanguage = navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : (navigator.language || navigator.userLanguage || 'ua');

      console.log(navigator.languages);

      // List of supported languages
      let supportedLanguages = this.$store.state.languages; // Add your supported languages here

      let selectedLang = null;
      supportedLanguages.push('uk')
      supportedLanguages.forEach(lang => {
        if (browserLanguage.toLowerCase().includes(lang.toLowerCase())) {
          selectedLang = lang
        }
      })

      if (selectedLang === 'uk') {
        selectedLang = 'ua'
      }

      if (selectedLang === 'ru') {
        return 'ua'
      }

      return selectedLang || 'en';
    }
  },
  mounted() {
    this.$store.state.isHomepage = true
    this.CHECK_PARTNER_REFFERAL()
  },
  async beforeMount() {
    if (this.getLang() !== 'ua' && !this.$route.params.lang) {
      await this.LOCALE_CHANGE(this.getLang())
    }
    this.HOME_LANGUAGE_READY = true
    this.$store.state.isHomepage = true
  },
  beforeDestroy() {
    this.$store.state.isHomepage = false
  }


}
</script>

<style lang="scss" scoped>
.main_section {
  width: 1100px
}
</style>