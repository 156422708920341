<template>
  <v-hover #default="{hover}">
    <div @click="selectBlock" :style="blockTopMarginFromHeader">
      <div style="position:relative;">
        <!-- :: BLOCK CONTENT -->
        <div :style="layoutStyle(hover)"
             class="d-flex justify-center"
             style="position : relative">


          <v-sheet
              v-if="block.config.background_type === 'image'"
              :color="GET_AVALON_COLOR(avalonBlockConfig(block, 'overlay_color' ))"
              :style="`opacity : ${avalonBlockConfig(block, 'overlay_opacity' )/100}`"
              class="fill-height"
              width="100%"
              style="position:absolute;"
          />

          <v-sheet
              :width="blockWidth"
              :style="style"
              color="transparent"
          >

            <!-- Title and Subtitle-->
            <div v-if="block.type && (block.config.title || block.config.subtitle)" class="mb-12 px-4">
              <h1 v-if="block.config.title"
                  :class="[{'text-center' : block.config.heading_align === 'center'},{'text-right' : block.config.heading_align === 'right'}]"
                  :style="BLOCK_TEXT_CSS(block , block.config.style , 'block' , 'title')"
              >
                {{ block.config.title }}
              </h1>
              <h3 v-if="block.config.subtitle"
                  :class="[{'text-center' : block.config.heading_align === 'center'},{'text-right' : block.config.heading_align === 'right'}]"
                  :style="BLOCK_TEXT_CSS(block , block.config.style , 'block'  , 'subtitle' )"
              >
                {{ block.config.subtitle }}
              </h3>
            </div>

            <view-zero
                v-if="!block.type"
                :block="block"
                :elements="blockElements"
                @element-hover="elementHover = $event"
                :preview="preview"
            />
            <block-courses
                v-if="block.type === 'courses'"
                :block="block"
                :preview="preview"
            />
            <block-news
                v-if="block.type === 'news' "
                :block="block"
                :preview="preview"
            />
            <block-contact-form
                v-if="block.type === 'contact_form' "
                :block="block"
                :preview="preview"
            />
            <block-tiles
                v-if="block.type === 'tiles' "
                :block="block"
                :preview="preview"
            />


            <block-tiles-dynamic
                v-if="['tiles_news','tiles_courses'].includes(block.type)"
                :block="block"
                :preview="preview"
                @no-items="$emit('no-items')"
                :default-site="defaultSite"
            />
            <block-gallery
                v-if="block.type === 'gallery' "
                :block="block"
                :preview="preview"
            />
            <block-slider
                v-if="block.type === 'slider1' "
                :block="block"
                :navigation-width="navigationWidth"
                :content-width="contentWidth"
                :preview="preview"
            />
            <block-calendar
                v-if="block.type === 'calendar' "
                :block="block"
                :preview="preview"
                view
            />

            <block-course-pricing
                v-if="block.type === 'course-pricing'"
                :block="block"
                :preview="preview"
            />

            <view-navigation
                v-if="block.type === 'navigation' "
                :key="'parentBlock' + block.id"
                :block="block"
                :children="children"
                :children-elements="childrenElements"
                view
            />

            <view-block-text
                v-if="block.type === 'block-text' "
                :block="block"
            />

          </v-sheet>
        </div>

      </div>
    </div>
  </v-hover>

</template>

<script>

import BlockCourses from "@/components/AvalonEditor/Editor/blocks/blockCourses";
import BlockNews from "@/components/AvalonEditor/Editor/blocks/blockNews";
import BlockContactForm from "@/components/AvalonEditor/Editor/blocks/blockContactForm";
import blockCoursePricing from "@/components/AvalonEditor/Editor/blocks/tiles/blockCoursePricing";
import viewBlockText from "@/components/AvalonEditor/View/blocks/viewBlockText";
import blockTiles from "@/components/AvalonEditor/Editor/blocks/tiles/blockTiles";
import blockTilesDynamic from "@/components/AvalonEditor/Editor/blocks/tiles/blockTilesDynamic";
import blockCalendar from "@/components/AvalonEditor/Editor/blocks/calendar/BlockCalendar.vue";

import { mapState} from "vuex";


export default {
  name: "ViewBlock",
  components : {
    blockCoursePricing,
    ViewZero : () => import("@/components/AvalonEditor/View/blocks/viewZero"),
    blockTiles,
    blockTilesDynamic,
    viewNavigation : () => import("@/components/AvalonEditor/View/blocks/viewNavigation"),
    blockGallery : () => import("@/components/AvalonEditor/Editor/blocks/gallery/blockGallery"),
    blockSlider : () => import("@/components/AvalonEditor/Editor/blocks/slider/blockSlider"),
    viewBlockText ,
    BlockCourses,
    BlockNews,
    BlockContactForm,
    blockCalendar
  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    elements : {
      type : Array,
      default() { return [] }
    },
    triggerUpdate : {
      type : Number,
      default : 0
    },
    overlap : {
      type : Boolean,
      default : false
    },
    footer : {
      type : Boolean,
      default : false
    },
    preview : {
      type : Boolean,
      default : false
    },
    children : {
      type : Array,
      default() { return [] }
    },
    childrenElements : {
      type : Array,
      default() { return [] }
    },
    navigationWidth : {
      type : Number,
    },
    contentWidth : {
      type : Number,
    },
    isFirst : {
      type : Boolean,
      default : false
    },
    defaultSite : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      edit : false,
      displaySettings : false,
      blockElements : [],
      maxHeight : 0,
      maxHeightSM : 0,
      elementHover : false,
    }
  },
  computed : {
    ...mapState('avalon',[ 'editorMode' , 'header' , 'scrollPosition' , 'selectedLang' ]),
    blockTopMarginFromHeader() {
      return  this.isFirst && this.header.config.overlap ? `margin-top : -${this.header.config[this.DIM_KEY['height']]}px` : ''
    },
    style() {
      let style = ""

      let overlap = this.overlap && this.header.config.overlap ? this.header.config.height : 0
      if ( this.SM || this.MOBILE_VIEW ) {
        overlap = this.overlap && this.header.config.overlap ? this.header.config.height_sm : 0
      }

      // let sticky = this.header.config.sticky && this.scrollPosition > 52
      if ( this.avalonBlockConfig(this.block, 'full_height') ) {
        style += `height : 100vh;`
      }

      if ( !(this.SM || this.MOBILE_VIEW)) {

        if ( this.avalonBlockConfig(this.block, 'vertical_padding')   && this.block.config ) {
          if ( this.block.config.padding_top || this.block.config.padding_top === 0 ) {
            style += `padding-top : ${(this.block.config.padding_top !== undefined ? this.block.config.padding_top : this.avalonConfig.block.padding_top) + overlap }px;`
          }
          if ( this.block.config.padding_bottom || this.block.config.padding_bottom === 0 ) {
            style += `padding-bottom : ${this.block.config.padding_bottom !== undefined ? this.block.config.padding_bottom : this.avalonConfig.block.padding_bottom}px;`
          }
        } else if ( this.overlap && this.header.config.overlap ) {
          style +=  `padding-top : ${this.avalonBlockConfig(this.block, 'padding_top') + overlap }px;`
        }

      }

      if ( this.SM || this.MOBILE_VIEW) {

        style += `padding-top : ${(this.block.config.padding_top_sm || 0 ) + overlap }px;`
        style += `padding-bottom : ${this.block.config.padding_bottom_sm || 0 }px;`

      }

      return style
    },
    blockWidth() {
      if ( this.avalonBlockConfig(this.block, 'full_width')) {
        return '100%'
      }
      if ( this.avalonBlockConfig(this.block, 'width') ) {
        return this.avalonBlockConfig(this.block, 'width').toString()
      }
      return '100%'
    },
    sticky() {
      return this.overlap ? ( this.header.config.sticky && this.scrollPosition > 52 ) : false
    },
  },
  watch : {
    triggerUpdate() {
      this.initBlock()
    },
    elements : {
      handler() {
        this.blockElements = JSON.parse(JSON.stringify(this.elements))
      },
      deep : true
    }
  },
  methods : {

    selectBlock() {
      if ( !this.$store.state.avalon.menuOnlyStylesAndFonts && this.$store.state.avalon.menuNavigation !== 'settings' ) {
        return
      }
      this.$store.state.avalon.selectedElementType = null
      this.$store.state.avalon.menuNavigationStyleTheme = this.block.config.style
      this.$store.state.avalon.selectedBlockStyle = this.block.config.style || 'light_1'

      this.$store.state.avalon.selectedBlockType = this.block.type || 'block'

      this.$store.state.avalon.selectedBlockDesign = this.block.config.design || null



      if ( this.$store.state.avalon.menuNavigation === 'styles'  ) {
        this.$store.state.avalon.menuNavigation = 'styles_theme'
        this.$store.state.avalon.menuNavigationReturn = 'styles'
      } else
      if ( this.$store.state.avalon.menuNavigation === 'fonts'  ) {
        this.$store.state.avalon.menuNavigation = 'fonts_edit_element'
        this.$store.state.avalon.menuNavigationReturn = 'fonts'
        this.$store.state.avalon.menuNavigationElement = 'other'
      }

    },


    // technical
    layoutStyle( hover ) {
      let style = 'transition : -webkit-box-shadow 0.3s ease, -moz-box-shadow 0.3s ease, box-shadow 0.3s ease;'

      let sticky = this.header.config.sticky
      let stickyHeight = sticky ? this.header.config.height : 0

      if ( this.SM || this.MOBILE_VIEW ) {
        stickyHeight = sticky ? this.header.config.height_sm : 0
      }

      if ( sticky && this.overlap && !this.preview ) {
        style += `margin-top : ${stickyHeight}px` + ';'
      }

      let color = this.block.config.style
          ? this.BLOCK_COLOR_PARAM(this.block ,  'bg' )
          : this.GET_AVALON_COLOR(this.block.config.color)
      style += `background-color : ${color}` + ';'

      if ( this.avalonBlockConfig(this.block, 'background_type' ) === 'image' ) {
        style += `background: url('${this.avalonBlockConfig(this.block, 'image' )}');
                  background-size : cover;
                  position:relative;`
        if ( !(this.SM  || this.MOBILE_VIEW)) {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_y' )}%;`
        } else {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_sm_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_sm_y' )}%;`
        }
      }

      if ( !this.elementHover && this.$store.state.avalon.menuOnlyStylesAndFonts && this.$store.state.avalon.menuNavigation !== 'settings' && hover ) {
        style += `box-shadow: inset 0 0 0 4px ${this.wsATTENTION} !important;`
        style += `cursor : pointer !important;`
      }


      return style
    },
    initBlock() {

      if ( !this.block.config || Object.keys(this.block.config).length === 0 ) {
        this.block.config = {
          rows  : this.avalonConfig.block.rows,
          rows_sm : this.avalonConfig.block.rows_sm,
          auto_resize_mobile : true,
          background_type : this.avalonConfig.block.background_type,
          full_width : this.avalonConfig.block.full_width,
          vertical_padding : this.avalonConfig.block.vertical_padding
        }
      }

      this.blockElements = JSON.parse(JSON.stringify(this.elements))
    }

  },
  mounted() {
    this.initBlock()
    if ( Array.isArray( this.block.config.color_styles) ) {
      this.block.config.color_styles = {}
      // this.updateSettings()
    }
    if ( !this.block.config.color_styles ) {
      this.block.config.color_styles = {}
      // this.updateSettings()
    }

    if ( Array.isArray( this.block.config.fonts_styles) ) {
      this.block.config.fonts_styles = {}
      // this.updateSettings()
    }
    if ( !this.block.config.fonts_styles ) {
      this.block.config.fonts_styles = {}
      // this.updateSettings()
    }
  }

}
</script>

<style scoped>

</style>